export const update_form_ataque_date = (date) => {
    return {
        type: 'UPDATE_FORM_ATAQUE_DATE',
        payload: date
    }
}

export const update_form_ataque_coords = (coords) => {
    return {
        type: 'UPDATE_FORM_ATAQUE_COORDS',
        payload: coords
    }
}

export const update_form_ataque_alvo = (alvo) => {
    return {
        type: 'UPDATE_FORM_ATAQUE_ALVO',
        payload: alvo
    }
}

export const update_form_ataque_descricao = (descr) => {
    return {
        type: 'UPDATE_FORM_ATAQUE_DESCRICAO',
        payload: descr
    }
}

export const update_form_ataque_image = (imagem) => {
    return {
        type: 'UPDATE_FORM_ATAQUE_IMAGE',
        payload: imagem
    }
}

export const update_form_ataque_concelho = (concelho) => {
    return {
        type: 'UPDATE_FORM_ATAQUE_CONCELHO',
        payload: concelho
    }
}

export const clean_form_ataque = () => {
    return {
        type: 'CLEAR_ATAQUE_FORM'
    }
}
