import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import './UsersList.css';
import Loader from 'react-loader-spinner';
import { clear_users_list, edit_user, get_users_list_extra, get_users_list_first, search_user } from '../redux/actions';
import { Link } from 'react-router-dom';

function UsersList(){
    const users_list = useSelector(state => state.usersList.utilizadores)
    const total_users = useSelector(state => state.usersList.totalUtilizadores)
    const source = useSelector(state =>  state.usersList.source)
    const status = useSelector(state => state.usersList.status)
    const [searchString, setSearchString] = useState('')

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(get_users_list_first())
       
    }, [])

    useEffect(() => {
        if(total_users>0){
            setTotalPages(Math.round(total_users/10))
        }
        

    }, [total_users])

    function handleBack(){
        const new_page = page-1
        setPage(new_page)
        dispatch(get_users_list_extra(new_page))
    }

    function handleForward(){
        const new_page = page+1
        setPage(new_page)
        dispatch(get_users_list_extra(new_page))
    }


   

    const lista_display = users_list.map(el => {
        const date = new Date(el.joined)
        return <tr key={el._id}>
            <td>{el.username}</td>
            <td>{el.email}</td>
            <td>{date.toLocaleDateString("pt-PT")} {date.toLocaleTimeString("pt-PT", {hour:'2-digit', minute:'2-digit'})}</td>
            <td>{el.confirmado?"Sim":"Não"}</td>
            <td>{el.role}</td>
            <td><div onClick = {() => dispatch(edit_user(el))}><Link to={'/admin/utilizador'}>Editar</Link></div></td>

        </tr>}
    )
    return(
        
        <div>
            <div>
                <label htmlFor="search-string" style={{display:'block'}}>Nome de utilizador:</label>
                <input type="text" id="search-string" name="search-string" onChange={e => setSearchString(e.target.value)} value = {searchString} style={{width:'100%', boxSizing: 'border-box'}}></input>
                <button className='custom-button' onClick={() => dispatch(search_user(searchString))} disabled={searchString===''}>Procurar</button>
                <button className='custom-button' onClick={() => dispatch(get_users_list_first())}>Limpar</button>
            </div>
            {status==='LOADED'?
            <div><table>
                <tr><th>Username</th><th>Email</th><th>Desde</th><th>Confirmado</th><th>Papel</th><th>Opções</th></tr>{lista_display}</table>
                <div style={{display:'grid', gridTemplateColumns:'50% 50%'}}>
                    {source ==='list' && page>1?<div onClick={handleBack} style={{gridColumn:1, textAlign:'center'}}>Anterior</div>:null}
                    {source ==='list' &&  page<totalPages?<div onClick={handleForward} style={{gridColumn:2, textAlign:'center'}}>Seguinte</div>:null}
                </div>
            </div>
                :<Loader
                    type='TailSpin'
                    color='#0dafd0ff'
                    height='40'
                    width='40'
                
                />}
        </div>
    )
}

export default UsersList;