import React from 'react';


function EditAninhamento(props){
    //componente de edição de observações do tipo ninho. Todo o processo é handled pelo parent component, os dados a mostrar e funções a chamar chegam como props.
    //este componente apenas lida com os dados específicos deste tipo de observação, os dados comuns a todos os tipos são editados no parent
    return(
        <>
            <div className='form-element'>
                    <label htmlFor='n_ovos' style={{display:'block'}}>Número de ovos</label>
                    <input type='number' name='n_ovos' id='n_ovos' min={-1} value={parseInt(props.observation.properties.n_ovos)} onChange={props.handleChange} style={{width:'100%', boxSizing: 'border-box'}}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='progenitores' style={{display:'block'}}>Progenitores presentes</label>
                    <input type='checkbox' name='progenitores' id='progenitores' onChange={props.handleChange} checked={props.observation.properties.progenitores}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='ruido' style={{display:'block'}}>A causar ruido</label>
                    <input type='checkbox' name='ruido' id='ruido' onChange={props.handleChange} checked={props.observation.properties.ruido}/>
                </div>
                <div className='form-element'>
                    <label htmlFor='local' style={{display:'block'}}>Local</label>
                    <select id='local' name='local' onChange={props.handleChange} style={{width:'100%'}}>
                        <option value='Telhado' selected={props.observation.properties.local==='Telhado'}>Telhado</option>
                        <option value='Solo' selected={props.observation.properties.local==='Solo'}>Solo</option>
                        <option value='Outro' selected={props.observation.properties.local==='Outro'}>Outro</option>
                    </select>
                </div>
                <div className='form-element'>
                    <label htmlFor='detalhes' style={{display:'block'}}>Detalhes</label>
                    <textarea type='text' name='detalhes' id='detalhes' rows={'5'} cols={'40'} value={props.observation.properties.detalhes} onChange={props.handleChange} style={{width:'100%', boxSizing:'border-box'}}></textarea>
                </div>
        </>
    )
}

export default EditAninhamento