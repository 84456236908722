import React from 'react';
import './Sidebar.css';

import MainListElement from './mainListElement';
import {useSelector} from 'react-redux';


function Sidebar() {
    //barra lateral, contém o logotipo, os botões para navegar na aplicação, e os créditos
    // o nome "MainListElement" para os botões é uma herança de outro projeto

    const logged_in = useSelector(state => state.userStatus.loggedIn); //estado do utilizador (se há alguém logged in), uma vez que alguns botões só aparecem a utilizadores com login
    const userData = useSelector(state => state.userStatus.profile); //dados do utilizador, se houver

    return(
        <div className = 'sidebar'>
            <div className='logo'  >
                <img  src='/logo.png' id='logo'/>
            </div>
            
            <ul className='menu-list'>
            
                {logged_in?<li>
                        <MainListElement
                            className = 'main-list-element'
                            elemento='Reportar gaivota'
                            cor='#069e9dff'
                            link='/novo-avistamento-gaivota'
                            imagem='/gull.png'                 
                        />     
                </li>:null}
                
                {logged_in?<li>
                       <MainListElement
                            className = 'main-list-element'
                            elemento='Reportar ninho'
                            cor='#ec693cff'
                            link='/novo-avistamento-ninho'
                            imagem='/nest.png'
                        />
                        
                </li>:null}
                {logged_in?<li>
                       <MainListElement
                            className = 'main-list-element'
                            elemento='Reportar ataque'
                            cor='#29235cff'
                            link='/novo-ataque'
                            imagem='/ataque.png'
                        />
                        
                </li>:null}

                {logged_in?<li>
                       <MainListElement
                            className = 'main-list-element'
                            elemento='Minha conta'
                            tipo='conta'
                            link='/minha-conta'
                            cor='#0dafd0ff'
                            imagem={`/badges/badge${userData.avatar}.png`} 
                        />
                        
                </li>:null}

                {!logged_in?<li>
                       <MainListElement
                            className = 'main-list-element'
                            elemento='Login ou Registo'
                            tipo='login'
                            link='/login'
                            cor='#0dafd0ff'
                            imagem={`/badges/badge${userData.avatar}.png`}
                        />
                        
                </li>:null}

                {window.innerWidth <=810?<li>
                       <MainListElement
                            className = 'main-list-element'
                            elemento='Mapa de avistamentos'
                            link='/'
                            tipo='mapa'
                            cor='#8d5a97ff'
                            imagem={`/map_white.png`}
                        />
                        
                </li>:null}
                
            </ul>
            <div style={{  gridRow:3, height:'100%', display:'grid', gridTemplateColumns: '40% 60%', gridTemplateRows:'15% 85%'}}>
                    
                <p style={{fontSize:'12px', margin: 0, textAlign: 'center', gridRow:1, gridColumnStart: 'span 2', }}>
                    <a target="_blank" rel='noreferrer' href='https://www.wenature.pt' style={{textDecoration: 'inherit', color: 'inherit', width:'100%'}}>Powered by Wenature</a>
                </p>
            
                <a target="_blank" rel='noreferrer' href='http://portal.amp.pt/pt/'  style={{marginTop:'10%', gridColumn:1, gridRow:2, textAlign: 'center'}}>
                    <img style={{width:'75%', height:'80%', objectFit:'contain'}} src='/logo_amp.png'/>
                </a>
                <a target="_blank" rel='noreferrer' href='https://gaivotas-amp.pt' style={{marginTop:'10%', gridColumn:2, gridRow:2, textAlign: 'center'}}>
                    <img style={{width:'85%', height:'80%', objectFit:'contain'}} src='/logo_gaivotas.png'/>
                </a>
                        
                    
               
            </div>
        </div>
    )
}

export default Sidebar;
