import {timeoutPromise} from '../../helpers/timeouters';

export const FETCH_AVISTAMENTOS_USER_BEGIN   = 'FETCH_AVISTAMENTOS_USER';
export const FETCH_AVISTAMENTOS_USER_SUCCESS = 'FETCH_AVISTAMENTOS_USER_SUCCESS';
export const FETCH_AVISTAMENTOS_USER_FAILURE = 'FETCH_AVISTAMENTOS_USER_FAILURE';
export const FETCH_AVISTAMENTOS_EXTRA_USER_BEGIN   = 'FETCH_AVISTAMENTOS_EXTRA_USER';
export const FETCH_AVISTAMENTOS_EXTRA_USER_SUCCESS = 'FETCH_AVISTAMENTOS_EXTRA_USER_SUCCESS';
export const FETCH_AVISTAMENTOS_EXTRA_USER_FAILURE = 'FETCH_AVISTAMENTOS_EXTRA_USER_FAILURE';
export const CLEAR_AVISTAMENTOS_USER = 'CLEAR_AVISTAMENTOS_USER';

export const fetch_avistamentos_user_begin = () => {
    return {
        type:FETCH_AVISTAMENTOS_USER_BEGIN
    }
}

export const fetch_avistamentos_user_success = avistamentos => ({
    type: FETCH_AVISTAMENTOS_USER_SUCCESS,
    payload: {avistamentos}
});

export const fetch_avistamentos_user_failure = error => ({
    type: FETCH_AVISTAMENTOS_USER_FAILURE,
    payload: {error}
});

export const fetch_avistamentos_extra_user_begin = () => {
    return {
        type:FETCH_AVISTAMENTOS_EXTRA_USER_BEGIN
    }
}

export const fetch_avistamentos_extra_user_success = avistamentos => ({
    type: FETCH_AVISTAMENTOS_EXTRA_USER_SUCCESS,
    payload: {avistamentos}
});

export const fetch_avistamentos_extra_user_failure = error => ({
    type: FETCH_AVISTAMENTOS_EXTRA_USER_FAILURE,
    payload: {error}
});

export const clear_avistamentos_user = () => {
    return {
        type:CLEAR_AVISTAMENTOS_USER
    }
}

export const fetch_avistamentos_user = (id) => {
    return dispatch =>{
        dispatch(fetch_avistamentos_user_begin());
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizador/${id}?q=9`)
        .then(response => response.json())
        .then(json => {
         
            dispatch(
                fetch_avistamentos_user_success(json)
                )
        })
        .catch((error) => {
            
            dispatch(fetch_avistamentos_user_failure(error))
        })
    }
}

export const fetch_avistamentos_extra_user = (id, timestamp) => {
    return dispatch =>{
        dispatch(fetch_avistamentos_extra_user_begin());
    
        
        return timeoutPromise(6000,fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizador/${id}?q=9&timestampav=${timestamp.avistamento.toISOString()}&timestampan=${timestamp.aninhamento.toISOString()}&timestampat=${timestamp.ataque.toISOString()}`))
        .then(response => response.json())
        .then(json => {
            dispatch(
                fetch_avistamentos_extra_user_success(json)
                )
        })
        .catch((error) => {
            dispatch(fetch_avistamentos_extra_user_failure(error))
        })
    }
}