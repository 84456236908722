import React from 'react';
import {Link, useParams} from 'react-router-dom';
import FormGeral from './FormGeral';
import LoginPage from './LoginPage';
import AccountPage from './AccountPage';
import AdminPage from './AdminPage';
import ValidationPage from './ValidationPage';
import EditUserPage from './EditUserPage';
import TermsPage from './TermsPage';
import './Modal.css';
import PasswordResetPage from './PassResetPage';

function Modal(props){
    const {id, token} = useParams()
    var modal_content
    if(props.tipo==='login'){
        modal_content = <LoginPage />
    }else if(props.tipo==='conta'){
        modal_content = <AccountPage />
    }else if(props.tipo==='validar'){
        modal_content=<ValidationPage />
    }else if(props.tipo==='admin'){
        modal_content=<AdminPage />
    }else if(props.tipo==='admin-user'){
        modal_content=<EditUserPage />
    }else if(props.tipo==='reset'){
        modal_content=<PasswordResetPage id={id} token={token}/>
    }else if(props.tipo==='termos'){
        modal_content=<TermsPage/>
    }else{
        modal_content = <FormGeral tipo={props.tipo} />
    }
    return(
        <>
        <Link to={{pathname:'/'}}>
            <div
                style= {
                    {
                        position: 'absolute',
                        width: '100vw',
                        height: '100vh',
                        zIndex:1600
                    }
                    }
                >
                                    </div>
            </Link>
      
                <div className='detalhesModal'>
                    
                        <div className = 'modalHeader'>
                            <h1 style={{marginLeft:'15px', fontSize:'24px', heigth:'100%', display:'flex', alignItems:'center'}}>{props.titulo}</h1>
                            <Link style={{display:'flex', flexDirection:'column', justifyContent:'center'}} to={'/'}>
                            <div 
                                
                                style={
                                        {
                                            cursor: 'pointer'
                                            
                                        
                                            
                                            }
                                            }
                                            >
                                                <img alt='' width= '24px' height='24px' style={{marginRight:'10px', marginLeft:'10px'}} src='/close_icon.png'></img>
                                            </div>
                            </Link>
                        </div>
                    <>

                        {modal_content}
                    </>
                    
                </div>
                </>
    )
}

export default Modal;
