import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router'

function FilterButton(){
    //botão que controla a visibilidade do modal onde se encontram os controlos do filtro das observações no mapa
    const [filtering, setFiltering] = useState(false); //se algum filtro está ativo, usado para mostrar um indicador visual no botão
    const history = useHistory();
    const filterState = useSelector(state => state.mapFilter); //estado dos filtros

    useEffect(() => {
        //para mostrar o indicador visual de filtragem no botão, sempre que os filtros mudam, verificamos se algum está ativo
        //se qualquer filtro estiver ativar, true no estado, se nenhum estiver, false no estado
        if(!Object.values(filterState.tipos).every(val => val) || filterState.datas[0]!==null || filterState.datas[1]!==null){
            setFiltering(true)
        }else{
            setFiltering(false)
        }
    }, [filterState]);

    
    return(
        <div
            //botão de controlo, mostra o modal com os filtros quando carregado, ao direcionar para a página correspondente ao modal
            onClick={() => history.push('filtro')}
            
            style = {
                {
                position:'absolute',
                top:'178px',
                right:'10px',
                width:'70px',
                height:'36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: '#F7F9F9',
                borderRadius: '2px',
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize:12,
                WebkitBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                MozBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                boxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                WebkitTapHighlightColor: 'rgba(51, 181, 229, 0.4)',
                display: 'flex',
                justifyContent: 'flex-start'
                }
                }
                >
                    <div style={{width:'25px', objectFit: 'contain'}}>
                        {filtering?<div
                            //indicador de estado: se houver algum filtro ativo
                            style={{position:'absolute', top:'2px', right:'2px', width:'10px', height:'10px', borderRadius:'50%', backgroundColor: 'red'}}></div>:null}
                        <img src='/filter.png' style={{width:'85%', objectFit: 'contain'}}/>
                    </div>
                    <div style={{textAlign: 'left', marginLeft:'3px'}}>Filtrar</div>
                
            </div>
    )
}

export default FilterButton;