import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { update_filter_datas, update_filter_tipos, clear_filters } from '../redux/actions';
import DatePicker from 'react-date-picker';
import { useHistory } from 'react-router-dom';



function FilterModal(){
    //pequeno modal para controlar o estado de filtragem dos avistamentos do mapa
    //o estado de filtragem do mapa é guardado no state geral da app (filterState). As interações do utilizador com o modal são primeiro guardadas no estado local (localFilters, dataMin, dataMax)
    //e só são enviadas para o Redux quando o utilizador confirma em "Aplicar filtros".

    const dispatch = useDispatch();
    const history = useHistory();

    const filterState = useSelector(state => state.mapFilter); //estado dos filtros, guardado no state geral da app
    
    const [localFilters, setLocalFilters] = useState({avistamentos: true, aninhamentos: true, ataques: true}); //estado local dos filtros por tipo
    const [dataMin, setDataMin] = useState(null); //estado local dos filtros por data
    const [dataMax, setDataMax] = useState(null); //estado local dos filtros por data
  

    useEffect(() =>{
        //os estados locais devem reproduzir o state geral da app
        setLocalFilters(filterState.tipos);
        setDataMin(filterState.datas[0]);
        setDataMax(filterState.datas[1]);
    }, [filterState]);

    const handleCancel = () => {
        //fechar o modal ao cancelar
        history.goBack();
    }

    const handleClear = () => {
        //limpar os filtros no state geral e fechar o modal
        dispatch(clear_filters());
        history.goBack();
    }
    

    const applyFilters = () => {
        //aplicar os filtros que estavam guardados localmente no state geral, e fechar o modal
        dispatch(update_filter_tipos(localFilters))
        dispatch(update_filter_datas([dataMin, dataMax]))
        history.goBack()
    }

    const FilterButton = (props) => {
        //retorna cada um dos botões onde o utilizador pode ativar e desativar o respetivo filtro de avistamento. O estilo varia conforme o estado do respetivo filtro (aplicado ou não) para indicar isso.
        //caso um filtro não esteja ativo, i.e. as respetivas observações estejam visíveis o botão é a cores. Caso contrário, tons de cinzento.

        const icones = {aninhamentos:'nest', avistamentos: 'gull', ataques:'ataque'} //ícones por tipo, vão ser usados para construir o URI da imagem
        
        return(
            <div
                onClick={() => setLocalFilters({...localFilters, [props.tipo]:!localFilters[props.tipo]})} //quando o utilizador carrega no botão, toggle o respetivo filtro no estado local
                style={
                    {
                        display:'flex',
                        flexDirection:'column',
                        justifyContent: 'space-around',
                        alignContent: 'center',
                        cursor: 'pointer',
                        border:`1px solid ${localFilters[props.tipo]?'black':'grey'}`, //estilo varia para indicar estado do respetivo filtro
                        width:'80px',
                        height: '80px',
                        borderRadius:'10px'
                    }
                }>
                <div style={{textAlign: 'center'}}>
                    <img
                    //construimos o URI da imagem dependendo do tipo de observação (ninho, ataque, etc) definida no objecto "icones",
                    //e usamos a imagem normal ou uma imagem em tons de cinzento conforme o filtro esteja ou não ativo.
                    src={`/${icones[props.tipo]}${localFilters[props.tipo]?'':'_grey'}.png`}
                    style={{width:'42px', height:'42px', objectFit:'contain'}}
                />
                </div>
                <div
                    //etiqueta do botão, estilo varia com o estado
                    style={{color:localFilters[props.tipo]?'black':'grey', textAlign:'center'}}
                >
                    {props.label}
                </div>
            </div>
        )
    }

    return(
        <div style={{height:'100%', padding:'10px', display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}> 
            <div
                //botões para ativar e desativar filtros
                style={{display: 'flex', justifyContent:'space-evenly'}}
            >
                <FilterButton tipo='avistamentos' label='Gaivotas'/>
                <FilterButton tipo='aninhamentos' label='Ninhos'/>
                <FilterButton tipo='ataques' label='Ataques'/>
                
            </div>
            <div>
                <div
                    //seletor da data mínima para filtragem
                >
                    <div>Data mínima</div>
                    <DatePicker
                        name='dataMin'
                        id='dataMin' 
                        value={dataMin}
                        onChange={newDate => setDataMin(newDate)} //atualizar data no state local, quando alterada
                        maxDate={new Date()} //não pode ser mais que a data atual
                        style={{width:'100%'}}
                    />
                </div>
                <div
                    //seletor da data máxima para a filtragem
                >
                    <div>Data máxima</div>
                    <DatePicker
                        name='dataMax'
                        id='dataMax'
                        value={dataMax}
                        onChange={newDate => setDataMax(newDate)} //atualizar data no state local, quando alterada
                        maxDate={new Date()} //não pode ser mais que a data atual
                        style={{width:'100%'}}
                    />
                </div>
                </div>
            <div
                style={{color:'blue', cursor: 'pointer', textAlign: 'center'}}
                onClick = {handleClear} //limpar os filtros ativos
            >
                    Limpar todos os filtros
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div onClick={handleCancel} style={{cursor: 'pointer'}}>Cancelar</div>
                <div onClick={applyFilters} style={{color: 'green', cursor: 'pointer'}}>Aplicar</div>
            </div>
        </div>
    )
}

export default FilterModal


