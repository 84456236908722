export const open_popup = (popup_data) => {
    return {
        type: 'OPEN_POPUP',
        payload: popup_data
    }
}

export const close_popup = () => {
    return {
        type: 'CLOSE_POPUP'
    }
}