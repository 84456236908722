import {
    FETCH_AVISTAMENTOS_USER_BEGIN,
    FETCH_AVISTAMENTOS_USER_SUCCESS,
    FETCH_AVISTAMENTOS_USER_FAILURE,
    FETCH_AVISTAMENTOS_EXTRA_USER_BEGIN,
    FETCH_AVISTAMENTOS_EXTRA_USER_SUCCESS,
    FETCH_AVISTAMENTOS_EXTRA_USER_FAILURE,
    CLEAR_AVISTAMENTOS_USER
} from '../actions';

const initialState = {
    avistamentos: [],
    mainStatus: 'WAITING',
    extraStatus: 'WAITING', 
    error: null
    };
    
    export default function avistamentosUserReducer(state = initialState, action){
        switch(action.type){
            case FETCH_AVISTAMENTOS_USER_BEGIN:
                return {
                    ...state,
                    mainStatus: 'LOADING',
                    error: null
                }
            case FETCH_AVISTAMENTOS_EXTRA_USER_BEGIN:
                return {
                    ...state,
                    extraStatus: 'LOADING',
                    error: null
                }
            case FETCH_AVISTAMENTOS_USER_SUCCESS:
                return {
                    ...state,
                    mainStatus: 'LOADED',
                    error: null,
                    avistamentos: action.payload.avistamentos
                }
            case FETCH_AVISTAMENTOS_EXTRA_USER_SUCCESS:
                var currState = state.avistamentos;
                const newState = currState.concat(action.payload.avistamentos)
                
                return {
                    ...state,
                    extraStatus: 'LOADED',
                    error: null,
                    avistamentos: newState
                }
            case FETCH_AVISTAMENTOS_EXTRA_USER_FAILURE:
                return {

                    ...state,
                    extraStatus: 'ERROR',
                    error: action.payload
                }
            case FETCH_AVISTAMENTOS_USER_FAILURE:
                return {

                    ...state,
                    extraStatus: 'ERROR',
                    error: action.payload
                }
            case CLEAR_AVISTAMENTOS_USER:
                return {
                    avistamentos: [],
                    mainStatus: 'WAITING',
                    extraStatus: 'WAITING', 
                    error: null 
                }
            default:
                return state
        }
    }