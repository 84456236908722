import {timeoutPromise} from '../../helpers/timeouters';
export const FETCH_ATAQUES_BEGIN   = 'FETCH_ATAQUES_BEGIN';
export const FETCH_ATAQUES_SUCCESS = 'FETCH_ATAQUES_SUCCESS';
export const FETCH_ATAQUES_FAILURE = 'FETCH_ATAQUES_FAILURE';
export const FETCH_ATAQUE_BEGIN   = 'FETCH_ATAQUE_BEGIN';
export const FETCH_ATAQUE_SUCCESS = 'FETCH_ATAQUE_SUCCESS';
export const FETCH_ATAQUE_FAILURE = 'FETCH_ATAQUE_FAILURE';
export const CLEAR_ATAQUE_POPUP = 'CLEAR_ATAQUE_POPUP';

export const fetchAtaquesBegin = () => ({
    type: FETCH_ATAQUES_BEGIN
});

export const fetchAtaquesSuccess = ataques => ({
    type: FETCH_ATAQUES_SUCCESS,
    payload: {ataques}
});

export const fetchAtaquesFailure = error => ({
    type: FETCH_ATAQUES_FAILURE,
    payload: {error}
});

export const fetchAtaqueBegin = () => ({
    type: FETCH_ATAQUE_BEGIN
});

export const fetchAtaqueSuccess = ataque => ({
    type: FETCH_ATAQUE_SUCCESS,
    payload: {ataque}
});

export const fetchAtaqueFailure = error => ({
    type: FETCH_ATAQUE_FAILURE,
    payload: {error}
});

export const clearAtaquePopup = () => ({
    type: CLEAR_ATAQUE_POPUP
})



export const fetchAtaques = () => {
    const token = localStorage.getItem("token");
    const params = token?{
        method:'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }:{method:'get'};
    return async dispatch =>{
        dispatch(fetchAtaquesBegin());
        try{
            
            const response = await timeoutPromise(6000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/ataques?q=500`, params));
           
            if(response.ok){
                const content = await response.json()
                dispatch(fetchAtaquesSuccess(content))
            }else{
                throw response.status
            }
            

        } catch (error){
            dispatch(fetchAtaquesFailure(error))
        
        } 
    }
}

export const fetchAtaque = (id) => {
    return dispatch =>{
        dispatch(fetchAtaqueBegin());
 
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/ataque/${id}`)
        .then(response => response.json())
        .then(json => {
            dispatch(
                fetchAtaqueSuccess(json)
                )
        })
        .catch((error) => {
            dispatch(fetchAtaqueFailure(error))
        })
    }
}