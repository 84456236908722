export const GET_USERS_LIST_FIRST = 'GET_USERS_LIST_FIRST';
export const GET_USERS_LIST_EXTRA = 'GET_USERS_LIST_EXTRA';
export const GET_USERS_LIST_FIRST_START = 'GET_USERS_LIST_FIRST_START';
export const GET_USERS_LIST_EXTRA_START = 'GET_USERS_LIST_EXTRA_START';
export const GET_USERS_LIST_FIRST_SUCCESS = 'GET_USERS_LIST_FIRST_SUCCESS';
export const GET_USERS_LIST_EXTRA_SUCCESS = 'GET_USERS_LIST_EXTRA_SUCCESS';
export const GET_USERS_LIST_FIRST_FAILED = 'GET_USERS_LIST_FIRST_FAILED';
export const GET_USERS_LIST_EXTRA_FAILED = 'GET_USERS_LIST_EXTRA_FAILED';
export const SEARCH_USER  = 'SEARCH_USER';
export const SEARCH_USER_START  = 'SEARCH_USER_START';
export const SEARCH_USER_SUCCESS  = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILED  = 'SEARCH_USER_FAILED';
export const CLEAR_USERS_LIST = 'CLEAR_USERS_LIST';
export const EDIT_USER = 'EDIT_USER';
export const CLEAR_EDIT_USER = 'CLEAR_EDIT_USER';


export const get_users_list_first_start = () => ({
    type: GET_USERS_LIST_FIRST_START
})

export const get_users_list_first_success = (user) => ({
    type: GET_USERS_LIST_FIRST_SUCCESS,
    payload: user
})

export const get_users_list_first_failed = (error) => ({
    type: GET_USERS_LIST_FIRST_FAILED,
    payload: error
})



export const get_users_list_first = () => {
    const token = localStorage.getItem("token");
    return dispatch => {
        dispatch(get_users_list_first_start());
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizadores?q=10`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(json => {
            dispatch(get_users_list_first_success(json))
        })
        .catch(error => {
            dispatch(get_users_list_first_failed(error))
        })
    }
}

export const get_users_list_extra_start = () => ({
    type: GET_USERS_LIST_EXTRA_START
})

export const get_users_list_extra_success = (json) => ({
    type: GET_USERS_LIST_EXTRA_SUCCESS,
    payload: json
})

export const get_users_list_extra_failed = (error) => ({
    type: GET_USERS_LIST_EXTRA_FAILED,
    payload: error
})

export const get_users_list_extra = (page) => {
    const token = localStorage.getItem("token");
    return dispatch => {
        dispatch(get_users_list_extra_start());
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizadores?q=10&page=${page}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }    
        )
        .then(response => response.json())
        .then(json => {
            dispatch(get_users_list_extra_success(json))
        })
        .catch(error => {
            dispatch(get_users_list_extra_failed(error))
        })
    }
}

export const search_user_start = () => ({
    type: SEARCH_USER_START
})

export const search_user_success = (user) => ({
    type: SEARCH_USER_SUCCESS,
    payload: user
})

export const search_user_failed = (error) => ({
    type: SEARCH_USER_FAILED,
    payload: error
})

export const search_user = (string) => {
    const token = localStorage.getItem("token");
    return dispatch => {
        dispatch(search_user_start());
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizadores?search=${string}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            }
        )
        .then(response => response.json())
        .then(json => {
            dispatch(search_user_success(json))
        })
        .catch(error => {
            dispatch(search_user_failed(error))
        })
    }
}

export const clear_users_list = () => ({
    type: CLEAR_USERS_LIST
})

export const edit_user = (user_data) => ({
    type: EDIT_USER,
    payload: user_data
})

export const clear_edit_user = () => ({
    type: CLEAR_EDIT_USER
})

