import React, {useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Dropzone from 'react-dropzone';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import Loader from 'react-loader-spinner';
import {update_form_avistamento_image, upload_image, update_form_ninho_image, update_form_ataque_image} from '../redux/actions';

function ImageInput(props){
    
    const imageUploadStatus = useSelector(state => state.uploadImage.status)
    const uploadedImage = useSelector(state => state.uploadImage.imagem)
    const imageAvistamento = useSelector(state => state.novoAvistamento.imageInfo)
    const imageAninhamento = useSelector(state => state.novoAninhamento.imageInfo)
    const imageAtaque = useSelector(state => state.novoAtaque.imageInfo)
    const [image, setImage] = useState([]);

    const dispatch = useDispatch();

    var mainImageUploadMessage = 'Arrastar uma imagem ou clicar'

    async function onMainImageDrop(file) {
        const compressOptionsImage = {
            maxWidthOrHeight: 1000
        }

        const compressOptionsThumb = {
            maxWidthOrHeight: 300
        }


        try{
            var imagem_raw;
            if(file[0].type==='image/heic' || file[0].type==='image/heif'){
      
                const blb = new Blob(file[0]);
                imagem_raw = heic2any({
                    blb,
                    toType: "image/jpeg"
                })
            } else{ 
                imagem_raw = file[0]
            }
            const imagem_comp = await imageCompression(imagem_raw, compressOptionsImage)
            const imagem = new File([imagem_comp], imagem_comp.name)

            dispatch(upload_image(imagem))


        } catch{
            mainImageUploadMessage = 'Erro a carregar imagem'
        }
        
    }

    useEffect(() => {
        if(imageUploadStatus === 'UPLOAD_IMAGE_SUCCESS'){
       
            switch(props.tipo){
                case 'aninhamento':
                    dispatch(update_form_ninho_image(uploadedImage))
                    break
                case 'avistamento':
                    dispatch(update_form_avistamento_image(uploadedImage))
                    break
                case 'ataque':
                    dispatch(update_form_ataque_image(uploadedImage))
                    break
                default:
                    break
            }
        }
        
    }, [imageUploadStatus])

    useEffect(()=>{
        switch(props.tipo){
            case 'aninhamento':
                setImage(imageAninhamento)
                break
            case 'avistamento':
                setImage(imageAvistamento)
                break
            case 'ataque':
                setImage(imageAtaque)
                break
            default:
                break
        }
    }, [imageAvistamento, imageAninhamento, imageAtaque]);

   

    return(
        <Dropzone
            onDrop = {onMainImageDrop}
            maxFiles={1}
            accept="image/*"
            minSize={0}
            maxSize={31457280}
            style={{height:'100%'}}
        >
        {({getRootProps, getInputProps}) => (
            <section style={{height:'100%',  textAlign:'center', cursor:'pointer'}}>
                <div {...getRootProps()} style={{height:'100%', width: '100%', overflowY:'hidden', display:'flex', justifyContent:'space-around', flexDirection:'column', alignItems:'center'}}>
                    <input {...getInputProps()} />
                    <div style={{height:'75%', width: '75%'}}>
                        {imageUploadStatus !== 'UPLOADING_IMAGE'?<img
                            src={image.length>0 ? `/imagens/${image[0].url}` : '/placeholder_pic.png'}
                            
                            style={{maxWidth:'100%', maxHeight:'100%', objectFit:'contain'}}/>:<Loader
                                                                                                    type='TailSpin'
                                                                                                    color='#0dafd0ff'
                                                                                                    height='40'
                                                                                                    width='40'
                                                                                        
                                                                                                />}
                    </div>
                    <p>{mainImageUploadMessage}</p>
                </div>
                
            </section>
        )}
        </Dropzone>
    )
}

export default ImageInput;