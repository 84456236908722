import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DateTimePicker from 'react-datetime-picker'
import {
    update_form_avistamento_date,
    update_form_avistamento_vida,
    update_form_avistamento_especie,
    update_form_avistamento_numero, 
    update_form_avistamento_ruido,
    update_form_avistamento_lixo,
    update_form_avistamento_alimentacao,
    update_form_avistamento_detalhes,
    clean_form_avistamento,
    upload_image_clean} from '../redux/actions';

function FormAvistamento(props){
    //componente do formulário de criação de observações, para reportar gaivotas
    const dispatch = useDispatch();
    const formData = useSelector(state => state.novoAvistamento); //estado atual do formulário no state geral

    
    useEffect(() => {
        //quando o componente monta, atualizamos a data do avistamento para a data atual
        const currentDate = new Date();
        dispatch(update_form_avistamento_date(currentDate))
        return(() => {
            //limpa formulário, submissão e imagem carregada
            dispatch(clean_form_avistamento())
            dispatch(upload_image_clean())
        }
        )
    }, [])



    const handleChange = e => {
        //quando um campo do formulário é alterado, atualizar no sítio respetivo do state geral da app
        switch(e.target.name){
            case 'data':
                dispatch(update_form_avistamento_date(e.target.value))
                break
            case 'especie':
                dispatch(update_form_avistamento_especie(e.target.value))
                break
            case 'numero':
                dispatch(update_form_avistamento_numero(e.target.value))
                break
            case 'vida':
                dispatch(update_form_avistamento_vida(e.target.checked))
                break
            case 'ruido':
                dispatch(update_form_avistamento_ruido(e.target.checked))
                break
            case 'lixo':
                dispatch(update_form_avistamento_lixo(e.target.checked))
                break
            case 'detalhes':
            
                dispatch(update_form_avistamento_detalhes(e.target.value))
                break
            case 'alimentacao':
              
                dispatch(update_form_avistamento_alimentacao(e.target.value))
                break
            default:
                break

        }
     
    }

    return(
        <div className='form-group'>
            <div className='form-group-element'>
                <div className='form-element'>
                    <label htmlFor='data' style={{display:'block'}} >Data e hora do avistamento</label>
                    <DateTimePicker name='data' id='data' onChange={(v) => handleChange({target:{name:'data', value:v}})} value={formData.data} disableClock={true} maxDate={new Date()} clearIcon={null} style={{width:'100%'}}/>
                </div>
                <div className='form-element'>
                    <label htmlFor='especie' style={{display:'block'}}>Espécie</label>
                    <select id='especie' name='especie' onChange={handleChange} style={{width:'100%'}}>
                        <option value='Não sei'>Não sei</option>
                        <option value='Larus michahellis'>Larus michahellis</option>
                        <option value='Larus fuscus'>Larus fuscus</option>
                    </select>
                </div>
                
             
                <div className='form-element'>
                    <label htmlFor='detalhes' style={{display:'block'}}>Detalhes</label>
                    <textarea type='text' name='detalhes' id='detalhes' rows={'5'} cols={'40'} value={formData.detalhes} onChange={handleChange} style={{width:'100%', boxSizing:'border-box'}}></textarea>
                </div>
            </div>
            <div className='form-group-element'>
                <div className='form-element'>
                    <label htmlFor='numero' style={{display:'block'}}>Número de indivíduos</label>
                    <input type='number' name='numero' id='numero' min={1} value={parseInt(formData.numero)} onChange={handleChange} style={{width:'100%', boxSizing: 'border-box'}}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='vida' style={{display:'block'}}>Espécime(s) vivo(s)</label>
                    <input type='checkbox' name='vida' id='vida' checked={formData.vida} onChange={handleChange}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='ruido' style={{display:'block'}}>A causar ruído</label>
                    <input type='checkbox' name='ruido' id='ruido' checked={formData.ruido} onChange={handleChange} />
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='progenitores' style={{display:'block'}}>A mexer em lixo urbano</label>
                    <input type='checkbox' name='lixo' id='lixo' checked={formData.lixo} onChange={handleChange} />
                </div>
                <div className='form-element'>
                    <label htmlFor='alimentacao' style={{display:'block'}}>Alimentação</label>
                    <select id='alimentacao' name='alimentacao' onChange={handleChange} style={{width:'100%'}}>
                        <option value='Não se está a alimentar'>Não se está a alimentar</option>
                        <option value='A alimentar-se'>A alimentar-se</option>
                        <option value='A ser alimentada'>A ser alimentada</option>
                    </select>
                </div>
                
            </div>
        </div>
    )
}

export default FormAvistamento;