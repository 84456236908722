import React, {useEffect, useState} from 'react';
import './FormGeral.css';
import {useDispatch, useSelector} from 'react-redux';
import SelectionMap from './SelectionMap';
import ImageInput from './ImageInput';
import FormAvistamento from './FormAvistamento';
import FormAninhamento from './FormAninhamento';
import FormAtaque from './FormAtaque';
import { Link } from 'react-router-dom';
import {submit, submission_clean} from '../redux/actions'

function FormGeral(props){
    //formulário de submissão de nova observação. Lida com a submissão de imagem, que é comum a todos os tipos de observação, e inclui um sub-componente para submissão de local de 
    //observação num mapa, também comum. Os dados específicos de cada tipo de observação são geridos por sub-componentes diferentes, carregados de acordo com o tipo de observação.

    //dados da nova observação no state, cada tipo de avistamento tem um espaço separado, só vamos usar um dependendo do tipo de observação a submeter,
    //mas como o useSelector não pode ser condicional, carregamos os três:
    const dadosAvistamento = useSelector(state => state.novoAvistamento);
    const dadosAninhamento = useSelector(state => state.novoAninhamento);
    const dadosAtaque = useSelector(state => state.novoAtaque);

    const [open, setOpen] = useState('imagem'); //em mobile separamos o formulário por secções, gardamos aqui qual está aberta de momento
    const [detalhesHeight, setDetalhesHeight] = useState(400); //guardamos a altura do form, calculada mais abaixo

    const submissionStatus = useSelector(state => state.submissao.status); //estado da submissão no backend
    const submissionError = useSelector(state => state.submissao.error); //mensagem de erro na submissão, se alguma

    const dispatch = useDispatch();

    var formData; // dados da observação, escolhidos a seguir de entre os selectors anteriores, de acordo com o tipo de observação a submeter

    if(props.tipo==='avistamento'){
        formData = dadosAvistamento
  
    }else if(props.tipo==='aninhamento'){
        formData = dadosAninhamento
 
    }else if(props.tipo==='ataque'){
        formData = dadosAtaque
   
    }

    const handleSubmit = () => {
        //submetemos os dados através de uma redux action, indicando o tipo de observação
        dispatch(submit(formData, `${props.tipo}s`));
    }

    useEffect(() => {
        //calculamos a altura do formulário, que será a altura dele quando carrega menos a altura do cabeçalho, que pertence ao parent component, de maneira a não sair fora (em mobile)
        const height = document.getElementById('form-id').clientHeight;
        setDetalhesHeight(height-100);

        return () => {dispatch(submission_clean())}
    }, [])

    var form;
    if(window.innerWidth>810){ //desktop
        return(
            <div className='form-body' id='form-id'>
                {submissionStatus!=='SUBMITTED'?<div
                    //a menos que a submissão tenha sido feita com sucesso, mostramos o formulário
                    className='scrollbar-custom'
                    style={{overflowY:'auto', textAlign: 'left', height:'100%', padding:'10px', boxSizing: 'border-box'}}
                >
                    <div style={{display:'flex'}}>
                        <div style={{width:'35%'}}>
                            {//input de imagem, comum a todos os tipos de observação, embora lhe passemos o tipo para selecionar a imagem placeholder
                            }
                            <ImageInput tipo = {props.tipo}/>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly', width: '100%'}}>
                            {//formulário para submeter dados, de acordo com o tipo de observação
                            }
                            {props.tipo==='avistamento'?<FormAvistamento />:null} 
                            {props.tipo==='aninhamento'?<FormAninhamento />:null}
                            {props.tipo==='ataque'?<FormAtaque />:null}
                            {//se não estivermos no processo de submeter o avistamento ao backend, mostramos o botão de submissão ativo
                            //se estivermos, mostramos um botão inativo a indicar isso
                            submissionStatus!=='SUBMITTING'?<button
                                className='custom-button'
                                type='button'
                                disabled={!formData.concelho} //caso o ponto selecionado no mapa esteja fora da área de estudo e por isso não tenha concelho associado
                                onClick={handleSubmit}
                                >
                                    Submeter
                                </button>:<button
                                    type='button'
                                    active={false}>
                                        A submeter...
                                        </button>
                            }
                        </div>
                        </div>
                        <div>{formData.concelho? `Concelho: ${formData.concelho}`:'Fora da área de estudo' //caso o ponto selecionado no mapa esteja fora da área de estudo e por isso não tenha concelho associado
                        }</div>
                        <div style={{width:'100%', height:'50%'}}>
                           <SelectionMap tipo = {props.tipo} />
                        </div>
                        {submissionStatus==='FAILED'?<div>{submissionError}</div>:null}
                    
                        
                    
                </div>:<div>
                        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign:'center'}}>
                            <h1>Observação submetida!</h1>
                            <Link to={{pathname:'/'}}><button className='custom-button'>Continuar</button></Link>

                        </div>
                    </div>}
                
            </div>
        )
    }else{
        return(
            <div className='form-body' id='form-id'>
                {submissionStatus!=='SUBMITTED'?<>
                
                <div style={{height:open==='imagem'?'250px':'50px', borderBottom:'1px solid black'}}>
                    <div onClick={() => setOpen('imagem')} style={{height:open==='imagem'?'25%':'100%', display:'flex', alignItems: 'center', padding: '5px', boxSizing:'border-box', justifyContent:'space-between'}}>
                        <div>Submeter imagem</div>
                        <img
                            src='/chevron-right.png'
                            style=
                                {
                                    {
                                        height: '0.7em',
                                        marginRight: '15px',
                                        
                                        verticalAlign:'middle',
                                        transform: open==='imagem'?'rotate(90deg)':'none'
                                    }
                                }
                            />
                        
                    </div>
                    
                    {open==='imagem'?<div style={{height:'75%'}}>
                        <ImageInput tipo = {props.tipo}/>
                    </div>:null}
                </div>
                <div style={{height:open==='mapa'?'400px':'50px', borderBottom:'1px solid black'}}>
                    <div onClick={() => setOpen('mapa')} style={{height:open==='mapa'?'12.5%':'100%', display:'flex', alignItems: 'center', padding: '5px', boxSizing:'border-box', justifyContent:'space-between'}}>
                        <div>Selecionar local</div>
                        <img
                            src='/chevron-right.png'
                            style=
                                {
                                    {
                                        height: '0.7em',
                                        marginRight: '15px',
                                        verticalAlign:'middle',
                                        transform: open==='mapa'?'rotate(90deg)':'none'
                                    }
                                }
                            />
                    </div>
                    <div style={{height:open==='mapa'?'79%':'0%'}}>
                    {/* <div style={{height:'7.5%', fontSize:'0.75em', marginLeft:'5px'}}>{formData.concelho? `Concelho: ${formData.concelho}`:'Fora da área de estudo'}</div> */}
                        <SelectionMap tipo = {props.tipo} />
                    </div>
                    
                </div>
                <div style={{height:open==='detalhes'?`${detalhesHeight}px`:'50px', borderBottom:'1px solid black'}}>
                    <div onClick={() => setOpen('detalhes')} style={{height:open==='detalhes'?'12.5%':'100%', display:'flex', alignItems: 'center', padding: '5px', boxSizing:'border-box', justifyContent:'space-between'}}>
                        <div>Preencher detalhes</div>
                        <img
                            src='/chevron-right.png'
                            style=
                                {
                                    {
                                        height: '0.7em',
                                        marginRight: '15px',
                                        verticalAlign:'middle',
                                        transform: open==='detalhes'?'rotate(90deg)':'none'
                                    }
                                }
                            />
                    </div>
                    <div style={{height:open==='detalhes'?'79%':'0%', overflow:open==='detalhes'?'scroll':'hidden', margin:'10px'}}>
                        
                            {props.tipo==='avistamento'?<FormAvistamento />:null} 
                            {props.tipo==='aninhamento'?<FormAninhamento />:null}
                            {props.tipo==='ataque'?<FormAtaque />:null}
                            
                            {submissionStatus!=='SUBMITTING'?<button className='custom-button' type='button' disabled={!formData.concelho} onClick={handleSubmit}>Submeter</button>:<button type='button' active={false}>A submeter...</button>}
                        
                    </div>
                </div>
                </>:<div>
                        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign:'center'}}>
                            <h1>Observação submetida!</h1>
                            <Link to={{pathname:'/'}}><button className='custom-button'>Continuar</button></Link>

                        </div>
                    </div>}
            </div>
        )
    }
    
}

export default FormGeral;