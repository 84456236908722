import React from 'react';


function EditAtaque(props){
    //componente de edição de observações do tipo ataque. Todo o processo é handled pelo parent component, os dados a mostrar e funções a chamar chegam como props.
    //este componente apenas lida com os dados específicos deste tipo de observação, os dados comuns a todos os tipos são editados no parent
    return(
        <>
            <p style={{display:'block', marginBottom:0}}>Alvo</p>
                    <ul style={{width:'100%', listStyleType:'none'}}>

                        <li key='Adulto'><input type='checkbox'  name='alvo' value='Adulto' checked={props.observation.properties.alvo['Adulto']} onChange={props.handleChange}/>Adulto</li>
                        <li key='Criança'><input type='checkbox' name='alvo' value='Criança' checked={props.observation.properties.alvo['Criança']} onChange={props.handleChange}/>Criança</li>
                        <li key='AEstimação'><input type='checkbox' name='alvo' value='AEstimação' checked={props.observation.properties.alvo['AEstimação']} onChange={props.handleChange}/>Animal de estimação</li>
                        <li key='ASelvagem'><input type='checkbox' name='alvo' value='ASelvagem' checked={props.observation.properties.alvo['ASelvagem']} onChange={props.handleChange}/>Animal selvagem</li>
                        <li key='Objeto'><input type='checkbox' name='alvo' value='Objeto' checked={props.observation.properties.alvo['Objeto']} onChange={props.handleChange}/>Objeto</li>
                    </ul>
                
            <div className='form-element'>
                <label htmlFor='descricao' style={{display:'block'}}>Descrição</label>
                <textarea type='text' name='descricao' id='descricao' rows={'5'} cols={'40'} value={props.observation.properties.descricao} onChange={props.handleChange} style={{width:'100%', boxSizing:'border-box'}}></textarea>
            </div>
        </>
    )
}

export default EditAtaque;