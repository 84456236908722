import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { edit_user_self, edit_user_self_clean } from '../redux/actions';
import { useHistory } from 'react-router-dom';

function PickAvatarModal(){
    const [avatar, setAvatar] = useState(0)
    const dispatch = useDispatch();
    const history = useHistory();
    const editStatus = useSelector(state => state.userStatus.userUpdateStatus)
    const userData = useSelector(state => state.userStatus.profile)
    const n_avatares=8;

    useEffect(() => {
        setAvatar(userData.avatar)
    }, [userData])

    useEffect(() => {
        if(editStatus ==='edit_success'){
            history.goBack()
        }
        return () => dispatch(edit_user_self_clean())
    }, [editStatus])



    const handleCancel = () => {
        
        history.goBack()
    }

    const handleSubmit = (e) => {
       
        dispatch(edit_user_self(userData.id, {avatar: avatar}))
    }

    const moveImage =(dir) => {
        if(dir==='lower' && avatar>0){
         
            setAvatar(avatar-1)
        
        }else if(dir==='higher' && avatar<n_avatares){
            
            setAvatar(avatar+1)
           
        }
    }
     return(
         <div style={{height:'100%', padding:'10px'}}> 
            
            {editStatus!=='edit_success'?<div>
                        
                        {editStatus==='edit_failed' ?'Ocorreu um erro. Por favor tente novamente.':null}
                        <div style={{display:'flex', justifyContent: 'center', alignContent: 'center'}}><img src={`/avatars/avatar${avatar}.png`}/></div>
                        <div style={{display:'grid', gridTemplateColumns:'50% 50%'}}>
                            {avatar>0?<div onClick={() => moveImage('lower')} style={{gridColumn:1, textAlign:'center', cursor: 'pointer'}}>Anterior</div>:null}
                            {avatar<n_avatares?<div onClick={() => moveImage('higher')} style={{gridColumn:2, textAlign:'center', cursor: 'pointer'}}>Próxima</div>:null}
                        </div>
                        </div>:<div>Editado!</div>}
                        
                    <div style={{display:'flex', justifyContent: 'space-around', alignContent: 'center', marginTop:'10px'}}> <button className='button-cancel' onClick={handleCancel}>Cancelar</button> <button className='custom-button' onClick={handleSubmit}>Alterar</button></div>
                   
            
            
        </div>
    )
}

export default PickAvatarModal


