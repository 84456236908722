import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import { get_excel} from '../redux/actions';
import './AdminPage.css';

import UsersList from './UsersList';


function AdminPage(){
    //página de administração, com uma barra lateral onde podem ser escolhidas opções (neste momento, ver lista de utilizadores ou descarregar observações)
    //e uma área principal, cujo conteúdo varia dependendo da escolha feita na barra lateral.

    const dispatch = useDispatch();
    const [showing, setShowing] = useState('users'); //aquilo que está a ser mostrado na área principal, escolhido na barra lateral

    //não vale a pena tornar esta componente responsive, por isso caso o dispositivo seja de pequenas dimensões, só mostramos um erro
    if(window.innerWidth<=1024) return <div>A área de administração está otimizada para utilização em computador desktop ou laptop.</div>
    
    return(
        
        <div className='page-body'>
            <div
                className='options-bar' 
            >
                <div
                    //seleciona para ser mostrada na área principal a lista de utilizadores registados, a partir da qual estes podem ser editados
                    style={showing==='users'?{fontWeight:'bold'}:{cursor:'pointer'}} onClick={() => setShowing('users')}
                >
                    Gerir utilizadores
                </div>

                <div
                    //mostra na área principal o link para descarregar a lista de observações em formato xlsx
                    style={showing==='download'?{fontWeight:'bold'}:{cursor:'pointer'}} onClick={() => setShowing('download')}
                >
                    Descarregar avistamentos
                </div>  
            </div>
    
                {showing==='download'?<div
                    //request para descarregar o xlsx com avistamentos
                    onClick={() => dispatch(get_excel())} style={{cursor:'pointer'}}
                >
                    Descarregar todos os avistamentos
                </div>:null}
                
                {showing==='users'?<UsersList />:null}             

        </div>
    )
   
    
    

    
}

export default AdminPage;