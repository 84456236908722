export default function novoAninhamentoReducer(
    state = {
        data: null,
        long:null,
        lat: null,
        alvo: {Adulto: false, Criança: false, AEstimação: false, ASelvagem: false, Objeto: false},
        descricao: '',
        imageInfo: [],
        concelho:null

    },
    action){
        switch(action.type){
            case 'UPDATE_FORM_ATAQUE_DATE':
                return {...state, data: action.payload}
            case 'UPDATE_FORM_ATAQUE_COORDS':
                return {...state, long: action.payload[1], lat:action.payload[0]}
            case 'UPDATE_FORM_ATAQUE_ALVO':
                
                return {...state, alvo: {
                    ...state.alvo,
                    [action.payload]:!state.alvo[action.payload]
                    }
                }
            case 'UPDATE_FORM_ATAQUE_DESCRICAO':
                return {...state, descricao: action.payload}
            case 'UPDATE_FORM_ATAQUE_IMAGE':
                return {...state, imageInfo:action.payload}
            case 'UPDATE_FORM_ATAQUE_CONCELHO':
                return {...state, concelho:action.payload}
            case 'CLEAR_ATAQUE_FORM':
                return {...state, data: null, long:null, lat:null, alvo: 'Adulto', descricao:'', imageInfo:[], concelho:null}
            default:
                return state
        }
    }