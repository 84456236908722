const locationReducer = (state = {location: [0, 0], accuracy: 0, hasLocation: false, error:false}, action) => {
    let newState;
    switch(action.type){
        case 'UPDATE_LOCATION':
            newState = {...state, location: action.payload.location, accuracy: action.payload.accuracy, hasLocation: true, error:false}
            return newState;
        case 'LOCATION_ERROR':
            newState = {...state, error:true, hasLocation: false}
            return newState;
        default:
            return state;
    }
}

export default locationReducer;