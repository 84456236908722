
import {timeoutPromise} from '../../helpers/timeouters';
import { fetchAninhamentos } from './aninhamentosActions';
import { fetchAvistamentos } from './avistamentosActions';
import { fetchAtaques } from './ataquesActions';

export const submit_start = () => {
    return {
        type:'SUBMISSION_START'
    }
}

export const submit_success = () => {
    return {
        type:'SUBMISSION_SUCCESS'
    }
}

export const submit_failure = (error) => {
    return {
        type: 'SUBMISSION_FAILED',
        payload:error
    }
}

export const submission_clean = () => {
    return {
        type: 'SUBMISSION_CLEAN'
    }
}

export const submit = (submissionData, target) => {

    const placeholder = {avistamentos: 'gull_large.png', aninhamentos: 'nest_large.png', ataques:'ataque_large.png'}

    return async dispatch => {
        dispatch(submit_start());
        try{
            if(submissionData.imageInfo.length===0) {submissionData.imageInfo.push({url: placeholder[target]})}

            const token = localStorage.getItem("token");
            const submission = await timeoutPromise(8000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/${target}`,{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(submissionData)
                })
                );
            
            if(submission.ok){
                dispatch(submit_success())
                dispatch(fetchAvistamentos())
                dispatch(fetchAninhamentos())
                dispatch(fetchAtaques())
            }else{
                throw submission.status
            }
            

        } catch (error){
            dispatch(submit_failure(error))
         
        } 
    }
}

