import {timeoutPromise} from '../../helpers/timeouters';
export const FETCH_AVISTAMENTOS_BEGIN   = 'FETCH_AVISTAMENTOS_BEGIN';
export const FETCH_AVISTAMENTOS_SUCCESS = 'FETCH_AVISTAMENTOS_SUCCESS';
export const FETCH_AVISTAMENTOS_FAILURE = 'FETCH_AVISTAMENTOS_FAILURE';
export const FETCH_AVISTAMENTO_BEGIN   = 'FETCH_AVISTAMENTO_BEGIN';
export const FETCH_AVISTAMENTO_SUCCESS = 'FETCH_AVISTAMENTO_SUCCESS';
export const FETCH_AVISTAMENTO_FAILURE = 'FETCH_AVISTAMENTO_FAILURE';
export const CLEAR_AVISTAMENTO_POPUP = 'CLEAR_AVISTAMENTO_POPUP';

export const fetchAvistamentosBegin = () => ({
    type: FETCH_AVISTAMENTOS_BEGIN
});

export const fetchAvistamentosSuccess = avistamentos => ({
    type: FETCH_AVISTAMENTOS_SUCCESS,
    payload: {avistamentos}
});

export const fetchAvistamentosFailure = error => ({
    type: FETCH_AVISTAMENTOS_FAILURE,
    payload: {error}
});

export const fetchAvistamentoBegin = () => ({
    type: FETCH_AVISTAMENTO_BEGIN
});

export const fetchAvistamentoSuccess = avistamento => ({
    type: FETCH_AVISTAMENTO_SUCCESS,
    payload: {avistamento}
});

export const fetchAvistamentoFailure = error => ({
    type: FETCH_AVISTAMENTO_FAILURE,
    payload: {error}
});

export const clearAvistamentoPopup = () => ({
    type: CLEAR_AVISTAMENTO_POPUP
})



export const fetchAvistamentos = () => {
    const token = localStorage.getItem("token");

    const params = token?{
        method:'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }:{method:'get'};
    return async dispatch =>{
        dispatch(fetchAvistamentosBegin());
        try{
            
            const response = await timeoutPromise(6000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/avistamentos?q=500`, params));
           
            if(response.ok){
                const content = await response.json()
                dispatch(fetchAvistamentosSuccess(content))
            }else{
                throw response.status
            }

        } catch (error){
            dispatch(fetchAvistamentosFailure(error))
  
        } 
    }
}

export const fetchAvistamento = (id) => {
    return dispatch =>{
        dispatch(fetchAvistamentoBegin());
   
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/avistamento/${id}`)
        .then(response => response.json())
        .then(json => {
            dispatch(
                fetchAvistamentoSuccess(json)
                )
        })
        .catch((error) => {
            dispatch(fetchAvistamentoFailure(error))
        })
    }
}