import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import './EditUserPage.css';
import ListaAvistamentos from './ListaAvistamentos';

function EditUserPage(){
    //página de edição de utilizador, destinada a admins, para que estes possam ver as informações de um utilizador e editá-las.
    const userData = useSelector(state => state.usersList.editingUser); //dados do utilizador a ver visualizado/editado.
    const date = new Date(userData.joined); //formata a data de criação do utilizador para visualização
    const [observacoes, setObservacoes] = useState(0); //guarda o número de observações total do utilizador, calculado a seguir

    useEffect(() => {
        //núemro de observações total do utilizador, somando as de cada tipo
        setObservacoes(userData.n_animais + userData.n_ninhos + userData.n_ataques)
        
    }, []);
    
    return(
        <div className='page-body'>
            <div
                className='user-info' 
            >
                    <img
                        className='user-image'
                        src={`/avatars/avatar${userData.avatar}.png`}
                        
                    />
                    <div className='user-info-text'>
                        <div >
                            {userData.email}
                        </div>
                        <div >
                            {userData.username}
                        </div>
                        <div >
                            {date.toLocaleDateString("pt-PT")} {date.toLocaleTimeString("pt-PT", {hour:'2-digit', minute:'2-digit'})}
                        </div>
                        <div >
                            {userData.role}
                        </div>
                        <div style={{fontWeight:'bold'}}>Observações:</div>
                        <div >
                            {userData.n_animais} gaivotas
                        </div>
                        <div >
                            {userData.n_ninhos} ninhos
                        </div>
                        <div >
                            {userData.n_ataques} ataques
                        </div>
 
                        
                    </div>
                
                    
            </div>
         
                <ListaAvistamentos
                    //observações do utilizador
                    tipo = 'administrador'
                    n_obs={observacoes}
                />   
                    
  
        </div>
    )
   
    
    

    
}

export default EditUserPage;