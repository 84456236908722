import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import thunk from 'redux-thunk';

//Usar este compose enhancer é para adicionar a extensão que permite ver o estado do redux no browser
//O thunk permite fazer operações assíncronas (e.g. API calls) dentro das actions do redux

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer,
    composeEnhancers(
        compose(
            applyMiddleware(thunk)
        )
    )
    );