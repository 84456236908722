import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { request_logout } from '../redux/actions';
import './AccountPage.css';
import {Link} from 'react-router-dom';
import ListaAvistamentos from './ListaAvistamentos';

function AccountPage(){
    //página da conta de utilizador, com uma barra lateral (cabeçalho em mobile) com a info do utilizador e opções,
    //e a lista dos avistamentos na zona principal

    const dispatch = useDispatch();
    const userData = useSelector(state => state.userStatus.profile); //dados do utilizador
    const avistamentos = useSelector(state => state.avistamentosUser.avistamentos); //lista de avistamentos obtida do backend
    const [observacoes, setObservacoes] = useState(0); //número de observações do utilizador, calculada em baixo, usada para mostrar e para passar como prop à lista de observações

    useEffect(() => {
        //em todas as observações existe um campo com informação do utilizador, que inclui o número de avistamentos de cada tipo.
        //quando estiver carregada a lista de observações, usamos essa informação de uma delas para calcular o total
        const summer = () => {
            //função que, se houverem observações carregadas, pega na primeira, extrai o número de observações de cada tipo, 
            //e retorna a soma.
            if(avistamentos.length !==0){
                return avistamentos[0].properties.id_user.n_animais + avistamentos[0].properties.id_user.n_ninhos + avistamentos[0].properties.id_user.n_ataques
            }
            return 0
        }
        setObservacoes(summer()); //usamos a função anterior para atualizar o número total de observações do utilizador
        
    }, [avistamentos]);

    return(
        <div className='page-body'>
            <div
                //barra lateral, ou cabeçalho em mobile, com info do utilizador
                className='user-info' 
            >
                <img
                    //avatar do utilizador
                    className='user-image'
                    src={`/avatars/avatar${userData.avatar}.png`}
                    
                />
                <div
                    //info do utilizador e links para opções da conta, abaixo do avatar em desktop e ao lado em mobile. No caso de a conta ter
                    //privilégios de administrador, também se encontram links para as áreas de administração da app.
                    className='user-info-text'>
                    <div >
                        {userData.email}
                    </div>
                    <div style={{fontSize:20}}>{observacoes} observações</div>
                    <Link
                        to={'/minha-conta/editar-avatar'}
                        style={{color:'blue', cursor: 'pointer', textAlign: 'center', textDecoration:'none'}}
                        >
                            Editar avatar
                    </Link>
                    {userData.role==='Administrador' ?
                        //link para a página de validação de observações, só para admins 
                        <Link
                            to={'/validar'}
                            style={{color:'blue', cursor: 'pointer', textAlign: 'center', textDecoration:'none'}}>
                                <div>Validar</div>
                        </Link>:null}

                    {userData.role==='Administrador' ?
                        //link para a página de administração da app, só para admins
                        <Link
                            to={'/admin'}
                            style={{color:'blue', cursor: 'pointer', textAlign: 'center', textDecoration:'none'}}>
                                <div>Administração</div>
                        </Link>:null}
                    
                    <Link
                        to={'/minha-conta/alterar-password'}
                        style={{color:'blue', cursor: 'pointer', textAlign: 'center', textDecoration:'none'}}>
                            Alterar password
                    </Link>
                    <Link
                        to={'/minha-conta/eliminar'}
                        style={{color:'red', cursor: 'pointer', textAlign: 'center', textDecoration:'none'}}>
                            Eliminar conta
                    </Link>
                    <div>
                        <div
                            style={{color:'red', cursor: 'pointer', textAlign: 'center', textDecoration:'none', marginTop:'20px'}}
                            onClick={() => dispatch(request_logout())}>
                                Terminar sessão
                        </div>
                    </div>
                    
                    
                </div>
                
                    
            </div>
        
            <ListaAvistamentos
                //lista de observações, este elemento é usado sempre que precisamos de uma lista de observações (e.g. validação), por isso aqui especificamos o tipo de observações que vamos mostrar, os de um utilizador
                tipo = 'utilizador'
                n_obs = {observacoes} //número total de observações, usado nos requests posteriores
            />   
                    
        </div>
        
    )
   
    
    

    
}

export default AccountPage;