import React, {useEffect, useState} from 'react';
import MapArea from './map/MapArea';
import Sidebar from './elements/Sidebar';
import CookieConsent from 'react-cookie-consent';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import {fetchAninhamentos, fetchAvistamentos, fetchAtaques, show_sidebar, hide_sidebar} from './redux/actions';
import {useHistory} from 'react-router';

function MainWindow() {
    // A janela principal da app, responsável por montar o mapa e a sidebar, e por fazer a primeira chamada para obter os dados de avistamentos para o mapa.
    // Inclui ainda um elemento para mostrar uma barra de seleção de cookies.
    const history=useHistory();
    const dispatch = useDispatch();

    const sidebarShowing = useSelector(state => state.sidebar.show); //estado da barra lateral, visível ou não

    const [prevLoc, setPrevLoc] = useState("/"); //útil mais abaixo, para guardar a última localizão (i.e. a última página aberta dentro da app). Página inicial por defeito.

    useEffect(() => {
        //isto é um dirty trick para tentar que a app se comporte como uma app móvel em mobile, onde é suposto a barra lateral funcionar como uma espécie de menu inicial.
        // para isso, queremos que sempre que um utilizador fizer retroceder em mobile, voltemos à página anterior, excepto se estivermos no mapa.
        //se estivermos no mapa em mobile, funcionalmente estamos na página inicial com a barra lateral escondida, e queremos que quando o utilizador retroceder
        //novamente, nos mantenhamos nessa página mas a barra lateral abra, que do ponto de vista do utilizador é igual a sair da página do mapa para um menu inicial.

        history.listen((loc, action) => {
            //quando carregamos o componente, começamos a vigiar a história do browser, e a tomar nota da página em que estamos em cada momento
            setPrevLoc(loc.pathname)
            //como estamos a vigiar a história, também recebemos as ações do utilizador quando ele, p.ex., retrocede, por isso podemos:
            if(window.innerWidth <=810 && action==='POP' && prevLoc === '/'){
                //se estivermos em mobile, recebermos uma ação 'POP' no história (i.e. retroceder), e já estivermos na página inicial:

                history.push('/'); //vamos para a página inicial, j para impedir de retroceder demasiado e sair da app
                
                dispatch(show_sidebar()); //mostramos a sidebar
                
            }

        });

        return history.unlisten;
    }, []
      )

    useEffect(() => {
        // pedimos para carregar os avistamentos quando abrimos a app pela primeira vez
        dispatch(fetchAninhamentos())
        dispatch(fetchAvistamentos())
        dispatch(fetchAtaques())
    }, [])
      

    return(
        <div
            id='whole'
                style={{
                "position": "absolute",
                "width": "100vw",
                "height": "100vh"
                }
                }
            >

            <div>
                {//um botão, fixo na página, para esconder a sidebar, em desktop
                    sidebarShowing && window.innerWidth>810?<div
                        onClick={() => dispatch(hide_sidebar())}
                        style = {
                            {
                            width:'64px',
                            height:'48px',
                            position: 'absolute',
                            cursor:'pointer',
                            left: '10px',
                            top: '10px',
                            zIndex:'1700',
                            display: 'flex',
                            flexDirection:'column',
                            fontWeight:'bold',
                            alignContent: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F7F9F9',
                            borderRadius: '2px',
                            
                            }
                        }
                    >
                        <div style={{display:'flex', justifyContent:'space-around'}}>
                            <img src='/map.png' alt="" style={{width:'32px', height:'32px'}}/>
                        </div>
                        <div style={{fontSize:'0.8em', display:'flex', justifyContent:'space-around'}}>Ver mapa</div>
                    </div>:null
                }
                   
            </div>
            
            {sidebarShowing ? <Sidebar/> :null}
                
            <MapArea />

            <CookieConsent
                location='bottom'
                buttonText='Compreendi'
                cookieName='AutorizaCookies'
                declineButtonText='Não autorizo'
                style={{zIndex:5000}}
                expires={150}>Esta aplicação utiliza cookies para melhorar a experiência do utilizador e para agregar anonimamente estatísticas de utilização.  <Link to={'/termos'}>Saber mais.</Link>
            </CookieConsent>
            
        </div>

        
        
    )
}

export default MainWindow;
