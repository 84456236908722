import React, {useState} from 'react';
import './App.css';
import {isIE} from 'react-device-detect';
import Loader from 'react-loader-spinner';
import Modal from './elements/Modal';
import IE from './pages/IE';
import MainWindow from './main'; 
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import { useCallback, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { login_success, request_logout } from './redux/actions';
import PrivateRoute from './utils/PrivateRoute';
import ConfirmationModal from './elements/ConfirmationModal';
import checkTokenValidity from './helpers/checkTokenValidity';



function App() {
  //módulo principal da app, carrega os dados de utilizador, se houver na memória do browser, e as rotas para as diferentes páginas com o react-router.

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // guardamos o estado, até os dados de utilizador estarem carregados, mantemos loading

  useEffect(() => {
    const userData = localStorage.getItem("user_data"); // se o utilizador tiver dados guardados no browser, de uma sessão anterior, carregamos
    
    
    if(userData){
      // se tivermos verificado que havia dados de utilizador guardados, vamos buscar o token para o verificar (se ainda está na data de validade)
      const token = localStorage.getItem("token");
      if(checkTokenValidity(token)){
        //se o token ainda estiver válido, fazemos login (hijacking a action que é usada no processo normal de login)
        dispatch(login_success(JSON.parse(userData)));
      }else{
        //se o token já não for válido, limpamos do estado.
        dispatch(request_logout());
      }
      
    }
    setLoading(false)
  },[]);

  if(isIE){

    //just checking if it's Internet Explorer, we don't do Internet Explorer
    return IE
  } else {
    if(loading){
      return(
        <Loader
            type='TailSpin'
            color='#0dafd0ff'
            height='40'
            width='40'
        
        />
      )
    }else{
      // carregamos as rotas na app, é melhor ver os componentes indivíduais. As private routes são aquelas em que é necessário login, daí serem carregadas de forma diferente
      // Provavelmente há variações no método, pesquisando por "react-router private route" há vários resultados.
      
      //A app está basicamente toda estruturada com modals, ou seja, a MainWindow está permanentemente visível, e as diversas páginas surgem por cima.
      return(
        <Router basename='/'>
          <Route path='/' render={(location)=><MainWindow location={location}/>} />
          <Route path='/filtro' render={() =><ConfirmationModal tipo='filtrar'/>} />
          <PrivateRoute path='/novo-avistamento-gaivota' component={Modal} tipo={'avistamento'} titulo={'Reportar gaivota'} />
          <PrivateRoute path='/novo-avistamento-ninho' component={Modal} tipo={'aninhamento'} titulo={'Reportar ninho'} />
          <PrivateRoute path='/novo-ataque' component={Modal} tipo={'ataque'} titulo={'Reportar ataque'} />
          <PrivateRoute path='/minha-conta' component={Modal} tipo={'conta'} titulo={'Minha conta'} />
          <PrivateRoute path='/minha-conta/editar-avatar' component={ConfirmationModal} tipo={'editAvatar'} />
          <PrivateRoute path='/validar' component={Modal} tipo={'validar'} titulo={'Validar'} />
          <PrivateRoute path='/admin' component={Modal} tipo={'admin'} titulo={'Administração'} />
          <PrivateRoute path='/admin/utilizador' component={Modal} tipo={'admin-user'} titulo={'Administração'}/>
          <PrivateRoute path='/validar/validar_detalhes' component={ConfirmationModal} tipo={'validar'} />
          <PrivateRoute path='*/confirmar' component={ConfirmationModal} tipo={'delete'} />
          <PrivateRoute path='/minha-conta/alterar-password' component={ConfirmationModal} tipo={'novaPassword'} />
          <PrivateRoute path='/minha-conta/eliminar' component={ConfirmationModal} tipo={'delete_account'} />
          <Route path='/login' render={() => <Modal tipo={'login'} titulo={'Entrar'}/>} />
          <Route path='/reposicao/:id/:token' render={() => <Modal tipo={'reset'} titulo={'Repor password'}/>} />
          <Route path='/termos' render={() => <Modal tipo={'termos'} titulo={'Termos de Serviço'}/>} />
        </Router>
        
      )
    }
    
      
  }
}

export default App;
