
import React from 'react';
import {useSelector} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';


const PrivateRoute = ({component: Component, ...rest}) => {

    const logged_in = useSelector(state => state.userStatus.loggedIn)
    
    return (    
        <Route {...rest} render={props => (
            logged_in ?<Component {...props} {...rest} />
            : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;