const mapStateReducer = (state = {latitude: 41.2368, longitude: -8.6611, zoom: 11}, action) => {
    switch(action.type){
        case 'SET_MAP_STATE':
    
            return {...state, ...action.payload};

        default:
            return state;
    }
}

export default mapStateReducer;