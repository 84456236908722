const popupReducer = (state = {show: false, position:null, tipo:null}, action) => {
    switch(action.type){
        case 'OPEN_POPUP':
            return {
                show: true,
                position: action.payload.position,
                tipo: action.payload.tipo
            }
        case 'CLOSE_POPUP':
            return {
                show: false,
                position: null,
                tipo: null
            }
        default:
            return state;
    }
}

export default popupReducer