export default function mapFilterReducer(
    
    state = {
        tipos: {avistamentos: true, aninhamentos: true, ataques: true},
        datas: [null, null]

    },
    action){
        switch(action.type){
            case 'UPDATE_FILTER_TIPOS':
                return {...state, tipos: action.payload}
            case 'UPDATE_FILTER_DATAS':
                return {...state, datas: action.payload}
            case 'CLEAR_FILTERS':
                return {...state, tipos: {avistamentos: true, aninhamentos: true, ataques: true}, datas:[null, null]}
            default:
                return state
        }
    }