const sidebarReducer = (state = {show:true}, action) =>{
    switch(action.type){
        case 'SHOW_SIDEBAR':
            return {show:true}
        case 'HIDE_SIDEBAR':
            return {show: false}
        default:
            return {...state}
        
    }
}

export default sidebarReducer