export default function uploadImagem(
    state = {
        status: 'UPLOAD_IMAGE_WAITING',
        imagem: []
    },
    action){
        switch(action.type){
            case 'UPLOADING_IMAGE':
               return {...state, status:'UPLOADING_IMAGE'};
            case 'UPLOAD_IMAGE_SUCCESS':
                return {...state, status: 'UPLOAD_IMAGE_SUCCESS', imagem:[{url:action.payload}]};
            case 'UPLOAD_IMAGE_FAILED':
                return {...state, status: 'UPLOAD_IMAGE_FAILED'}
            case 'UPLOAD_IMAGE_CLEAN':
                return {...state, status: 'UPLOAD_IMAGE_WAITING', imagem:[]};
            default:
                return state

        }
    }
