import React from 'react';
import './mainListElement.css';
import { useDispatch } from 'react-redux'

import {useHistory} from 'react-router'

import PropTypes from 'prop-types';
import { hide_sidebar } from '../redux/actions';

function MainListElement(props){
    //Este elemento só representa o botão, mas é essencialmente dumb, o aspect (e.g. cor) e funcionalidade (link) vem com props
    const history=useHistory()
    const dispatch = useDispatch();

    const handleClick = () => {
        //se o botão for o de ver o mapa, que só aparece em mobile, escondemos a barra lateral, tornando o mapa visível. Se não, funciona como link.
        if(props.tipo !=='mapa'){
            history.push(props.link)
        }else{
            dispatch(hide_sidebar())
        }
    }
    return(
        <>
            <div className = 'main-list-element' style={{backgroundColor:props.cor}} onClick={handleClick}>
                
                    <span style={{display:'flex', justifyContent: 'space-around', width: '48px'}}>
                            <img src={props.imagem} style={{height:'48px', width: 'auto'}}/>
                    </span>
                    <span style={{display: 'inline-flex', alignItems: 'center', textAlign:'center', justifyContent:'center', fontSize: 15, color:'white', width:'75px'}}>
                        {props.elemento}
                    </span>
            
                
            </div>
        </>
    )
}

MainListElement.propTypes = {
    link: PropTypes.string,
    cor: PropTypes.string,
    elemento: PropTypes.string.isRequired,
    imagem: PropTypes.string.isRequired
}

export default MainListElement;