export const update_form_ninho_date = (date) => {
    return {
        type: 'UPDATE_FORM_NINHO_DATE',
        payload: date
    }
}

export const update_form_ninho_coords = (coords) => {
    return {
        type: 'UPDATE_FORM_NINHO_COORDS',
        payload: coords
    }
}

export const update_form_ninho_progenitores = (progen) => {
    return {
        type: 'UPDATE_FORM_NINHO_PROGENITORES',
        payload: progen
    }
}

export const update_form_ninho_ovos = (n_ovos) => {
    return {
        type: 'UPDATE_FORM_NINHO_OVOS',
        payload: n_ovos
    }
}

export const update_form_ninho_image = (imagem) => {
    return {
        type: 'UPDATE_FORM_NINHO_IMAGE',
        payload: imagem
    }
}

export const update_form_ninho_ruido = (ruido) => {
    return {
        type: 'UPDATE_FORM_NINHO_RUIDO',
        payload: ruido
    }
}

export const update_form_ninho_local = (local) => {
    return {
        type: 'UPDATE_FORM_NINHO_LOCAL',
        payload: local
    }
}

export const update_form_ninho_inferido = (inferido) => {
    return {
        type: 'UPDATE_FORM_NINHO_iNFERIDO',
        payload: inferido
    }
}

export const update_form_ninho_detalhes = (detalhes) => {
    return {
        type: 'UPDATE_FORM_NINHO_DETALHES',
        payload: detalhes
    }
}

export const update_form_ninho_concelho = (concelho) => {
    return {
        type: 'UPDATE_FORM_NINHO_CONCELHO',
        payload: concelho
    }
}

export const clean_form_aninhamento = () => {
    return {
        type: 'CLEAR_ANINHAMENTO_FORM'
    }
}
