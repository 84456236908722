import {timeoutPromise} from '../../helpers/timeouters';
import { fetchAninhamentos } from './aninhamentosActions';
import { fetchAvistamentos } from './avistamentosActions';
import { fetchAtaques } from './ataquesActions';
import { bindActionCreators } from 'redux';

export const login_start = () => {
  return {
    type: 'LOGIN_START',
  };
};

export const login_success = (userData) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: userData,
  };
};

export const login_failure = (error) => {
  return {
    type: 'LOGIN_FAILURE',
    payload: error
  };
};

export const login = (userData) => {
  return async (dispatch) => {
    dispatch(login_start());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: `email=${userData.email}&password=${userData.password}`,
      });
      if (response.ok) {
        const data = await response.json();
       
        const dataSave = {
          email: userData.email,
          id: data.utilizador,
          avatar: data.avatar,
          role: data.role,
        }
        localStorage.setItem("user_data", JSON.stringify(dataSave))
        localStorage.setItem("token", data.token)
        dispatch(login_success(dataSave));
        dispatch(fetchAvistamentos())
        dispatch(fetchAninhamentos())
        dispatch(fetchAtaques())
      } else {
        const text = await response.text()
        
        if(response.status === 403 || response.status===429){
          throw text;
        }else{
          throw 'Ocorreu um erro. Verifique as credenciais, e tente novamente.'
        }
        
      }


        } catch (error){
          
          dispatch(login_failure(typeof error === "string"? error : 'Ocorreu um erro.'));
    }
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
  };
}

export const request_logout = () => {
  return async(dispatch) => {
    try{
      localStorage.removeItem("user_data")
      localStorage.removeItem("token")
    }catch{
      console.log('no logout')
    }finally{
      
        dispatch(logout())
        dispatch(fetchAninhamentos())
        dispatch(fetchAvistamentos())
        dispatch(fetchAtaques())
      
      
      
    }
  }
}

export const register_start = () => {
  return {
    type: 'REGISTER_START',
  };
};

export const register_success = () => {
  return {
    type: 'REGISTER_SUCCESS'
    
  };
};

export const register_failure = (error) => {
  return {
    type: 'REGISTER_FAILURE',
    payload: error
  };
};

export const clear_login_forms = () => {
  return {
    type: 'CLEAR_LOGIN_FORMS'
  }
}

export const register = (userData) => {
  return async (dispatch) => {
    dispatch(register_start());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/registo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: `email=${userData.email}&username=${userData.username}&password=${userData.password}&avatar=0`,
      });
      var resp = await response.text()
  
      
      if (response.ok && resp==='Registo realizado com sucesso!') {
        dispatch(register_success());
        
       
      } else {
        
        
        if(response.status===409 || response.status===429){
          throw resp;
        }else{
          throw 'Ocorreu um erro. Por favor tente novamente.'
        }
        
      }


        } catch (error){
       
          dispatch(register_failure(error));
    }
  };
};

export const reset_start = () => {
  return {
    type: 'RESET_START',
  };
};

export const reset_success = () => {
  return {
    type: 'RESET_SUCCESS'
    
  };
};

export const reset_failure = (error) => {
  return {
    type: 'RESET_FAILURE',
    payload: error
  };
};

export const reset = (email) => {
  return async (dispatch) => {
    dispatch(reset_start());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/esquecimento`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: `email=${email}`,
      });
      var resp = await response.text()

      
      if (response.ok && resp==='Email de reposição enviado.') {
        dispatch(reset_success());
        
       
      } else {
        if(response.status===429){
          throw resp;
        }else{
          throw 'Ocorreu um erro. Por favor tente novamente.'
        }
      }


        } catch (error){
    
          dispatch(reset_failure(error));
    }
  };
};

export const submit_reset_password_start = () => {
  return {
    type: 'SUBMIT_RESET_PASSWORD_START',
  };
};

export const submit_reset_password_success = () => {
  return {
    type: 'SUBMIT_RESET_PASSWORD_SUCCESS'
    
  };
};

export const submit_reset_password_failure = (error) => {
  return {
    type: 'SUBMIT_RESET_PASSWORD_FAILURE',
    payload: error
    
  };
};

export const submit_reset_password = (id, token, password) => {
  return async (dispatch) => {
    dispatch(submit_reset_password_start());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/reposicao`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: `id=${id}&token=${token}&password=${password}`,
      });
      var resp = await response.text()
      
      
      if (response.ok && resp==='Password reposta com sucesso.') {
        dispatch(submit_reset_password_success());
        
       
      } else {
        throw "Ocorreu um erro. Por favor tente novamente."

      }

      } catch (error){
  
          dispatch(submit_reset_password_failure(error));
    }
  };
};

export const change_password_start = () => {
  return {
    type: 'CHANGE_PASSWORD_START',
  };
};

export const change_password_success = () => {
  return {
    type: 'CHANGE_PASSWORD_SUCCESS'
    
  };
};

export const change_password_failure = () => {
  return {
    type: 'CHANGE_PASSWORD_FAILURE',
  };
};

export const change_password_clean = () => {
  return{
    type: 'CHANGE_PASSWORD_CLEAN'
  }
}

export const change_password = (id, passwordAtual, passwordNova) => {

  return async (dispatch) => {
    dispatch(change_password_start());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/nova-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: `id=${id}&passwordAtual=${passwordAtual}&passwordNova=${passwordNova}`,
      });
      var resp = await response.text()
      
      console.log(resp)
      if (response.ok && resp==='Password atualizada.') {
        dispatch(change_password_success());
        
       
      } else {
        throw response.status;
      }


        } catch (error){

          dispatch(change_password_failure());
    }
  };
};

export const edit_user_self_start = () => {
  return {
    type: 'EDIT_USER_SELF_START',
  };
};

export const edit_user_self_success = (edited) => {
  return {
    type: 'EDIT_USER_SELF_SUCCESS',
    payload: edited
    
  };
};

export const edit_user_self_failure = () => {
  return {
    type: 'EDIT_USER_SELF_FAILURE',
  };
};

export const edit_user_self_clean = () => {
  return {
    type: 'EDIT_USER_SELF_CLEAN',
  };
};

export const edit_user_self = (id, edited) => {
  
  return async dispatch => {
    dispatch(edit_user_self_start());
    try{
        
        const token = localStorage.getItem("token");
        const submission = await timeoutPromise(8000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizador/${id}`,{
                method:'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(edited)
            })
            );
        var resp = await submission.json()
        
        if(submission.ok){
          const userDataLocal = JSON.parse(localStorage.getItem("user_data"))
          const editedData = {...userDataLocal, ...resp}
          localStorage.setItem("user_data", JSON.stringify(editedData))
          dispatch(edit_user_self_success(resp))
        }else{
            throw submission.status
        }
        

    } catch (error){
        dispatch(edit_user_self_failure(error))
  
    } 
}
};

export const delete_user_self_confirm = () => {
  return {
    type: 'DELETE_USER_SELF_CONFIRM',
  };
};

export const delete_user_self_start = () => {
  return {
    type: 'DELETE_USER_SELF_START',
  };
};

export const delete_user_self_success = (edited) => {
  return {
    type: 'DELETE_USER_SELF_SUCCESS'
    
  };
};

export const delete_user_self_failure = () => {
  return {
    type: 'DELETE_USER_SELF_FAILURE',
  };
};

export const delete_user_self_clean = () => {
  return {
    type: 'DELETE_USER_SELF_CLEAN',
  };
};

export const delete_user_self = () => {
  
  return async dispatch => {
    dispatch(delete_user_self_start());
    try{
        
        const token = localStorage.getItem("token");
        const submission = await timeoutPromise(8000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/utilizador/`,{
                method:'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            );
      
       
        
        if(submission.ok){
          
          dispatch(delete_user_self_success());
          dispatch(request_logout());
        }else{
            throw submission.status
        }
        

    } catch (error){
        dispatch(delete_user_self_failure(error))
  
    } 
}
};