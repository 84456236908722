import {timeoutPromise} from '../../helpers/timeouters';
import { fetchAninhamentos } from './aninhamentosActions';
import { fetchAvistamentos } from './avistamentosActions';
import { fetchAtaques } from './ataquesActions';

export const EDIT_OBSERVATION = 'EDIT_OBSERVATION';
export const EDIT_OBSERVATION_FIELD = 'EDIT_OBSERVATION_FIELD';
export const DELETE_OBSERVATION_START = 'DELETE_OBSERVATION_START'
export const DELETE_OBSERVATION_CONFIRM = 'DELETE_OBSERVATION_CONFIRM'
export const DELETE_OBSERVATION_SUCCESS = 'DELETE_OBSERVATION_SUCCESS'
export const DELETE_OBSERVATION_FAILED = 'DELETE_OBSERVATION_FAILED'
export const DELETE_OBSERVATION_CLEAN = 'DELETE_OBSERVATION_CLEAN'
export const VALIDATE_OBSERVATION_START = 'VALIDATE_OBSERVATION_START'
export const VALIDATE_OBSERVATION_SUCCESS = 'VALIDATE_OBSERVATION_SUCCESS'
export const VALIDATE_OBSERVATION_FAILED = 'VALIDATE_OBSERVATION_FAILED'
export const VALIDATE_OBSERVATION_CLEAN = 'VALIDATE_OBSERVATION_CLEAN'
export const VALIDATE_OBSERVATION_CHECK = 'VALIDATE_OBSERVATION_CHECK'

export const edit_observation = (obs) => ({
  type: EDIT_OBSERVATION,
  payload: obs,
});

export const edit_observation_field = (field) => ({
  type: EDIT_OBSERVATION_FIELD,
  payload: field,
});

export const delete_observation_start = () => {
  return {
      type:DELETE_OBSERVATION_START
  }
}

export const delete_observation_confirm = (id, tipo) => {
  return {
      type:DELETE_OBSERVATION_CONFIRM,
      payload:{id:id, tipo:tipo}
  }
}

export const delete_observation_success = () => {
  return {
      type:DELETE_OBSERVATION_SUCCESS
  }
}

export const delete_observation_failure = () => {
  return {
      type: DELETE_OBSERVATION_FAILED
  }
}

export const delete_observation_clean = () => {
  return {
      type: DELETE_OBSERVATION_CLEAN
  }
}

export const delete_observation = (observationID, target) => {
  
  
  const token = localStorage.getItem("token");
          
  return async dispatch => {
      dispatch(delete_observation_start());
      try{
          
          const deletion = await timeoutPromise(8000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/${target}/${observationID}`,{
                  method:'DELETE',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  }
              })
              );
          
          if(deletion.ok){
              dispatch(delete_observation_success())
              dispatch(fetchAvistamentos())
              dispatch(fetchAninhamentos())
              dispatch(fetchAtaques())
          }else{
              throw deletion.status
          }
          

      } catch (error){
          dispatch(delete_observation_failure())
       
      } 
  }
}

export const validate_observation_start = () => {
  return {
      type:VALIDATE_OBSERVATION_START
  }
}

export const validate_observation_check = (id, tipo) => {
  return {
      type:VALIDATE_OBSERVATION_CHECK,
      payload:{id:id, tipo:tipo}
  }
}

export const validate_observation_success = () => {
  return {
      type:VALIDATE_OBSERVATION_SUCCESS
  }
}

export const validate_observation_failure = () => {
  return {
      type: VALIDATE_OBSERVATION_FAILED
  }
}

export const validate_observation_clean = () => {
  return {
      type:VALIDATE_OBSERVATION_CLEAN
  }
}

export const validate_observation = (validationData, target) => {
 
  
  var data = {...validationData.properties, lat:validationData.geometry.coordinates[1], long:validationData.geometry.coordinates[0], validado:true}
  delete data.imagens
  delete data.id_user
  
  return async dispatch => {
      dispatch(validate_observation_start());
      try{
        const token = localStorage.getItem("token");
          const validation = await timeoutPromise(8000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/${target}/${validationData._id}`,{
                  method:'PUT',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify(data)
              })
              );
          
          if(validation.ok){
              dispatch(validate_observation_success())
              dispatch(fetchAvistamentos())
              dispatch(fetchAninhamentos())
              dispatch(fetchAtaques())
          }else{
              throw validation.status
          }
          

      } catch (error){
          dispatch(validate_observation_failure())
        
      } 
  }
}