
import {timeoutPromise} from '../../helpers/timeouters';

export const FETCH_VALIDATE_BEGIN = 'FETCH_VALIDATE';
export const FETCH_VALIDATE_SUCCESS = 'FETCH_VALIDATE_SUCCESS';
export const FETCH_VALIDATE_FAILURE = 'FETCH_VALIDATE_FAILURE';
export const FETCH_VALIDATE_EXTRA_BEGIN = 'FETCH_VALIDATE_EXTRA_BEGIN';
export const FETCH_VALIDATE_EXTRA_SUCCESS = 'FETCH_VALIDATE_EXTRA_SUCCESS';
export const FETCH_VALIDATE_EXTRA_FAILURE = 'FETCH_VALIDATE_EXTRA_FAILURE'
export const VALIDATE_OBSERVATION = 'VALIDATE_OBSERVATION';
export const EDIT_FIELD = 'EDIT_FIELD';
export const CLEAR_AVISTAMENTOS_VALIDATE = 'CLEAR_AVISTAMENTOS_VALIDATE';
export const FILTRAR_CONCELHO = 'FILTRAR_CONCELHO'

export const fetch_validate_begin = () => {
  return {
    type: FETCH_VALIDATE_BEGIN,
  };
};
 
export const fetch_validate_success = (avistamentos) => ({
  type: FETCH_VALIDATE_SUCCESS,
  payload: {avistamentos},
});

export const fetch_validate_failure = (error) => ({
  type: FETCH_VALIDATE_FAILURE,
  payload: {error},
});

export const fetch_validate_extra_begin = () => {
  return {
      type:FETCH_VALIDATE_EXTRA_BEGIN
  }
}

export const fetch_validate_extra_success = avistamentos => ({
  type: FETCH_VALIDATE_EXTRA_SUCCESS,
  payload: {avistamentos}
});

export const fetch_validate_extra_failure = error => ({
  type: FETCH_VALIDATE_EXTRA_FAILURE,
  payload: {error}
});

export const clear_avistamentos_validate = () => {
  return {
      type:CLEAR_AVISTAMENTOS_VALIDATE
  }
}


export const edit_field = field => ({
  type: EDIT_FIELD,
  payload: {field}
});

export const fetch_validate = (concelho) => {
  return (dispatch) => {
    dispatch(fetch_validate_begin());

    return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/naovalidados?q=10&concelho=${concelho}`)
      .then((response) => response.json())
      .then((json) => {
        dispatch(fetch_validate_success(json));
      })
      .catch((error) => {
        dispatch(fetch_validate_failure(error));
      });
  };
};

export const fetch_avistamentos_extra_validate = (timestamp, concelho) => {
  return dispatch =>{
      dispatch(fetch_validate_extra_begin());
    
      
      return timeoutPromise(6000,fetch(`${process.env.REACT_APP_API_ADDRESS}/api/naovalidados?q=10&&timestampav=${timestamp.avistamento.toISOString()}&timestampan=${timestamp.aninhamento.toISOString()}&timestampat=${timestamp.ataque.toISOString()}&concelho=${concelho}`))
      .then(response => response.json())
      .then(json => {
          dispatch(
              fetch_validate_extra_success(json)
              )
      })
      .catch((error) => {
          dispatch(fetch_validate_extra_failure(error))
      })
  }
}

export const filtrar_concelho =  concelho => {
  return {
    type: FILTRAR_CONCELHO,
    payload: concelho
  }
}