import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    update_form_ataque_date,
    update_form_ataque_alvo,
    update_form_ataque_descricao, 
    submit,
    clean_form_ataque,
    upload_image_clean } from '../redux/actions';
import DateTimePicker from 'react-datetime-picker'

function FormAtaque(props){
    //componente do formulário de criação de observações, para reportar ninhos
    const dispatch = useDispatch();
    const formData = useSelector(state => state.novoAtaque); //estado atual do formulário no state geral


    useEffect(() => {
        //quando o componente monta, atualizamos a data do avistamento para a data atual
        const currentDate = new Date();
        dispatch(update_form_ataque_date(currentDate)); 

        return(() => {
            //limpa formulário, submissão e imagem carregada
            dispatch(clean_form_ataque())
           
            dispatch(upload_image_clean())
        }
        )
    }, [])

    
    const handleChange = e => {
        //quando um campo do formulário é alterado, atualizar no sítio respetivo do state geral da app
        switch(e.target.name){
            case 'data':
                dispatch(update_form_ataque_date(e.target.value))
                break
            case 'alvo':
                dispatch(update_form_ataque_alvo(e.target.value))
                break
            case 'descricao':
                dispatch(update_form_ataque_descricao(e.target.value))
                break
            default:
                break

        }
     
    }

    return(
        <div className='form-group'>
            <div className='form-group-element'>
                <div className='form-element'>
                    <label htmlFor='data' style={{display:'block'}}>Data e hora do avistamento</label>
                    <DateTimePicker
                        name='data'
                        id='data'
                        onChange={(v) => handleChange({target:{name:'data', value:v}})}
                        value={formData.data}
                        disableClock={true}
                        maxDate={new Date()}
                        clearIcon={null}
                        style={{width:'100%'}}
                    />
                </div>
                <div className='form-element'>
                    <p style={{display:'block', marginBottom:0}}>Alvo</p>
                    <ul style={{width:'100%', listStyleType:'none'}}>

                        <li>
                            <input
                                key='Adulto'
                                type='checkbox' 
                                name='alvo'
                                value='Adulto'
                                checked={formData.alvo.Adulto}
                                onChange={handleChange}
                            />
                                Adulto
                        </li>
                        <li>
                            <input
                                key='Criança'
                                type='checkbox'
                                name='alvo'
                                value='Criança'
                                checked={formData.alvo.Criança}
                                onChange={handleChange}
                            />
                                Criança
                            </li>
                        <li>
                            <input
                                key='AEstimação'
                                type='checkbox'
                                name='alvo'
                                value='AEstimação'
                                checked={formData.alvo.AEstimação}
                                onChange={handleChange}
                            />
                                Animal de estimação
                            </li>
                        <li>
                            <input
                                key='ASelvagem'
                                type='checkbox'
                                name='alvo'
                                value='ASelvagem'
                                checked={formData.alvo.ASelvagem}
                                onChange={handleChange}
                            />
                                Animal selvagem
                        </li>
                        <li>
                            <input
                                key='Objeto'
                                type='checkbox'
                                name='alvo'
                                value='Objeto'
                                checked={formData.alvo.Objeto}
                                onChange={handleChange}
                            />
                                Objeto
                            </li>
                    </ul>
                </div>
            </div>
            <div className='form-group-element'>
                <div className='form-element'>
                    <label htmlFor='descricao' style={{display:'block'}}>Descrição</label>
                    <textarea
                        type='text'
                        name='descricao'
                        id='descricao'
                        rows={'5'}
                        cols={'40'}
                        colvalue={formData.descricao}
                        onChange={handleChange}
                        style={{width:'100%', boxSizing:'border-box' }}
                    ></textarea>
                </div>
            </div>
            
        </div>
    )
}

export default FormAtaque;