import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import EditAvistamento from './EditAvistamento';
import EditAninhamento from './EditAninhamento';
import EditAtaque from './EditAtaque';
import {useHistory, useLocation} from 'react-router';
import './EditModal.css';
import { validate_observation, delete_observation_confirm} from '../redux/actions';

function EditModal(props){
    //modal de edição de observações. Os dados comuns a todos os tipos de observação são editados com inputs neste componente,
    //os dados específicos de cada tipo num child component específico para cada tipo 
    const validatingState = useSelector(state => state.editObservation.validatingObservationStatus); //estado de validação (comunicação com backend)
    const editingObs = useSelector(state => state.editObservation.editingObservation); //dados da observação a ser editada
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation(); //página atual na história do browser
    const [observation, setObservation] = useState(null); //observação que está a ser editada, internamente

    const observation_redux = useSelector(
        (state) => state.validate.avistamentos,
      );  //lista de observações não validadas

    useEffect(() => {
        //de entre as observações não validadas, se alguma, filtramos aquela que corresponde ao id da observação que queremos editar,
        //convertemos o formato da data e guardamos internamente no state do componente
        
        if(observation_redux.length>0){
            const observation_val = observation_redux.filter((obs) => obs._id === editingObs.id)[0];
            const observation_set = {...observation_val,
                properties:{...observation_val.properties,
                    data: new Date(observation_val.properties.data)
                }
            }
              setObservation(observation_set)
        }

    }, [observation_redux])

      const handleChange = (e) =>{
        //edita a observação atual quando um campo é alterado no formulário do componente, ou num dos sub-componentes

        let edited_observation;
        if(e.target.name==='alvo'){
            //caso o campo a ser editado seja o alvo (só se aplica no sub-componente dos ataques), que é uma checklist, fazemos toggle
            //do estado (true/false, i.e. checked/unchecked)
            edited_observation = {...observation,
                properties: {...observation.properties, alvo:{
                    ...observation.properties.alvo, [e.target.value]: !observation.properties.alvo[e.target.value]
                }
            }
            }
        }else{
            //nos restantes casos, simplesmente alteramos o valor respetivo com o novo input
            edited_observation = {...observation,
                properties: {...observation.properties, [e.target.name]: e.target.value}
            }
        }
        
        setObservation(edited_observation); //alteramos a observação no state local
    }

    const handleDeleteRequest = () => {
        //para lidar com um pedido de delete da observação, queremos primeiro confirmar
        dispatch(delete_observation_confirm(editingObs.id, editingObs.tipo)); //passamos os dados da observação a eliminar para o state geral
        const current = location.pathname; //obtemos o URL da página de edição da observação
        history.push(`${current}/confirmar`); //navegamos para a respetiva página (modal) de confirmação, com base na página de edição
    }

    return(
        <div className='editing-modal'>
            {validatingState==='CHECKING' && observation!==null?
            //se estivermos a validar, e houver alguma observação carregada para validar
                <>
                    <div className='editing-imagem'><img src={`/imagens/${observation.properties.imagens[0].url}`} style={{width:'100%', height:'100%', objectFit:'contain'}}/></div>
                    
                    <div className='scrollbar-custom' id='editing-form'>
                        <div style={{flexDirection: 'row'}}>
                            <span style={{fontWeight:'bold'}}>Data do avistamento: </span>
                            <span>{observation.properties.data.toLocaleDateString("pt-PT")}</span>
                        </div>
                        <div style={{flexDirection: 'row'}}>
                            <span style={{fontWeight:'bold'}}>Hora do avistamento: </span>
                            <span>{observation.properties.data.toLocaleTimeString("pt-PT", {hour:'2-digit', minute:'2-digit'})}</span>
                        </div>
                        <div style={{flexDirection: 'row'}}>
                            <span style={{fontWeight:'bold'}}>Concelho: </span>
                            <span>{observation.properties.concelho}</span>
                        </div>
                        <div style={{flexDirection: 'row'}}>
                            <span style={{fontWeight:'bold'}}>Utilizador: </span>
                            <span>{observation.properties.id_user.username}</span>
                        </div>
                        {editingObs.tipo === 'avistamento'  && editingObs.id ? (
                            //para editar os campos específicos dos avistamentos
                            <EditAvistamento observation={observation} handleChange={handleChange}/>
                        ): null}
                        {editingObs.tipo === 'aninhamento'  && editingObs.id ? (
                            //para editar os campos específicos dos ninhos
                            <EditAninhamento observation={observation} handleChange={handleChange}/>
                        ): null}
                        {editingObs.tipo === 'ataque'  && editingObs.id ? (
                            //para editar os campos específicos dos ataques
                            <EditAtaque observation={observation} handleChange={handleChange}/>
                        ): null}
                    </div>
                    <div className='editing-buttons'>
                        <div
                        //eliminar observação (abrirá modal de confirmação)
                            onClick={handleDeleteRequest} style={{color:'red', cursor: 'pointer'}}
                        >
                            Eliminar
                        </div>
                        <div
                        //validar observação com as edições feitas, se alguma
                            onClick={() => dispatch(validate_observation(observation, editingObs.tipo))}
                            style={{color:'green', cursor: 'pointer'}}>
                                Validar
                        </div>
                        
                    </div>
                    <div className='editing-cancel' onClick={() => history.goBack()} >Cancelar</div>
                    
                </>:null}
                {//informações ao utilizador, conforme estado do processo de validação no backend:
                }
            {validatingState==='VALIDATING' ? <div>A validar...</div>:null}
            {validatingState==='VALIDATED' ? <div>Observação validada</div>:null}
            {validatingState==='FAILED' ? <div>Ocorreu um erro. Por favor tente novamente.</div>:null}
        </div>
    )
}

export default EditModal;


