import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import ObservationCard from './ObservationCard';
import './ListaAvistamentos.css';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {fetch_avistamentos_user, fetch_avistamentos_extra_user, clear_avistamentos_user, fetch_validate, clear_avistamentos_validate, fetch_avistamentos_extra_validate} from '../redux/actions';



function ListaAvistamentos(props){
    //lista de observações, é usada, dependendo dos props, na página de utilizador para mostrar os seus avistamentos,
    //na página de validação para mostrar os avistamentos não validados, e na página de admin para mostrar os 
    //avistamentos de um dado utilizador.
    const dispatch = useDispatch();

    const [observations, setObservations] = useState([]); //observações a mostrar, depois de obtidas do backend através do state geral da app e de processadas
    
    //das duas seguintes, só vai ser usada uma, conforme seja a página de utilizador ou de validação. Como o useSelector não pode ser condicional, carregamos ambos. 
    const avistamentosUser = useSelector(state => state.avistamentosUser);
    const avistamentosValidate = useSelector(state => state.validate);
    
    const userData = useSelector(state => state.userStatus.profile); //os dados do utilizador, para obter o seu id e requisitar as suas observações
    const userDataAdmin = useSelector(state => state.usersList.editingUser); //os dados do utilizador que está a ser visualizado por um admin, para obter o seu id e requisitar as suas observações
    const deletingState = useSelector(state => state.editObservation.deletingObservationStatus); //quando uma observação está a ser apagada no backend, estado do processo 
    const editingState = useSelector(state => state.editObservation.validatingObservationStatus); //quando uma observação está a ser validada no backend, estado do processo
    
    useEffect(() => {
        //carregamos os avistamentos a mostrar, que podem ser do utilizador atual, do utilizador que um admin
        //está a visualizar, ou os avistamentos a validar. Uma vez que queremos atualizar a lista quando um avistamento é apagado ou editado,
        //observamos os respetivos estados
        switch(props.tipo){
            case 'utilizador':
                if(deletingState==='WAITING'){
                    //caso o utilizador esteja a ver a sua própria página. Uma vez que estamos a observar o estado de 
                    //"apagamento" das observações, mas só queremos que a lista atualize a meio do processo,
                    //verificamos se o estado é 'WAITING'
                    dispatch(fetch_avistamentos_user(userData.id))
                }
                break
            case 'administrador':
                if(deletingState==='WAITING'){
                    //idem
                    dispatch(fetch_avistamentos_user(userDataAdmin._id))
                }
                break
            case 'validar':
                if(editingState==='WAITING'){
                    //idem, para o estado de edição
                    dispatch(fetch_validate(avistamentosValidate.filtroConcelho))
                }
                break
        }
        
    }, [deletingState, editingState, avistamentosValidate.filtroConcelho])

    useEffect(() => {
        //construimos uma lista de observações num formato adequado para utilizar, a partir dos avistamentos vindos do backend
        //queremos fazer isso ao carregar a lista com as observações iniciais, e posteriormente com avistamentos novos quando chegarmos ao fim da lista
        //e pedirmos mais
        if(props.tipo ==='utilizador' || props.tipo ==='administrador' && avistamentosUser.mainStatus === 'LOADED' || avistamentosUser.extraStatus === 'LOADED'){
            //no caso de uma lista de avistamentos de um utilizador (i.e. não para validar), e quando os avistamentos estiverem carregados
            const avistamentosDisp = avistamentosUser.avistamentos.map(avistamento => {
                //as observações vindas do backend vêm em bloco, sem indicar explicitamente de que tipo são
                //por isso, para termos uma indicação dos respetivos tipos, usamos um campo que seja exclusido de cada tipo,
                //se a observação incluir o campo x, é do tipo y
                let tipo='';
                let keys = Object.keys(avistamento.properties);
                if(keys.includes('n_ovos')){
                    tipo='aninhamento'
                } else if(keys.includes('especie')){
                    tipo='avistamento'
                } else if(keys.includes('alvo')){
                    tipo='ataque'
                }

                return {
                    data: new Date(avistamento.properties.data),
                    id:avistamento._id,
                    tipo: tipo,
                    properties: avistamento.properties,
                    coordinates:avistamento.geometry.coordinates
                }
          }
            )

            setObservations(avistamentosDisp)
        }
    }, [avistamentosUser.mainStatus, avistamentosUser.extraStatus])

    useEffect(() => {
        //em tudo semelhante ao anterior, mas escuta as observações a validar
        if(props.tipo ==='validar' && avistamentosValidate.mainStatus === 'LOADED' || avistamentosValidate.extraStatus === 'LOADED'){
          
            const avistamentosDisp = avistamentosValidate.avistamentos.map(avistamento => {
                
                let tipo='';
                let keys = Object.keys(avistamento.properties)
                if(keys.includes('n_ovos')){
                    tipo='aninhamento'
                } else if(keys.includes('especie')){
                    tipo='avistamento'
                } else if(keys.includes('alvo')){
                    tipo='ataque'
                }

                return {
                    data: new Date(avistamento.properties.data),
                    id:avistamento._id,
                    tipo: tipo,
                    properties: avistamento.properties,
                    coordinates:avistamento.geometry.coordinates
                }
          }
            )
            setObservations(avistamentosDisp)
        }
    }, [avistamentosValidate.mainStatus, avistamentosValidate.extraStatus])

    useEffect(() => {
        //apenas responsável por limpar as observações do state geral quando o componente desmontar
        if(props.tipo==='utilizador' || props.tipo==='administrador'){
            return () => dispatch(clear_avistamentos_user());
        }else if(props.tipo==='validar'){
            return () => dispatch(clear_avistamentos_validate());
        }else{
            return null
        }
        

    }, []);

    const scrollRef = useBottomScrollListener(handleBottomReach, {debounce:1000}); //deteta a chegada ao fim da lista, para pedir mais observações

    function handleBottomReach(){
        //chamada quando chegarmos ao fim da lista, para pedir mais observações
        const date = new Date(); 
        let last_dates = {avistamento: date, aninhamento:date, ataque:date}
        observations.forEach(obs => {last_dates[obs.tipo] = obs.data})

        switch(props.tipo){

            case 'utilizador':
                
                if(props.n_obs > observations.length && avistamentosUser.extraStatus !== 'LOADING'){
                    dispatch(fetch_avistamentos_extra_user(userData.id, last_dates))
                }
                
                break 
            case 'administrador':
            
                if(15 > observations.length && avistamentosUser.extraStatus !== 'LOADING'){
                    dispatch(fetch_avistamentos_extra_user(userData.id, last_dates))
                }
                
                break 
            case 'validar':
                if(avistamentosValidate.total > observations.length && avistamentosValidate.extraStatus !== 'LOADING'){
                    dispatch(fetch_avistamentos_extra_validate(last_dates, avistamentosValidate.filtroConcelho))
                }
                break
            default:
                break

        }
       

        
    }
    

    const userObs = observations.map((marker) =>(
        <ObservationCard
            key={marker.id}
            id={marker.id}
            coords={marker.coordinates}
            tipo={marker.tipo}
            properties={marker.properties}
            card_type = {props.tipo}
        />
    )
);

    return(
        <div className={props.tipo==='validar'?'lista-avistamentos-validate':'lista-avistamentos'} ref={scrollRef}>
            <div className='observationsGrid' >
                {userObs}
            </div>
      
        </div>
    )
}


ListaAvistamentos.propTypes = {
    observations: PropTypes.array,
    edit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired
}

export default ListaAvistamentos;