import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { login, request_logout, register, clear_login_forms, reset } from '../redux/actions';
import { Link } from 'react-router-dom';
import './LoginPage.css';

function LoginPage(){
    const dispatch = useDispatch();
    const [formDataLogin, setFormDataLogin] = useState({email: '', password: ''})
    const [formDataRegister, setFormDataRegister] = useState({email: '', password: '', username: ''})
    const [requestingReset, setRequestingReset] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')
    const logged_in = useSelector(state => state.userStatus.loggedIn)
    const loginStatus = useSelector(state => state.userStatus.loginStatus)
    const [passChecks, setPassChecks] = useState(false);
    const [visible, setVisible] = useState('login');
    const loginErrorMessage = useSelector(state => state.userStatus.loginError)
    const rex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$')
    

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        dispatch(login(formDataLogin));
    }

    const handleSubmitRegister = (e) => {
        e.preventDefault();
        
        
        if(rex.test(formDataRegister.password)){
            dispatch(register(formDataRegister));
        }
        
    }

    
    useEffect(() => {
  
        if(rex.test(formDataRegister.password)){
            setPassChecks(true)
        }else{
            setPassChecks(false)
        }
    }, [formDataRegister.password])

    const handleSubmitReset = (e) => {
        e.preventDefault();
        dispatch(reset(resetEmail));
    }

    const handleChangeLogin = (e) => {
    
        setFormDataLogin({...formDataLogin, [e.target.name]:e.target.value})
    }

    const handleChangeRegister = (e) => {
    
        setFormDataRegister({...formDataRegister, [e.target.name]:e.target.value})
    }

    useEffect(() => {
        if(loginStatus==='registration_success' || loginStatus==='logged_in'){
            setFormDataRegister({email: '', password: '', username: ''})
            setPasswordCheck('')
            setFormDataLogin({email: '', password: ''})
            
        }
        return () => {

            if(loginStatus === 'login_failed' || loginStatus ==='registration_failed' || loginStatus ==='registration_success' || loginStatus ==='reset_failed' || loginStatus ==='reset_success'){

                dispatch(clear_login_forms())
            }
        
        }
    }, [loginStatus])

    if(!logged_in){
        if(requestingReset){
            return(
                <div className='esqueci'>
                    {loginStatus==='reset_failed'?<div className="error-text" style={{marginTop: '20px'}}>{loginErrorMessage}</div>:null}
                    {loginStatus==='reset_success'?<div style={{marginTop: '20px'}}>A recuperação da palavra passe foi iniciada. Verifique o seu email.</div>:<form onSubmit={handleSubmitReset} >
                        <div className = 'form-element'>
                            <label htmlFor="email_reset" style={{display:'block', marginTop: '20px'}}>Introduza o seu endereço de email:</label>
                            <input type="email" id="email_reset" name="email_reset" style={{width:'100%', boxSizing: 'border-box'}}  onChange={e => setResetEmail(e.target.value)} value = {resetEmail} required></input>
                            
                        </div>
                        
                        <div style={{textAlign:'center'}}>
                            <button className='custom-button' type='button' onClick ={() => setRequestingReset(false)}>Cancelar</button>
                            <button className='custom-button' type='submit' display='block'>{loginStatus==='resetting'?'A submeter...':'Submeter'}</button>
                        </div>
                    </form>}
                </div>
            )
        }else {
            return(
                <div className='login-page'>
                    
                    {window.innerWidth >810?<h1 onClick={() => setVisible('login')} className='entrar-tab' style={{backgroundColor:visible==='login'?'#0dafd0ff':'#0dafd050', color:visible==='login'?'white':'grey'}}>Entrar</h1>:null}
                    {window.innerWidth <=810?<h1 onClick={() => setVisible('login')} className='entrar-tab' >Entrar</h1>:null}
                    {window.innerWidth >810?<h1 onClick={() => setVisible('register')} className='registar-tab' style={{backgroundColor:visible==='register'?'#0dafd0ff':'#0dafd050', color:visible==='register'?'white':'grey'}}>Registar</h1>:null}
                    <form onSubmit={handleSubmitLogin} className='entrar'>
                        
                        {visible==='login'?<div style={{marginTop:'25px'}}>
                        {loginStatus==='login_failed' ?<div className="error-text" style={{marginBottom:'5px'}}>{loginErrorMessage}</div>:null}
                        <div className = 'form-element'>
                            <label htmlFor="email_login" style={{display:'block'}}>Email:</label>
                            <input type="email" id="email_login" name="email" onChange={handleChangeLogin} value = {formDataLogin.email} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        <div className = 'form-element'>
                            <label htmlFor="password_login" style={{display:'block'}}>Password:</label>
                            <input type="password" id="password_login" name="password" onChange={handleChangeLogin} value = {formDataLogin.password} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        <div onClick ={() => setRequestingReset(true)} style={{color:'blue', cursor:'pointer'}}>Esqueci-me da password</div>
                        <div style={{textAlign:'center'}}>
                            <button className='custom-button' type='submit'>{loginStatus==='logging_in'?'A entrar...':'Entrar'}</button>
                        </div>
                        </div>:null}
                    </form>
                    {window.innerWidth <=810?<h1 onClick={() => setVisible('register')} className='registar-tab' style={{borderBottom:visible==='register'?null:'1px solid black'}}>Registar</h1>:null}
                    <form onSubmit={handleSubmitRegister} className='registar' style={{display:visible==='login'?'none':'block'}}>  
                        
                        {visible==='register'?<div style={{marginTop:'25px'}}>
                        {loginStatus==='registration_failed'?<div className="error-text" style={{ marginBottom:'5px'}}>{loginErrorMessage}</div>:null}
                        {loginStatus==='registration_success'?<div className="success-text" style={{marginBottom:'5px'}}>Registo bem sucedido. Por favor verifique o seu email.</div>:null}
                        <div className = 'form-element'>
                            <label htmlFor="email_register" style={{display:'block'}}>Email:</label>
                            <input type="email" id="email_register" name="email" onChange={handleChangeRegister} value = {formDataRegister.email} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        <div className = 'form-element'>
                            <label htmlFor="username" style={{display:'block'}}>Nome de utilizador:</label>
                            <input type="text" id="username" name="username" onChange={handleChangeRegister} value = {formDataRegister.username} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        <div className = 'form-element'>
                            <label htmlFor="password_register" style={{display:'block'}}>Password:</label>
                            {!passChecks?<div className="error-text">A password deve ter pelo menos oito caracteres, e conter pelo menos uma letra maiúscula, uma minúscula e um número.</div>:null}
                            <input type="password" id="password_register" name="password" onChange={handleChangeRegister} value = {formDataRegister.password} style={{width:'100%', boxSizing: 'border-box'}} required                                ></input>
                            
                        </div>
                        <div className = 'form-element'>
                            <label htmlFor="password_confirm" style={{display:'block'}}>Confirmar password:</label>
                        
                            <input type="password" id="password_confirm" name="password_confirm" onChange={e => setPasswordCheck(e.target.value)} value = {passwordCheck} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                            
                        </div>
                        <div style={{textAlign:'center'}}>
                            {passwordCheck!==formDataRegister.password?<div className="error-text">Por favor certifique-se de que as passwords coincidem</div>:null}
                            <button className='custom-button' type='submit' disabled={passwordCheck!==formDataRegister.password||!passChecks?'disabled':null} display='block'>{loginStatus==='registering'?'A registar...':'Registar'}</button>
                        </div>
                        </div>:null}
                     </form>
                </div>
                )
        }
        
    }else{
        return(
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign:'center'}}>
                <img src='/logo.png' style={{width: '150px', height: 'auto', alignSelf: 'center'}}/>
                <h1>Bem-vindo</h1>
                <div style={{width: '250px', display: 'flex', justifyContent: 'space-evenly', alignSelf: 'center'}}>
                    
                    <button type='button' onClick={() => dispatch(request_logout())} className='button-cancel'>Terminar sessão</button>
                    <Link to={{pathname:'/'}}><button className='custom-button'>Continuar</button></Link>
                </div>
                
            </div>
            
        )
    }
    

    
}

export default LoginPage;