import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { filtrar_concelho } from '../redux/actions';
import './ValidationPage.css';
import ListaAvistamentos from './ListaAvistamentos';

function ValidationPage(){
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userStatus.profile)
    const concelhoSelected = useSelector(state => state.validate.filtroConcelho);

        const concelhos = ['Todos', 'Vila do Conde', 'Vila Nova de Gaia', 'Porto', 'Póvoa de Varzim', 'Matosinhos']
        const options = concelhos.map(conc => <option value={conc} selected={concelhoSelected===conc}>{conc}</option>)
   
        return(
            <div className='page-body-validate'>
                <div>
                    <label htmlFor='concelho_display'>Mostrar Concelho</label>
                    <select id='concelho_display' name='concelho_display' onChange={val => dispatch(filtrar_concelho(val.target.value))} >
                        {options}
                    </select>
                </div>
                
                    <ListaAvistamentos tipo = 'validar'/>   
                
            </div>
        )
                        
              
    
    
    
    

    
}

export default ValidationPage;