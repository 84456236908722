import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { change_password, change_password_clean } from '../redux/actions';
import { useHistory } from 'react-router-dom';

function NewPassModal(props){

    const dispatch = useDispatch();
    const history = useHistory();
    const loginStatus = useSelector(state => state.userStatus.loginStatus)
    const userData = useSelector(state => state.userStatus.profile)
    const [passwordAtual, setPasswordAtual] = useState('');
    const [passwordNova, setPasswordNova] = useState('');
    const [passwordNovaConfirm, setPasswordNovaConfirm] = useState('');
    const [passChecks, setPassChecks] = useState(false);
    const rex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$');
    const handleCancel = () => {
        
        history.goBack()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(rex.test(passwordNova)){
            dispatch(change_password(userData.id, passwordAtual, passwordNova))    
        }
        
    }

    useEffect(() => {
        
        if(rex.test(passwordNova)){
            setPassChecks(true)
        }else{
            setPassChecks(false)
        }
        return () => {dispatch(change_password_clean())}
    }, [passwordNova])

     return(
         <div style={{height:'100%', padding:'10px', overflowY: 'auto'}}> 
            
            {loginStatus!=='change_pass_success' ?<form onSubmit={handleSubmit}>
                        
                        {loginStatus==='change_pass_failed' ?<div className='error-text'>Ocorreu um erro. Por favor tente novamente.</div>:null}
                        <div className = 'form-element'>
                            <label htmlFor="password_atual" style={{display:'block'}}>Password atual:</label>
                            <input type="password" id="password_atual" name="password" onChange={(e) => setPasswordAtual(e.target.value)} value = {passwordAtual} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        <div className = 'form-element'>
                            <label htmlFor="password_nova" style={{display:'block'}}>Password nova:</label>
                            {!passChecks?<div className="error-text">Mínimo oito caracteres, com pelo menos uma maiúscula, uma minúscula e um número.</div>:null}
                            <input type="password" id="password_nova" name="password"  onChange={(e) => setPasswordNova(e.target.value)} value = {passwordNova} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        <div className = 'form-element'>
                            <label htmlFor="password_nova_confirm" style={{display:'block'}}>Confirmar password:</label>
                            <input type="password" id="password_nova_confirm" name="password"  onChange={(e) => setPasswordNovaConfirm(e.target.value)} value = {passwordNovaConfirm} style={{width:'100%', boxSizing: 'border-box'}} required></input>
                        </div>
                        {passwordNova!==passwordNovaConfirm?<div className='info-text' style={{marginTop: '-10px'}}>Por favor verifique se as passwords são iguais.</div>:null}
                        <div style={{textAlign:'center'}}>
                            
                            <button className='button-cancel' type="button" onClick={handleCancel}>Cancelar</button>
                            <button className='custom-button' type='submit' disabled={passwordNova!==passwordNovaConfirm||!passChecks}>{loginStatus==='changing_pass'?'A submeter...':'Submeter'}</button>
                        </div>
                    </form>: <div>Password alterada.<button className='custom-button' onClick={handleCancel}>Fechar</button></div>}
        </div>
    )
}

export default NewPassModal


