export default function submissao(
    state = {
        status: 'WAITING',
        error:''
    },
    action){
        switch(action.type){
            case 'SUBMISSION_START':
               return {...state, status:'SUBMITTING'};
            case 'SUBMISSION_SUCCESS':
                return {...state, status: 'SUBMITTED'};
            case 'SUBMISSION_FAILED':
                return {...state, status: 'FAILED', error:action.payload}
            case 'SUBMISSION_CLEAN':
                return {...state, status: 'WAITING', error:''}
            default:
                return state

        }
    }
