export function timeoutPromise(ms, promise) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error("promise timeout"))
      }, ms);
      promise.then(
        (res) => {
          if(res.status !==200) reject('error')
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    })
  }