import React, { useEffect, useState } from 'react';
import ReactMapboxGl, { Marker, GeoJSONLayer, ZoomControl } from "react-mapbox-gl";
import limites_concelhos from '../map/limites_concelhos_gaivotas.json';
import limite_visivel from '../map/limite_gaivotas.json';
import {useSelector, useDispatch} from 'react-redux';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {point} from '@turf/helpers';
import {featureEach} from '@turf/meta';
import circle from '@turf/circle';
import {update_form_avistamento_coords, update_form_ninho_coords, update_form_ataque_coords, update_form_avistamento_concelho, update_form_ninho_concelho, update_form_ataque_concelho} from '../redux/actions';



const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN
});

function SelectionMap(props){
    const avistamentoLong = useSelector(state => state.novoAvistamento.long)
    const avistamentoLat = useSelector(state => state.novoAvistamento.lat)
    const aninhamentoLong = useSelector(state => state.novoAninhamento.long)
    const aninhamentoLat = useSelector(state => state.novoAninhamento.lat)
    const ataqueLat = useSelector(state => state.novoAtaque.lat)
    const ataqueLong = useSelector(state => state.novoAtaque.long)
    const userLocation = useSelector(state => state.userLocation)
    const [baseMap, setBaseMap] = useState({baseButton: "Satélite", baseUrl: `mapbox://styles/mapbox/streets-v11?optimize=true}`});
    const [coords, setCoords] = useState([0, 0])
    const [initCoords, setInitCoords] = useState([-8.661, 41.236])
    const [zoom, setZoom] = useState([15])
    const dispatch = useDispatch();
    


    const icones = {avistamento: '/gull.png', aninhamento: '/nest.png', ataque: '/ataque.png'};

    const handleBaseChange = () => {
      if(baseMap.baseButton === 'Satélite'){
          setBaseMap({baseButton: "Mapa", baseUrl: `mapbox://styles/mapbox/satellite-v9?optimize=true`})
      } else {
          setBaseMap({baseButton: "Satélite", baseUrl: `mapbox://styles/mapbox/streets-v11?optimize=true`});
      }
  }

    useEffect(() => {
      if(coords[0]){

        const ponto = point(coords)

        var concelho = null
        featureEach(limites_concelhos, feature => {
          if(booleanPointInPolygon(ponto, feature)){
            concelho = feature.properties.Concelho
          }
          
        })
        switch(props.tipo){
          case 'aninhamento':
            dispatch(update_form_ninho_concelho(concelho))
            break
          case 'avistamento':
            dispatch(update_form_avistamento_concelho(concelho))
            break
          case 'ataque':
            dispatch(update_form_ataque_concelho(concelho))
            break
          default:
            break
      }
     
    }}, [coords])

    useEffect(() => {

        if(userLocation.hasLocation){
          switch(props.tipo){
              case 'aninhamento':
                dispatch(update_form_ninho_coords(userLocation.location))
                setInitCoords([userLocation.location[1], userLocation.location[0]])
                break
              case 'avistamento':
                dispatch(update_form_avistamento_coords(userLocation.location))
                setInitCoords([userLocation.location[1], userLocation.location[0]])
                break
              case 'ataque':
                dispatch(update_form_ataque_coords(userLocation.location))
                setInitCoords([userLocation.location[1], userLocation.location[0]])
                break
              default:
                break

        }
      } else{
          switch(props.tipo){
              case 'aninhamento':
                if(aninhamentoLat !== null && aninhamentoLong !== null){
                  setInitCoords([aninhamentoLong, aninhamentoLat])
                }
                dispatch(update_form_ninho_coords(initCoords))
                
                break
              case 'avistamento':
                if(avistamentoLat !== null && avistamentoLong !== null){
                  setInitCoords([avistamentoLong, avistamentoLat])
                }
                dispatch(update_form_avistamento_coords(initCoords))
                
                break
              case 'ataque':
                if(ataqueLat !== null && ataqueLong !== null){
                  setInitCoords([ataqueLong, ataqueLat])
                }
                dispatch(update_form_ataque_coords(initCoords))
                
                break
              default:
                break
          }
      }
    }, [])

      useEffect(() => {
        
        switch(props.tipo){
          case 'aninhamento':
            setCoords([aninhamentoLong, aninhamentoLat])
            break
          case 'avistamento':
            setCoords([avistamentoLong, avistamentoLat])
            break
          case 'ataque':
            setCoords([ataqueLong, ataqueLat])
            break
          default:
            break
        }
          }, [avistamentoLat, avistamentoLong, aninhamentoLat, aninhamentoLong, ataqueLat, ataqueLong]);
          
    const handleClick = (map, evt) =>{
      switch(props.tipo){
          case 'aninhamento':
            dispatch(update_form_ninho_coords([evt.lngLat.lat, evt.lngLat.lng]))
            break
          case 'avistamento':
            dispatch(update_form_avistamento_coords([evt.lngLat.lat, evt.lngLat.lng]))
            break
          case 'ataque':
            dispatch(update_form_ataque_coords([evt.lngLat.lat, evt.lngLat.lng]))
            break
          default:
            break
        }
      
    }

    const handleClickLocation = () =>{
      switch(props.tipo){
          case 'aninhamento':
            dispatch(update_form_ninho_coords(userLocation.location))
            break
          case 'avistamento':
            dispatch(update_form_avistamento_coords(userLocation.location))
            break
          case 'ataque':
            dispatch(update_form_ataque_coords(userLocation.location))
            break
          default:
            break
        }
      
    }


    return(
        <Map    
            containerStyle={{
            position:'relative',
            width: "100%",
            height: "100%",
            cursor: 'pointer'
            
            }
            }
            
            onViewportUpdate={e => setZoom(e.zoom)}
            zoom={zoom}
            center={initCoords}
            // renderChildrenInPortal={true}
            style={baseMap.baseUrl}
            onClick={handleClick}
        >
          <Marker
                coordinates={coords}
                anchor="center">
                <img src={icones[props.tipo]} width='32px'/>
            
          </Marker>
          
          <ZoomControl position='top-right' style={{marginTop:'35px'}}/>
          <GeoJSONLayer
                    key={1}
                    data={limite_visivel}
                    fillPaint={{"fill-color": 'grey', "fill-opacity":0.6}}
                    linePaint={{"line-color":'grey'}}
                />
                <div
                        onClick={handleBaseChange}
                        
                        style = {
                            {
                            position:'absolute',
                            top:'10px',
                            right:'10px',
                            width:'60px',
                            height:'26px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F7F9F9',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize:12,
                            WebkitBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                            MozBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                            boxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                            WebkitTapHighlightColor: 'rgba(51, 181, 229, 0.4)'
                            }
                            }
                            >
                            
                                {baseMap.baseButton}
                            
                        </div>
              <div style={{position: 'absolute', top:0, left:0, color: 'white', backgroundColor:'#00000050'}}>Clique no mapa para selecionar o local do avistamento.</div>
              {userLocation.hasLocation?<>
            <Marker
                coordinates={[userLocation.location[1], userLocation.location[0]]}
                anchor="center"
                onClick={() => handleClickLocation()}>
                <img src={'/blue_dot12.png'}/>
            
            </Marker>
            <GeoJSONLayer
                key={1000}
                data={circle([userLocation.location[1], userLocation.location[0]],userLocation.accuracy, {steps: 50, units: 'meters'} )}
                
                fillPaint={{"fill-color": "blue", "fill-opacity":0.2}}
                />
            </>: null}
        </Map>
    )
}

export default SelectionMap;