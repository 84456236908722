export default function novoAvistamentoReducer(
    state = {
        data: null,
        long:null,
        lat: null,
        vida: false,
        ruido: false,
        lixo: false,
        alimentacao:'Não se está a alimentar',
        detalhes:'',
        numero: 1,
        especie:'Não sei',
        imageInfo: [],
        concelho:null

    },
    action){
        switch(action.type){
            case 'UPDATE_FORM_AVISTAMENTO_DATE':
                return {...state, data: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_COORDS':
                return {...state, long: action.payload[1], lat:action.payload[0]}
            case 'UPDATE_FORM_AVISTAMENTO_VIDA':
                return {...state, vida: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_NUMERO':
                return {...state, numero: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_ESPECIE':
                return {...state, especie: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_RUIDO':
                return {...state, ruido: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_LIXO':
                return {...state, lixo: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_ALIMENTACAO':
                return {...state, alimentacao: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_DETALHES':
                return {...state, detalhes: action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_IMAGE':
                return {...state, imageInfo:action.payload}
            case 'UPDATE_FORM_AVISTAMENTO_CONCELHO':
                return {...state, concelho:action.payload}
            case 'CLEAR_AVISTAMENTO_FORM':
                return {...state, data: null, long:null, lat:null, vida: false, ruido: false, lixo: false, alimentacao:'Não se está a alimentar', detalhes:'',numero:1, especie: 'Não sei', imageInfo:[]}
            default:
                return state
        }
    }