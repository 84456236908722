import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner';
import {useHistory, useLocation} from 'react-router-dom';
import './ObservationCard.css'
import { useDispatch} from 'react-redux';
import { delete_observation_confirm, validate_observation_check, validate_observation_clean, set_map_state, hide_sidebar } from '../redux/actions';
 
const ObservationCard = props => {
    const [loadedImage, setLoadedImage] = useState(false);
    const IMAGENS = {avistamento: '/gull.png', aninhamento: '/nest.png', ataque: '/ataque.png'}
    const LABEL = {avistamento: 'Gaivota', aninhamento: 'Ninho', ataque: 'Ataque'}
    const target_list = {Adulto: 'Adulto', Criança: 'Criança', AEstimação: "Animal de estimação", ASelvagem: 'Animal selvagem', Objeto:'Objeto'}

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        
        
        if(props.card_type==='validar'){
            return () => dispatch(validate_observation_clean());
        }else{
            return null
        }
        

      }, []);

      const openValidation = () => {
        dispatch(validate_observation_check(props.id, props.tipo))

        history.push('validar/validar_detalhes')
      }
      const openDeleteConfirmation = () => {
        dispatch(delete_observation_confirm(props.id, props.tipo))
        const current = location.pathname
        history.push(`${current}/confirmar`)
      }

      const flyToPoint = (coords) =>{
        history.push('/')
        if(window.innerWidth <=810){
            dispatch(hide_sidebar())
        }
        
        dispatch(set_map_state({latitude:coords[1], longitude:coords[0], zoom:18}))
      }
    return(
        //<Link to={`/atividade/${props.atividade._id}`} style={{textDecoration:'inherit', color:'inherit'}}>
            <div style={{border: '1px solid grey', borderRadius:'5px', width:'250px', height:'275px', marginBottom:'25px', marginLeft:'10px', marginRight:'10px', overflow:'hidden'}}>
                <div style={{position:'relative'}}>
                    <div style={{width: '100%', height:'150px'}}>
                        {!loadedImage ? 
                            <Loader
                                type='TailSpin'
                                color='#0dafd0ff'
                                height='40'
                                width='40'
                            
                            />
                        
                        :null}

                        <img
                        style={loadedImage?{width:'100%', height:'100%', objectFit:'cover'}:{width:'100%', height:'100%', visibility:'hidden'}}
                        src = {`/imagens/${props.properties.imagens[0].url}`}
                        onLoad = {() => setLoadedImage(true)}
                        />
                        <img
                                    src={IMAGENS[props.tipo]}
                                    style={{width:30, height:30, resizeMode:'cover', position:'absolute', top:2, right:2}}
                                />
                        {props.properties.validado?<img
                                    src='/check-mark.png'
                                    style={{width:30, height:30, resizeMode:'cover', position:'absolute', top:2, left:2}}
                                />:null}
                            <div style={{ fontSize:'14px', fontWeight:'bold', position:'absolute', bottom:0, left:0, paddingLeft:'2px', paddingRight:'2px', color:'white', backgroundColor:'#00000050'}}>{LABEL[props.tipo]}</div>
                    </div>
                    <span style={
                        {
                            position:'absolute',
                            right:'0px',
                            bottom:'7px',
                            padding:'3px',
                            backgroundColor:'rgba(0,0,0,0.5)',
                            color:'white',
                            borderRadius:'5px 0 0 0',
                            fontSize:'14px',
                            fontWeight:'bold',
                            paddingBottom:'0px',
                            
                            }}>{props.properties.validado}</span>
                </div>
                <div style={{height:'125px', paddingLeft:'5px', paddingRight:'5px', display:'flex', justifyContent:'space-around', flexDirection:'column', boxSizing: 'border-box'}}>
                    
                        
                        <div style={{ fontSize:'14px', color:'grey',}}>
                            <span>{`${new Date(props.properties.data).toLocaleDateString("pt-PT")}, ${new Date(props.properties.data).toLocaleTimeString("pt-PT", { hour:'2-digit', minute:'2-digit'})}`}</span>
                        </div>
                        {props.tipo === 'avistamento'?<div className='scrollbar-custom' style={{overflowY:'auto', fontSize:13}}>
                        <div><span style={{fontWeight:'bold'}}>Espécie:</span> <span style={props.properties.especie!=="Não sei"?{fontStyle:'italic'}:{}}>{props.properties.especie}</span></div>
                            <div><span style={{fontWeight:'bold'}}>Número de indivíduos:</span>  {props.properties.numero}</div>
                            <div><span style={{fontWeight:'bold'}}>Indivíduo{props.properties.numero>1?'s':''} vivo{props.properties.numero>1?'s':''}:</span>  {props.properties.vida ? 'Sim': 'Não'}</div>
                            <div><span style={{fontWeight:'bold'}}>A causar ruído:</span>  {props.properties.ruido ? 'Sim': 'Não'}</div>
                            <div><span style={{fontWeight:'bold'}}>A mexer em lixo urbano:</span>  {props.properties.lixo ? 'Sim': 'Não'}</div>
                            <div><span style={{fontWeight:'bold'}}>Alimentação:</span>  {props.properties.alimentacao}</div>
                            <div><span style={{fontWeight:'bold'}}>Detalhes:</span>  {props.properties.detalhes}</div>

                        </div>:null}
                        {props.tipo === 'aninhamento'?<div className='scrollbar-custom' style={{overflowY:'auto', fontSize:13}}>
                            <div><span style={{fontWeight:'bold'}}>Número de ovos:</span> {props.properties.n_ovos===-1?'Desconhecido':props.properties.n_ovos}</div>
                            <div><span style={{fontWeight:'bold'}}>Progenitores presentes:</span> {props.properties.progenitores ? 'Sim': 'Não'}</div>
                            <div><span style={{fontWeight:'bold'}}>Local:</span> {props.properties.local}</div>
                            <div><span style={{fontWeight:'bold'}}>A causar ruído:</span> {props.properties.ruido ? 'Sim': 'Não'}</div>
                            <div><span style={{fontWeight:'bold'}}>Detalhes:</span> {props.properties.detalhes}</div>
                        </div>:null}
                        {props.tipo === 'ataque'?<div className='scrollbar-custom' style={{overflowY:'auto', fontSize:13}}>
                            <div><span style={{fontWeight:'bold'}}>Alvo:</span>
                                {
                                Object.keys(props.properties.alvo).map(key=>(
                                    props.properties.alvo[key]&&key!=='_id'?<li key={key}>{target_list[key]}</li>:null)
                                    )
                                }
                            </div>
                            <div><span style={{fontWeight:'bold'}}>Descrição:</span> {props.properties.descricao} </div>
                        </div>:null}

                        <div style={{display:'flex', justifyContent: 'space-around'}}>
                            <div onClick={openDeleteConfirmation} style={{color:'red', cursor: 'pointer', textAlign:'center'}}>Eliminar</div>
                            {props.card_type==='validar'?<div onClick={openValidation} style={{color:'green', cursor: 'pointer'}}>Validar</div>:null}
                            <div onClick={() => flyToPoint([props.coords[0], props.coords[1]])} style={{cursor: 'pointer', textAlign:'center'}}>Ver no mapa</div>
                        </div>
                        
                    
                    
                </div>
                
            </div>
           
    )
}

export default ObservationCard;