import React, { useRef, useEffect, useState } from 'react';
import './MapArea.css';
import MapPopup from '../elements/MapPopup';

import circle from '@turf/circle';

import limite_visiv from './limite_gaivotas.json';
import {open_popup,close_popup, show_sidebar, set_map_state, fetchAvistamento, fetchAtaque, fetchAninhamento} from '../redux/actions';
import {featureCollection, point} from '@turf/helpers';
import { useDispatch, useSelector } from 'react-redux';
import FilterButton from '../elements/FilterButton';
import ReactMapboxGl, {Image, Popup,Layer,  GeoJSONLayer, ZoomControl, RotationControl, Source} from "react-mapbox-gl";

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN
  });

function MapArea() {
    //Mapa de avistamentos principal, que contém o mapa, elementos de controlo, etc

    const mapRef = useRef(); //para guardar uma referencia direta ao mapa, usado em baixo
    const popup = useSelector(state => state.popup); //estado do popup, se algum está visível
    const [baseMap, setBaseMap] = useState({baseButton: "Satélite", baseUrl: `mapbox://styles/mapbox/streets-v11?optimize=true}`}); //nome e tipo do mapa de base
    //nota: uma vez que o baseButton corresponde ao texto to botão num dado momento, e o baseUrl ao mapa que está a ser usado, significa que
    //quando estiver ativo o mapa das ruas, o botão diz "Satélite" (uma vez que o botão nesse momento serve para mudar para satélite)
    //e quando estiver visível a imagem de satélite, o botão diz "Mapa", porque serve para voltar ao mapa normal. Ou seja, o botão é sempre o oposto do URL

    const [displayPoints, setDisplayPoints] = useState({loaded:false, data:{}}); //pontos dos avistamentos a mostrar no mapa
    const filterState = useSelector(state => state.mapFilter); //estado do filtro dos avistamentos

    const handleBaseChange = () => {
        //muda o URL do mapa ativo, e o botão, pela lógica explicada antes, sempre opostos:
        if(baseMap.baseButton === 'Satélite'){
            //Se o botão atualmente disser satélite, mudamos o mapa para o satélite e o texto do botão para "Mapa"
            setBaseMap({baseButton: "Mapa", baseUrl: `mapbox://styles/mapbox/satellite-v9?optimize=true`})
        } else {
            //e vice-versa
            setBaseMap({baseButton: "Satélite", baseUrl: `mapbox://styles/mapbox/streets-v11?optimize=true`});
        }
    }
    
    
    const showSidebar = useSelector(state => state.sidebar.show); //estado da barra lateral (visível ou não)
    const userLocation = useSelector(state => state.userLocation); //localização do utilizador
    const mapState = useSelector(state => state.mapState); //estado do mapa, i.e. coordenadas do centro e nível de zoom
    //diferentes avistamentos e respetivos estados (se foram pedidos à API, se estão já carregados, ou se falharam):
    const avistamentos_status = useSelector(state => state.avistamentos.statusGeral);
    const avistamentos_load = useSelector(state => state.avistamentos.avistamentos);
    const aninhamentos_status = useSelector(state => state.aninhamentos.statusGeral);
    const aninhamentos_load = useSelector(state => state.aninhamentos.aninhamentos);
    const ataques_status = useSelector(state => state.ataques.statusGeral);
    const ataques_load = useSelector(state => state.ataques.ataques);
    
    const dispatch = useDispatch();

    const filter_observacoes = (lista) => {
        //função para filtrar uma dada lista de observações, vai ser usada no useEffect seguinte

        //da forma como está estruturada a app neste momento, todas as observações são pedidas à API independentemente das datas, e a filtragem ocorre aqui no frontend
        //idealmente seria preferível que os próprios filtros de data alterassem o pedido à API, de forma a que só viessem as observações que interessam e não sobrecarregassemos o mapa
        //a app está semi-pensada para isso, já há uma limitação no número de observações pedidas, seria apenas uma questão de enviar também os filtros e preparar o backend para isso
        //com mais tempo teria já implementado, but you can't always get what you want

        let observacoes_filt = lista; //lista que vamos filt
        
        if(filterState.datas[0]){
            //se houver alguma data mínima, filtramos as observações acima dela
            observacoes_filt = observacoes_filt.filter(ob => new Date(ob.properties.data) >= filterState.datas[0]);
        }
        if(filterState.datas[1]){
            //se houver alguma data máxima, filtramos as observações abaixo dela
            observacoes_filt = observacoes_filt.filter(ob => new Date(ob.properties.data) <= filterState.datas[1]);
        }
        return observacoes_filt
    }

    useEffect(() => {
        //corre quando o mapa é mounted ou quando há alguma alteração nas observações que se encontram no estado global,
        //de forma a tratar e filtrar as observações para as mostrar no mapa
        if(avistamentos_status === 'LOADED' && aninhamentos_status === 'LOADED' && ataques_status === 'LOADED'){
          
            const avistamentos_filt = filter_observacoes(avistamentos_load); //aplicamos o filtro das datas nos avistamentos
            
            const avistamentos_disp = filterState.tipos.avistamentos ? avistamentos_filt.map(
                av => (
                    //retornamos os avistamentos no formato que o mapa usa (i.e. adicionando  o tipo, ícone e id, para utilizar o ícone adequado e termos uma key)
                    //temos um ícone diferente para os não validados, em que é adicionada uma bolinha vermelha.
                    {...av,
                        properties: {...av.properties, tipo: 'avistamento', icone:`avistamento_${av.properties.validado}`, id:av._id}
                    }
                )
            ): [];

            //mesma operação de cima, para os ninhos e ataques:
            const aninhamentos_filt = filter_observacoes(aninhamentos_load);

            const aninhamentos_disp = filterState.tipos.aninhamentos ?  aninhamentos_filt.map(
            an => (
                {...an, properties: {...an.properties, tipo: 'aninhamento', icone:`aninhamento_${an.properties.validado}`, id:an._id}
            }
            )
            ): [];
            
            const ataques_filt = filter_observacoes(ataques_load);

            const ataques_disp = filterState.tipos.ataques ? ataques_filt.map(
            at => (
                {...at, properties: {...at.properties, tipo:`ataque`, icone:`ataque_${at.properties.validado}`, id:at._id}}
            )
            ) : [];

            
            const display = [...aninhamentos_disp, ...avistamentos_disp, ...ataques_disp]; //juntamos as observações de todos os tipos.
        
            setDisplayPoints({loaded:true, data:featureCollection(display)}); //atualizamos o state do componente com os avistamentos, convertidos num geojson feature collection para o mapa, e atualizamos o estado para loaded
        
        }
      }, [avistamentos_status, avistamentos_load, aninhamentos_status, aninhamentos_load, ataques_status, ataques_load, filterState]);
  

    function handleLocationUpdate(e){
        //usada no useEffect seguinte, quando a localização do utilizador mudar
        if(!mapRef.current.state.map.isMoving()){
            //ocasionalmente havia um novo update quando o mapa se estava a mover (e.g. por input do utilizador), e isso ia interferir e fazê-lo saltar para trás
            //por isso só fazemos update quando o mapa não se está a mover. Podemos ocasionalmente perder algum movimento por isso, mas é marginal.

            //estamos a fazer dispatch diretamente daqui para o reducer, em vez de usar uma action pelo caminho. Provavelmente é uma anti-pattern, mas ¯\_(ツ)_/¯ 
            dispatch({type: 'UPDATE_LOCATION', payload: {hasLocation: true, location: [e.coords.latitude, e.coords.longitude], accuracy: e.coords.accuracy, error: false}});
        }
        
        
    }

    function handleLocationError(e){
        //se houver um erro na localização, atualizamos o state da app com isso.
        dispatch({type: 'LOCATION_ERROR'})
    }

    useEffect(() => {
        //quando o mapa carrega, começamos a vigiar a localização do utilizador com a API de geolocalização do browser.
        //usamos as funções acima para atualizar a localização no state quando esta muda.
        const location_watch = navigator.geolocation.watchPosition(
                    //sempre que a API de localização disparar, verificamos se a localização mudou em relação ao state, atualizamos se sim
                    (e) => {if([e.coords.latitude, e.coords.longitude]!==userLocation.location ){handleLocationUpdate(e)}},
                    () => handleLocationError() //o segundo argumento do watchPosition é uma função para lidar com error, usamos a que criamos antes
                )
        return () => {if(navigator.geolocation){
                        //deixamos de vigiar a localização quando o componente for unmounted
                        navigator.geolocation.clearWatch(location_watch);
                    }}
    }, []);
 
    function handleViewportUpdate(map){
        //usada no mapa, sempre que o mapa for movido, vamos buscar as coordenadas e zoom novos e atualizamos o state com elas
        const coords = map.getCenter();
        const zoom = map.getZoom();
        
        dispatch(set_map_state({latitude:coords.lat, longitude:coords.lng, zoom:zoom}))
 
    }
     
    function onHoverStart(){
        //usamos abaixo, para os markers do mapa, quando o cursor estiver por cima de um, convertemos no pointer para indicar que é clicável
        mapRef.current.state.map.getCanvas().style.cursor = 'pointer'
    }

    function onHoverEnd(){
        //voltamos ao cursor normal quando saímos de cima de um marker. Usamos o grab uma vez que estamos na mesma por cima do mapa, e ele é arrastável
        mapRef.current.state.map.getCanvas().style.cursor = 'grab'
    }

    const handleMarkerClick = (map) => {
        //handler para quando o utilizador clica num marker
        //as primeiras duas linhas são simplesmente a forma como podemos aceder aos dados de um click no mapbox GL
        //porque ao clicar, são devolvidos no evento todos os elementos por baixo do clique, incluido os da própria camada de base (e.g. estradas)
        //por isso filtramos os que correspondem à layer dos nossos pontos, que é a 'unclustered_layer' (nome atribuido abaixo)
        //mesmo aí é retornado um array, queremos o primeiro elemento
        const point = mapRef.current.state.map.queryRenderedFeatures(map.point).filter(point => point.layer.id ==="unclustered_layer")[0];
        const point_data = {...point.properties};
       
        //dependendo do tipo de avistamento, pedimos os restantes dados para mostar no popup, uma vez que inicialmente só temos os dados básicos para mostrar o ponto no mapa, como id, tipo e coordenadas
        if(point_data.tipo==='avistamento'){
            dispatch(fetchAvistamento(point_data.id))
        }else if(point_data.tipo==='aninhamento'){
            dispatch(fetchAninhamento(point_data.id))
        }else if(point_data.tipo==='ataque'){
            dispatch(fetchAtaque(point_data.id))
        }
       
        dispatch(
            open_popup(
                //abrimos o popup. Só precisamos de lhe dar a localização onde vai abrir e o tipo que vai ter, a informação a mostrar vai vir do state global
                //que por sua vez vai ficar preenchido quando o API request para os detalhes do ponto for acabado
                //a consequência disto é que o popup vai mostrar qualquer que seja a informação que esteja no secção de popup do global state
                //o que não deve ser problemático uma vez que esta é carregada quando o utilizador carrega num ponto, e limpa quando o popup é fechado
                {
                    position:[map.lngLat.lng, map.lngLat.lat],
                    tipo:point_data.tipo
                }
            )
        )
    }
 
    return(
        <div>
            
            <Map
                //mapa do Mapbox GL. Ver docs da biblioteca react-mapbox-gl e do Mapbox GL para detalhes
                ref={mapRef}
                containerStyle={{
                position:'absolute',
                width: "100%",
                height: "100%",
                }}
                
                onMouseDown={()=>dispatch(close_popup())} //para que se possa fechar os popups clicando no mapa
                onMoveEnd={handleViewportUpdate}
                
                zoom={[mapState.zoom]}
                center={[mapState.longitude, mapState.latitude]}
               
                style={baseMap.baseUrl}
                >
                     
                {popup.show ? <Popup
                        //mostramos o popup, totalmente controlado pelo state da app
                        offset={[0, -10]}
                        coordinates={[popup.position[0], popup.position[1]]}
                        closeOnClick={true}
                        onClose={ () => dispatch(close_popup())}>
                                    <MapPopup/>
                                </Popup>:null}
                <GeoJSONLayer
                    //esta layer é o limite da área de estudo.
                    //ver nota sobre "Idiossincrasias de bibliotecas externas" no documento de documentação comum a todos os projetos
                    key={1}
                    data={limite_visiv}
                    fillPaint={{"fill-color": 'grey', "fill-opacity":0.6}}
                    linePaint={{"line-color":'grey'}}
                />
                <Image
                    //carregamos as imagens dos ícone para usar nos markers do mapa. O id foi construído ao processar as observações, dependendo de se esta está validada.
                    id='aninhamento_true' url={'/nest.png'} options={{'icon-size':0.5}}/>
                <Image id='aninhamento_false' url={'/nest_nvalidado.png'} options={{'icon-size':0.5}}/>
                <Image id='ataque_true' url={'/ataque.png'} options={{'icon-size':0.5}}/>
                <Image id='ataque_false' url={'/ataque_nvalidado.png'} options={{'icon-size':0.5}}/>
                <Image id='avistamento_true' url={'/gull.png'} options={{'icon-size':0.5}}/>
                <Image id='avistamento_false' url={'/gull_nvalidado.png'} options={{'icon-size':0.5}}/>
                <GeoJSONLayer
                    //construimos uma layer GeoJSON com as observações processadas. Esta ainda vai ser "tratada" a seguir,
                    //para criar clusters de pontos muito próximos.
                    id='observations_geojson'
                    data = {displayPoints.data}
                    sourceOptions={{
                        cluster: true,
                        clusterMaxZoom: 14,
                        clusterRadius: 50
                        }} //dizemos à biblioteca que queremos fazer cluster de pontos próximos, e os respetivos parâmetros
                        //isto vai juntar os pontos próximos num só, com um atributo point_count, de quantos pontos foram unidos em cada caso
                />
                <Layer
                    //processa a layer anterior, e mostra só os pontos que tiverem point_count, ou seja, que correspondam à união de múltiplos pontos próximos
                    //mostra um círculo no mapa no centro do cluster
                    id='cluster_layer'
                    sourceId='observations_geojson'
                    filter={
                        [
                        'has', 'point_count'
                        ]
                    }
                    paint={{
                        'circle-color': '#0DAFD0',
                        'circle-opacity': 0.6,
                        'circle-radius': 30
                    }}
                    type='circle'
                    //ao clicar, fazemos zoom para a zona do centro do cluster, alterando o estado do mapa no state da app:
                    onClick={(evt) => dispatch(set_map_state({latitude:evt.lngLat.lat, longitude:evt.lngLat.lng, zoom:mapState.zoom + 2}))}
                    //alteramos o cursor ao sobrevoar, para indicar que é clicável:
                    onMouseEnter = {onHoverStart}
                    onMouseLeave = {onHoverEnd}
                />
                <Layer
                    //mesmo princípio da anterior, aqui para indicar o número de avistamentos no cluster por cima do círculo gerado anteriormente
                    id='cluster_label'
                    sourceId='observations_geojson'
                    filter={
                        [
                        'has', 'point_count'
                        ]
                    }
                    layout={{
                        "text-field": "{point_count}",
                        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                        "text-size": 16,
                        
                        }}
                    paint= {{
                    "text-color": "#ffffff"
                    }}       
                />
                
                <Layer
                    //mostramos os pontos que não tiverem point_count, i.e. que não foram clustered, individualmente
                    id='unclustered_layer'
                    sourceId='observations_geojson'
                    layerOptions={{
                        filter: [
                        '!has', 'point_count'
                        ]
                    }}
                    layout = {{"icon-image":["get", "icone"], "icon-size":0.5}}
                    onMouseEnter = {onHoverStart}
                    onMouseLeave = {onHoverEnd}
                    onClick={(map, evt) => handleMarkerClick(map, evt)}
                />        
                    
                {/* {popup.show ? <Popup
                offset={[0, -10]}
                // anchor='bottom'
                coordinates={[popup.position[0], popup.position[1]]}
                closeOnClick={true}
                onClose={ () => dispatch(close_popup())}>
                                    <MapPopup/>
                                </Popup>:null} */}
                <ZoomControl position='top-right' style={{marginTop:'35px'}}/>
                <RotationControl position='top-right' style={{marginTop:'40px'}}/>
                
                {userLocation.hasLocation?<>
                <Source
                    //usamos a localização do utilizador para gerar uma source para as layers seguintes, que vão mostrar o blue dot com a localização do utilizador
                    id="user_loc_source" geoJsonSource={{type:'geojson', data:point([userLocation.location[1], userLocation.location[0]])}}
                />
                <Layer
                //localização do utilizador - blue dot
                id='user_loc'
                sourceId='user_loc_source'
                
                paint={{
                    'circle-color': 'blue',
                    "circle-stroke-width": 1,
                    "circle-stroke-color": 'darkblue',
                    'circle-radius': 5
                }}
                type='circle' 
                />

                <GeoJSONLayer
                //precisão da localização, círculo semi-transparente à volta do blue dot
                    key={1000}
                    data={circle([userLocation.location[1], userLocation.location[0]],userLocation.accuracy, {steps: 50, units: 'meters'} )}
                    fillPaint={{"fill-color": "blue", "fill-opacity":0.2}}
                    />
                </>: null}
            
        
            </Map>

            {!showSidebar?<div
                //botão para mostrar a barra lateral/menu, no canto superior esquerdo da página, quando a barra não está visível.
                onClick={() => dispatch(show_sidebar())}
                style =  {
                    {
                    width:'64px',
                    height:'48px',
                    position: 'absolute',
                    cursor:'pointer',
                    left: '10px',
                    top: '10px',
                    zIndex:'1700',
                    display: 'flex',
                    flexDirection:'column',
                    fontWeight:'bold',
                    alignContent: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F9F9',
                    borderRadius: '2px',
                    boxShadow: 'rgb(0 0 0 / 75%) 2px 2px 3px 0px'
                    }
                    }
                    >
                    
                    <div style={{display:'flex', justifyContent:'space-around'}}><img src='/house.png' alt="" style={{width:'30px', height:'30px'}}/></div>
                    <div style={{fontSize:'0.8em', display:'flex', justifyContent:'space-around'}}>Menu</div>
                            
            </div>:null}
            <div
                //botão para voar para a localização do utilizador no mapa
                onClick={() => {
                    if(userLocation.hasLocation){
                        //só funciona se houver localização atual do utilizador.
                        //Se houver, atualiza o mapa com o centro na localização atual, e zoom para nível 18
                        dispatch(set_map_state({latitude:userLocation.location[0], longitude:userLocation.location[1], zoom:18}))
                    }
                    }
                }
                style = {
                    {
                    position:'absolute',
                    top:'138px',
                    right:'11px',
                    width:'26px',
                    height:'26px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F9F9',
                    borderRadius: '2px',
                    cursor: 'pointer'
                    }
                    }
            >
                
                {userLocation.hasLocation ? <div
                        //se tivermos localização detetada, fica ativo, i.e. azul
                        style={{ width:'8px', height:'8px', borderRadius:'50%', border: '2px solid darkblue', backgroundColor: 'blue'}}
                        ></div> : <div
                            //se não tivermos localização detetada, inativo, i.e. cinzento
                            style={{width:'8px', height:'8px', borderRadius:'50%', border: '2px solid darkgrey', backgroundColor: 'grey'}}
                        ></div>}                   
            </div>
            <div
                //botão para alternar mapa de base entre mapa normal e satélite, como explicado nos handlers acima
                onClick={handleBaseChange}
                
                style = {
                    {
                    position:'absolute',
                    top:'10px',
                    right:'10px',
                    width:'60px',
                    height:'26px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F9F9',
                    borderRadius: '2px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize:12,
                    WebkitBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                    MozBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                    boxShadow: "2px 2px 3px 0px rgba(0,0,0,0.75)",
                    WebkitTapHighlightColor: 'rgba(51, 181, 229, 0.4)'
                    }
                    }
                    >
                    
                        {baseMap.baseButton}
                    
            </div>
            <FilterButton
            //botão que controla o prompt de seleção de filtros
            />
        </div>
        
    )

}

export default MapArea;
