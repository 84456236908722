import React from 'react';

function IE(){
    return (
        <div style={{width:'500px', textAlign:'center', margin:'auto'}}>
            <div><img src='/logo.png' width='250px' alt='logo'/></div>
                <div>Para a melhor experiência com a plataforma <em>Gaivotas AMP</em>, sugerimos o uso de um dos seguintes browsers:</div>
                    <div>
                    <div style={{display:'inline-block'}}>
                        <a href='https://www.microsoft.com/pt-pt/edge' rel='noreferrer' target='_blank' alt='Microsoft Edge' style={{textColor:'black', textDecoration:'none'}}>
                        <div style={{width:'100px', textColor:'black', textDecoration:'none'}}>
                        <img src='/edge.png' width='100px' alt='Microsoft Edge'/>
                        <div style={{width: '100px', textColor:'black', textDecoration:'none'}}>Microsoft Edge</div>
                        </div>
                        </a>
                    </div>
                    <div style={{display:'inline-block'}}>
                        <a href='https://www.mozilla.org/pt-PT/firefox/new/' target='_blank' rel='noreferrer' alt='Mozilla Firefox' style={{textColor:'black', textDecoration:'none'}}>
                        <div style={{width:'100px', textColor:'black', textDecoration:'none'}}>
                        <img src='/firefox.png' width='100px' alt='Mozilla Firefox'/>
                        <div style={{width: '100px'}}>Mozilla Firefox</div>
                        </div>
                        </a>
                    </div>
                    <div style={{display:'inline-block'}}>
                        <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer' alt='Google Chrome' style={{textColor:'black', textDecoration:'none'}}>
                        <div style={{width:'100px', textColor:'black', textDecoration:'none'}}>
                        <img src='/chrome.png' width='100px' alt='Google Chrome'/>
                        <div style={{width: '100px'}}>Google Chrome</div>
                        </div>
                        </a>
                </div>
            </div>
        </div>
    )
}

export default IE;