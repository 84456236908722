import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    update_form_ninho_date,
    update_form_ninho_progenitores,
    update_form_ninho_ovos,
    update_form_ninho_local,
    update_form_ninho_ruido,
    update_form_ninho_detalhes,
    update_form_ninho_inferido,
    clean_form_aninhamento,
    upload_image_clean} from '../redux/actions';
import DateTimePicker from 'react-datetime-picker';
import './IndividualForms.css';

function FormAninhamento(props){
    //componente do formulário de criação de observações, para reportar ninhos
    const dispatch = useDispatch();
    const formData = useSelector(state => state.novoAninhamento); //estado atual do formulário no state geral

    useEffect(() => {
        //quando o componente monta, atualizamos a data do avistamento para a data atual
        const currentDate = new Date();
        dispatch(update_form_ninho_date(currentDate))
        return(() => {
            //limpa formulário, submissão e imagem carregada
            dispatch(clean_form_aninhamento())
            dispatch(upload_image_clean())
        }
        )
    }, [])


    const handleChange = e => {
        //quando um campo do formulário é alterado, atualizar no sítio respetivo do state geral da app
        switch(e.target.name){
            case 'data':
                dispatch(update_form_ninho_date(e.target.value))
                break
            case 'n_ovos':
                dispatch(update_form_ninho_ovos(e.target.value))
                break
            case 'progenitores':
                dispatch(update_form_ninho_progenitores(e.target.checked))
                break
            case 'local':
                dispatch(update_form_ninho_local(e.target.value))
                break
            case 'ruido':
                dispatch(update_form_ninho_ruido(e.target.checked))
                break
            case 'detalhes':
                dispatch(update_form_ninho_detalhes(e.target.value))
                break
            case 'inferido':
                dispatch(update_form_ninho_inferido(e.target.value))
                break
            case 'ovos_desc':
                //se o utilizador não souber o número de ovos, este é registado como -1
                if(e.target.checked){
                    dispatch(update_form_ninho_ovos(-1))
                }else{
                    dispatch(update_form_ninho_ovos(0))
                }
                break
            default:
                break

        }
     
    }

    return(
        <div className='form-group'>
            <div className='form-group-element'>
                <div className='form-element'>
                    <label htmlFor='data' style={{display:'block'}} >Data e hora do avistamento</label>
                    <DateTimePicker
                        name='data'
                        id='data'
                        onChange={(v) => handleChange({target:{name:'data', value:v}})}
                        value={formData.data}
                        disableClock={true}
                        maxDate={new Date()}
                        clearIcon={null}
                        style={{width:'100%'}}
                    />
                </div>
                <div className='form-element'>
                    <label htmlFor='ovos' style={{display:'block'}}>Número de ovos</label>
                    <label htmlFor='ovos_desc' style={{ fontSize:13}}>Não sei</label>
                    <input type='checkbox' name='ovos_desc' id='ovos_desc' onChange={handleChange} />
                    {//se o utilizador tiver indicado que não sabe o número de ovos, o valor ficou -1 e este campo não aparece
                        parseInt(formData.n_ovos)!==-1 ? <input 
                            type='number'
                            name='n_ovos'
                            id='n_ovos'
                            min={0}
                            value={parseInt(formData.n_ovos)}
                            onChange={handleChange}
                            style={{width:'25%', boxSizing:'border-box', display:'block'}}
                            disabled={parseInt(formData.n_ovos)===-1}/>:null //para além de não aparecer também fica disable, for good measure
                                }
                    
                </div>
                
                <div className='form-element'>
                    <label htmlFor='detalhes' style={{display:'block'}}>Detalhes</label>
                    <textarea
                        type='text'
                        name='detalhes'
                        id='detalhes'
                        rows={'5'}
                        cols={'40'}
                        colvalue={formData.detalhes}
                        onChange={handleChange} 
                        style={{width:'100%', boxSizing:'border-box'}}>

                    </textarea>
                </div>
            </div>
            <div className='form-group-element'>
                <div className='form-element'>
                    <label htmlFor='local' style={{display:'block'}}>Local</label>
                    <select id='local' name='local' onChange={handleChange} style={{width:'100%'}}>
                        <option value='Telhado'>Telhado</option>
                        <option value='Solo'>Solo</option>
                        <option value='Outro'>Outro</option>
                    </select>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='inferido' style={{display:'block'}}>Presença do ninho inferida (e não confirmada visualmente)</label>
                    <input type='checkbox' name='inferido' id='inferido' onChange={handleChange} />
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='progenitores' style={{display:'block'}}>Progenitor(es) presente(s)</label>
                    <input type='checkbox' name='progenitores' id='progenitores' onChange={handleChange} />
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='ruido' style={{display:'block'}}>A causar ruído</label>
                    <input type='checkbox' name='ruido' id='ruido' onChange={handleChange} />
                </div>
            </div>
            
        </div>
    )
}

export default FormAninhamento;