import {combineReducers} from 'redux';

import userReducer from './userReducer';
import avistamentosReducer from './avistamentosReducer';
// import avistamentoModalReducer from './avistamentoModalReducer';
import novoAninhamentoReducer from './novoAninhamentoReducer';
import novoAvistamentoReducer from './novoAvistamentoReducer';
import novoAtaqueReducer from './novoAtaqueReducer';
import aninhamentosReducer from './aninhamentosReducer';
import ataquesReducer from './ataquesReducer.js';
import popupReducer from './popupReducer';
import sidebarReducer from './sidebarReducer';
import submissaoReducer from './submissaoReducer';
import avistamentosUserReducer from './avistamentosUserReducer';
import validateReducer from './validateReducer';
import uploadImagem from './uploadImageReducer';
import mapStateReducer from './mapStateReducer';
import locationReducer from './locationReducer';
import editObservationReducer from './editObservationsReducer';
import mapFilterReducer from './mapFilterReducer';
import adminUsersReducer from './adminUsersReducer';
// import editFieldReducer from './editFieldReducer';



const rootReducer = combineReducers({
    userStatus: userReducer,
    avistamentos: avistamentosReducer,
    // avistamentoModal: avistamentoModalReducer,
    novoAninhamento: novoAninhamentoReducer,
    novoAvistamento: novoAvistamentoReducer,
    novoAtaque:novoAtaqueReducer,
    avistamentosUser: avistamentosUserReducer,
    aninhamentos: aninhamentosReducer,
    ataques: ataquesReducer,
    popup: popupReducer,
    sidebar: sidebarReducer,
    userLocation: locationReducer,
    submissao: submissaoReducer,
    uploadImage: uploadImagem,
    mapState: mapStateReducer,
    validate: validateReducer,
    editObservation: editObservationReducer,
    mapFilter: mapFilterReducer,
    usersList: adminUsersReducer
    // editField: editFieldReducer
});



export default rootReducer;