import {
    FETCH_ANINHAMENTOS_BEGIN,
    FETCH_ANINHAMENTOS_SUCCESS,
    FETCH_ANINHAMENTOS_FAILURE,
    FETCH_ANINHAMENTO_BEGIN,
    FETCH_ANINHAMENTO_SUCCESS,
    FETCH_ANINHAMENTO_FAILURE,
    CLEAR_ANINHAMENTO_POPUP
  } from '../actions'; 
  
const initialState = {
aninhamentos: {},
selectedAninhamento:{},
statusGeral: 'WAITING',
statusSelected: 'WAITING',
errorGeral: null,
errorSelected: null
};

export default function aninhamentosReducer(state = initialState, action){
    switch(action.type){
    case FETCH_ANINHAMENTOS_BEGIN:
        return {
            ...state,
            statusGeral: 'LOADING',
            errorGeral: null
        }
        case FETCH_ANINHAMENTOS_SUCCESS:
            return {
                ...state,
                statusGeral: 'LOADED',
                errorGeral: null,
                aninhamentos: action.payload.aninhamentos
            }
        case FETCH_ANINHAMENTOS_FAILURE:
            return {
                ...state,
                statusGeral: 'ERROR',
                errorGeral: action.payload
            }
        case FETCH_ANINHAMENTO_BEGIN:
            return {
                ...state,
                statusSelected: 'LOADING',
                errorSelected: null
            }
        case FETCH_ANINHAMENTO_SUCCESS:
            return {
                ...state,
                statusSelected: 'LOADED',
                errorSelected: null,
                selectedAninhamento: action.payload
            }
        case FETCH_ANINHAMENTO_FAILURE:
            return {
                ...state,
                statusSelected: 'ERROR',
                errorSelected: action.payload
            }
        case CLEAR_ANINHAMENTO_POPUP:
            return {
                ...state,
                selectedAninhamento: {},
                statusSelected: 'WAITING',
                errorSelected: null
            }
        default:
            return state
    }
}