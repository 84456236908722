import {
    FETCH_ATAQUES_BEGIN,
    FETCH_ATAQUES_SUCCESS,
    FETCH_ATAQUES_FAILURE,
    FETCH_ATAQUE_BEGIN,
    FETCH_ATAQUE_SUCCESS,
    FETCH_ATAQUE_FAILURE,
    CLEAR_ATAQUE_POPUP
  } from '../actions';
   
const initialState = {
ataques: {},
selectedAtaque:{},
statusGeral: 'WAITING',
statusSelected: 'WAITING',
errorGeral: null,
errorSelected: null
}; 

export default function ataquesReducer(state = initialState, action){
    switch(action.type){
    case FETCH_ATAQUES_BEGIN:
        return {
            ...state,
            statusGeral: 'LOADING',
            errorGeral: null
        }
    case FETCH_ATAQUES_SUCCESS:
        return {
            ...state,
            statusGeral: 'LOADED',
            errorGeral: null,
            ataques: action.payload.ataques
        }
    case FETCH_ATAQUES_FAILURE:
        return {
            ...state,
            statusGeral: 'ERROR',
            errorGeral: action.payload
        }
    case FETCH_ATAQUE_BEGIN:
        return {
            ...state,
            statusSelected: 'LOADING',
            errorSelected: null
        }
    case FETCH_ATAQUE_SUCCESS:
        return {
            ...state,
            statusSelected: 'LOADED',
            errorSelected: null,
            selectedAtaque: action.payload
        }
    case FETCH_ATAQUE_FAILURE:
        return {
            ...state,
            statusSelected: 'ERROR',
            errorSelected: action.payload
        }
    case CLEAR_ATAQUE_POPUP:
        return {
            ...state,
            selectedAtaque: {},
            statusSelected: 'WAITING',
            errorSelected: null
        }
    default:
        return state
    }
}