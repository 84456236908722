import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import './MapPopup.css';
import {clearAvistamentoPopup, clearAninhamentoPopup, clearAtaquePopup} from '../redux/actions';
import Loader from 'react-loader-spinner';

function MapPopup() {
    //popup com informação de cada observação, que surge no mapa ao carregar num dos ícones
    const target_list = {Adulto: 'Adulto', Criança: 'Criança', AEstimação: "Animal de estimação", ASelvagem: 'Animal selvagem', Objeto:'Objeto'} // converter entre o valor guardade na base de dados e o texto para mostrar ao público
    const LABEL = {avistamento: 'Gaivota', aninhamento: 'Ninho', ataque: 'Ataque'} //converter entre o valor guardado na base de dados e o texto para mostrar ao público
    const [data, setData] = useState(''); //espaço para guardar a data da observação num formato apresentável, que vai ser obtida do respetivo timestamp 
    const [content, setContent] = useState(null); // espaço para guardar o conteúdo do popup, depois de tratar os dados da observação vindos do backend

    const popup = useSelector(state => state.popup); //estado do popup (visível, posição, tipo)
    //dependendo do tipo de observação, só vamos usar um par de cada um dos seguintes, mas como o useSelector não pode ser condicional, carregamos todos de qualquer forma.
    const avistamento_status = useSelector(state => state.avistamentos.statusSelected) //estado do pedido dos detalhes do avistamento selecionado (ou vazia se o ponto em que o utilizador carregou for de outro tipo)
    const avistamento_load = useSelector(state => state.avistamentos.selectedAvistamento.avistamento) //detalhes do avistamento (ou vazio)
    const aninhamento_status = useSelector(state => state.aninhamentos.statusSelected) //igual aos anteriores, para ninho
    const aninhamento_load = useSelector(state => state.aninhamentos.selectedAninhamento.aninhamento)
    const ataque_status = useSelector(state => state.ataques.statusSelected) //igual aos anteriores, para ataque
    const ataque_load = useSelector(state => state.ataques.selectedAtaque.ataque)

    const dispatch = useDispatch();

    useEffect(() => {
        if(popup.tipo==='avistamento' && avistamento_load){
            //se o tipo da observação em que o utilizador carregou for avistamento, e depois de a informação do selecionado
            //ter chegado do backend
            setContent(avistamento_load) //metemos a informação respetiva no conteúdo
            setData(new Date(avistamento_load.properties.data)) //convertemos o timestamp numa data para mostrar
            return () => dispatch(clearAvistamentoPopup()) //limpamos os dados do popup do state geral da app quando o popup for desmontado
        } else if(popup.tipo==='aninhamento' && aninhamento_load){
            //mesma coisa do anterior, para um ninho
            setContent({...aninhamento_load, properties: {...aninhamento_load.properties, data: new Date(aninhamento_load.properties.data)}})
            setData(new Date(aninhamento_load.properties.data))
            return () => dispatch(clearAninhamentoPopup())
        } else if(popup.tipo==='ataque' && ataque_load){
            //mesma coisa dos anteriores, para um ataque
            setContent({...ataque_load, properties: {...ataque_load.properties, data: new Date(ataque_load.properties.data)}})
            setData(new Date(ataque_load.properties.data))
            return () => dispatch(clearAtaquePopup())
        }
     
    }, [avistamento_status, aninhamento_status, ataque_status])

    
    return(
        <div style={{width:'250px', minHeight:'350px', display:'grid',gridTemplateRows: '50% 50%'}}>
            {//se já tiver sido carregado conteúdo do backend, mostramo-lo, caso contrário, uma animação com um spinner
            content?<>
                <div
                    //imagem da observação na parte superior do popup
                    style={{objectFit:'cover', gridRowStart:1, gridRowEnd:1, position:'relative'}}>
                    <img src={`/imagens/${content.properties.imagens[0].url}`} height='100%' width='100%'/>
                    <div
                        //indicação do tipo de observação no canto inferior da imagem
                        style={{ fontSize:'14px', fontWeight:'bold', position:'absolute', bottom:0, left:0, paddingLeft:'2px', paddingRight:'2px', color:'white', backgroundColor:'#00000050'}}
                        >{LABEL[popup.tipo]}
                    </div>
                </div>
                <div
                    //dados da observação. Os primeiros (utilizador, data, hora) são comuns a todos os tipos, os seguintes dependem do tipo
                    style={{objectFit:'cover', gridRowStart:2, gridRowEnd:2, overflowY:'auto', marginTop:'5px'}} className='scrollbar-custom'>
                    <div className='field-container'>
                        <div><span style={{fontWeight:'bold'}}>Utilizador:</span> {content.properties.id_user.username}</div>
                        
                    </div>
                    <div className='field-container'>
                        <div><span style={{fontWeight:'bold'}}>Data:</span> {data !== '' ? data.toLocaleDateString("pt-PT"): ''}</div>
                        
                    </div>
                    <div className='field-container'>
                        <div><span style={{fontWeight:'bold'}}>Hora:</span> {data !== '' ? data.toLocaleTimeString("pt-PT", {hour:'2-digit', minute:'2-digit'}):''}</div>
                        
                    </div>
                    {popup.tipo === 'aninhamento' ?  <div>
                                                        <div><span style={{fontWeight:'bold'}}>Número de ovos:</span> {content.properties.n_ovos===-1?'Desconhecido':content.properties.n_ovos}</div>
                                                        <div><span style={{fontWeight:'bold'}}>Progenitores presentes:</span> {content.properties.progenitores ? 'Sim': 'Não'}</div>
                                                        <div><span style={{fontWeight:'bold'}}>Local:</span> {content.properties.local}</div>
                                                        <div><span style={{fontWeight:'bold'}}>A causar ruído:</span> {content.properties.ruido ? 'Sim': 'Não'}</div>
                                                        <div><span style={{fontWeight:'bold'}}>Detalhes:</span> {content.properties.detalhes}</div>
                                                    </div>:null}
                    {popup.tipo === 'avistamento' ? <div> 
                        
                        <div><span style={{fontWeight:'bold'}}>Espécie:</span> <span style={content.properties.especie!=="Não sei"?{fontStyle:'italic'}:{}}>{content.properties.especie}</span></div>
                        <div><span style={{fontWeight:'bold'}}>Número de indivíduos:</span> {content.properties.numero}</div>
                        <div><span style={{fontWeight:'bold'}}>Indivíduo{content.properties.numero>1?'s':''} vivo{content.properties.numero>1?'s':''}:</span> {content.properties.vida ? 'Sim': 'Não'}</div>
                        <div><span style={{fontWeight:'bold'}}>A causar ruído:</span> {content.properties.ruido ? 'Sim': 'Não'}</div>
                        <div><span style={{fontWeight:'bold'}}>A mexer em lixo urbano:</span> {content.properties.lixo ? 'Sim': 'Não'}</div>
                        <div><span style={{fontWeight:'bold'}}>Alimentação:</span> {content.properties.alimentacao}</div>
                        <div><span style={{fontWeight:'bold'}}>Detalhes:</span> {content.properties.detalhes}</div>

                    </div>:null}
                    {popup.tipo ==='ataque' ? <div>
                        <div><span style={{fontWeight:'bold'}}>Alvo:</span> {
                            Object.keys(content.properties.alvo).map(key=>(
                                //como podem haver múltiplos alvos selecionados para um só ataque, criamos uma lista
                                content.properties.alvo[key]&&key!=='_id'?<li key={key}>{target_list[key]}</li>:null)
                                )
                            }
                        </div>
                        <div><span style={{fontWeight:'bold'}}>Descrição:</span> {content.properties.descricao} </div>
                    </div>:null}

                </div>
            </>:<Loader
                                type='TailSpin'
                                color='#0dafd0ff'
                                height='40'
                                width='40'
                            
                            />}
        </div>
    )
    
}
    
   


export default MapPopup