import React, { useEffect} from 'react';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import DeleteModal from './DeleteModal';
import DeleteUserModal from './DeleteUserModal';
import EditModal from './EditModal';
import FilterModal from './FilterModal';
import NewPassModal from './NewPassModal';
import './ConfirmationModal.css'
import {delete_observation_clean, delete_user_self_clean, validate_observation_clean, delete_user_self_confirm} from '../redux/actions';
import PickAvatarModal from './PickAvatarModal';

function ConfirmationModal(props){
    //contentor para modal, reutilizado para dar prompts ao utilizador, em geral para confirmar inputs, mas também para introduzir e.g. nova password
    //este componente só serve para conter o modal, dar-lhe estilos e fazer algum clean up, cada tipo individual de modal tem um componente

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        //limpar o state quando o modal é desmontado, dependo do tipo de modal de que se trata
        if(props.tipo==='delete'){
            return () => dispatch(delete_observation_clean())
        }else if(props.tipo==='validar'){
            return () => dispatch(validate_observation_clean())
        }else if(props.tipo==='delete_account'){
            dispatch(delete_user_self_confirm());
            return () => dispatch(delete_user_self_clean());
        }
    }, [])
    
    const innerModalStyles = { //class names para os diferentes tipos de modal, assim podemos ter abaixo um único div, que recebe a classe
        //adequada de acordo com o tipo de modal de que se trate
        delete:'deletionModal',
        validar:'validationModal',
        filtrar: 'filterModal',
        novaPassword: 'newPassModal',
        editAvatar: 'editAvatarModal',
        delete_account:'deletionModal'
    }
   

    return(
        <div
        //área à volta do modal a cobrir o ecrã, retorna na história do browser quando clicada (i.e. fecha o modal)
        onClick = {() => history.goBack()}
        style={{position:'absolute', width:"100vw", height:"100vh", backgroundColor:'#00000099', zIndex:props.tipo==='delete'?20000:15000}}>
            <div className={innerModalStyles[props.tipo]} onClick={e=>e.stopPropagation()}>
                {props.tipo==='delete'?<DeleteModal />:null //modal de confirmação para apagar uma observação
                }
                {props.tipo==='delete_account'?<DeleteUserModal />:null //modal de confirmação para apagar um utilizador
                }
                {props.tipo==='validar'?<EditModal tipo='validar' />:null //modal para editar observações já efetuadas
                }
                {props.tipo==='filtrar'?<FilterModal />:null //modal para ativar e desativar filtros no mapa
                }
                {props.tipo==='novaPassword'?<NewPassModal />:null //modal para introduzir nova password
                }
                {props.tipo==='editAvatar'?<PickAvatarModal />:null //modal para escolher um novo avatar
                }
            </div>
        </div>
    )
    
}

export default ConfirmationModal;