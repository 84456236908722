import {
    GET_USERS_LIST_FIRST_START,
    GET_USERS_LIST_EXTRA_START,
    GET_USERS_LIST_FIRST_SUCCESS,
    GET_USERS_LIST_EXTRA_SUCCESS,
    GET_USERS_LIST_FIRST_FAILED,
    GET_USERS_LIST_EXTRA_FAILED,
    SEARCH_USER_START,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILED,
    CLEAR_USERS_LIST,
    EDIT_USER,
    CLEAR_EDIT_USER
} from '../actions';

const initialState = {
    utilizadores: [],
    status: 'WAITING',
    source:'',
    error: null,
    totalUtilizadores: 0,
    editingUser: {}
}

export default function adminUsersReducer(state = initialState, action){

    switch(action.type){
        case GET_USERS_LIST_FIRST_START:
            return {
                ...state,
                status: 'LOADING'
            }
        case GET_USERS_LIST_FIRST_SUCCESS:
            return {
                ...state,
                status: 'LOADED',
                utilizadores: action.payload.utilizadores,
                totalUtilizadores: action.payload.totalUtilizadores,
                source:'list'
            }
        case GET_USERS_LIST_FIRST_FAILED:
            return {
                ...state, 
                status: 'ERROR',
                error: action.payload
            }
        case GET_USERS_LIST_EXTRA_START:
            return {
                ...state,
                status: 'LOADING'
            }
        case GET_USERS_LIST_EXTRA_SUCCESS:
            
            return {
                ...state,
                status: 'LOADED',
                utilizadores: action.payload
            }
        case GET_USERS_LIST_EXTRA_FAILED:
            return {
                ...state, 
                status: 'ERROR',
                error: action.payload
            }
        case SEARCH_USER_START:
            return {
                ...state,
                status: 'LOADING'
            }
        case SEARCH_USER_SUCCESS:
            return {
                ...state,
                status: 'LOADED',
                utilizadores: action.payload,
                source: 'search'
            }
        case SEARCH_USER_FAILED:
            return {
                ...state, 
                status: 'ERROR',
                error: action.payload
            }
        case CLEAR_USERS_LIST:
            return {
                utilizadores: [],
                status: 'WAITING',
                errort: null,
                totalUtilizadores: 0,
                source:''
            }
        case EDIT_USER:
            return{
                ...state,
                editingUser: action.payload
            }
        case CLEAR_EDIT_USER:
            return {
                ...state,
                editingUser: {}
            }
        default:
            return state
    }
}