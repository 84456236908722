import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { delete_user_self, delete_user_self_clean, delete_user_self_confirm } from '../redux/actions';
import { useHistory } from 'react-router-dom';

function DeleteUserModal(){
    //modal para confirmar a eliminação de um utilizador
    const deletingState = useSelector(state => state.userStatus.userDeleteStatus); //estado, quando fazemos request ao backend para eliminar a observação
    const dispatch = useDispatch();
    const history = useHistory();



    const handleCancel = () => {
        //se a eliminação for cancelada, limpamos do state e fechamos o modal
        dispatch(delete_user_self_clean())
        history.goBack()
    }

     return(
         <div style={{height:'100%', padding:'10px'}}> 
            {//depois de ter, na lista utilizadores ou na respetiva página, clicado em eliminar o utilizador, o estado passou a "Confirming" e este modal abriu.
            //aí, mostramos o prompt de confirmação abaixo e dois botões para eliminar ou cancelar:
            }
            {deletingState==='confirming'?<div style={{display:'flex', flexDirection:'column', justifyContent: 'space-evenly', height:'100%'}}>
            <div style={{fontSize:'0.9em', textAlign:'center'}}>Eliminar conta? Os dados serão irreversivelmente apagados, incluindo os avistamentos.</div>
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                <div onClick ={handleCancel} style={{cursor: 'pointer'}}>Cancelar</div>
                <div onClick ={() => dispatch(delete_user_self())} style={{color:'red', cursor: 'pointer'}}>Eliminar</div></div>
                
            </div>:null}
            {//diferentes infos ao utilizador, refletindo os diferentes estados de processo de eliminação:
            }
            {deletingState==='deleting' ? <div>A eliminar...</div>:null}
            {deletingState==='delete_success' ? <div>Conta eliminada</div>:null}
            {deletingState==='delete_failed' ? <div>Ocorreu um erro. Por favor tente novamente.</div>:null}
        </div>
    )
}

export default DeleteUserModal;


