import React from 'react';

function TermsPage(){

    return(
        <div style={{width: '100%', height: '100%', textAlign:'left', overflowY:'auto', padding:'5px', boxSizing:'border-box'}}>
            <h1>Aceitação dos Termos de Utilização</h1>

<p>A aplicação web Gaivotas à Vista@AMP é propriedade da Área Metropolitana do Porto (AMP), tendo sido desenvolvida pela Wenature - Ecotourism and Nature Technologies Lda.</p>
<p>A aplicação web Gaivotas à Vista@AMP serve a finalidade de registar avistamentos de gaivotas, ninhos e outras ocorrências com gaivotas em 5 municípios da AMP: Gaia, Matosinhos, Porto, Póvoa de Varzim e Vila do Conde, estando esses registos acessíveis a qualquer cidadão que se registe na aplicação – o Utilizador, sem quaisquer custos para o mesmo.</p>
<p>O Utilizador poderá aceder à versão mais recente dos Termos de Utilização a qualquer momento, através da aplicação.</p>
<p>Ao utilizar a aplicação, o Utilizador declara ter tomado conhecimento e aceitar as políticas e regras aplicáveis à utilização, conforme descritas nestes Termos de Utilização e na Política de Privacidade. Estas regras e políticas podem ser periodicamente modificadas pela AMP, ficando todas as utilizações da aplicação sujeitas às regras e políticas atualizadas. Assim, o fornecedor compromete-se a garantir o acesso aos Termos de Utilização e à Política de Privacidade através da aplicação, comprometendo-se o Utilizador a aceder, manter-se atualizado sobre e consentir estes Termos e Política. Em caso de dúvidas sobre os Termos e Condições e Política de Privacidade, bem como sobre a utilização da aplicação, o Utilizador é encorajado a contactar a AMP.</p>
<ul>
    <li>Não é permitida a utilização indevida da aplicação.</li>
    <li>Não é permitida a interferência, nem a promoção da interferência, com a aplicação: não é permitido o acesso à aplicação através de outros métodos que não a interface e as instruções aqui fornecidas.</li>
    <li>A aplicação pode apenas ser utilizada nos termos permitidos por lei.</li>
    <li>O Utilizador compromete-se a não utilizar as funcionalidades da aplicação para o exercício de qualquer atividade comercial, lícita ou ilícita, comprometendo-se a conhecer as condições legais de funcionamento de todas as atividades para as quais venha a utilizar a aplicação.</li>
    <li>Se for detetada qualquer utilização indevida da aplicação, ou se se determinar que não estão a ser respeitados os Termos de Utilização e políticas aplicáveis, a AMP reserva-se o direito de suspender o acesso ou deixar de fornecer a aplicação ao Utilizador.</li>
    <li>Adicionalmente, se se verificar a utilização do Serviço em qualquer atividade ilícita, o fornecedor reserva-se o direito de reportar às autoridades tutelares todos os elementos necessários ao apuramento de eventual matéria criminal, sem qualquer tipo de aviso ao Utilizador.</li>
    <li>A utilização da aplicação não confere ao Utilizador quaisquer direitos de propriedade intelectual sobre a mesma ou sobre o conteúdo acedido. O conteúdo acedido através da aplicação não pode ser utilizado para qualquer outra finalidade que não as inerentes aos usos previstos da aplicação, salvo mediante autorização do respetivo proprietário ou em circunstâncias permitidas por lei. Os presentes termos de utilização não concedem o direito de utilizar nenhuma das marcas ou logótipos usados nesta aplicação.</li>
    <li>O Utilizador não deverá remover, ocultar ou alterar qualquer aviso legal apresentado na aplicação.</li>
    <li>Ao utilizar a aplicação, o Utilizador declara ser imputável e maior de idade ou, alternativamente, estar a utilizar o Serviço mediante supervisão de adulto(s). Em todos os casos a utilização do Serviço representa a aceitação tácita dos presentes Termos de Utilização.</li>
    <li>O fornecimento de informações, pelo Utilizador, que se venham a verificar falsas poderão determinar a suspensão do acesso à aplicação.</li>
    <li>A aplicação está disponível em dispositivos móveis. A aplicação não deve ser utilizada de forma a que constitua uma distração e/ou impeça o Utilizador de obedecer às leis rodoviárias, regras de segurança ou qualquer outra, de forma a que possa diminuir a sua segurança. Ao utilizar a aplicação, o Utilizador deverá acautelar todas as questões de segurança, nomeadamente no que refere às condições climatéricas, acesso a redes de comunicação móvel, acesso a cuidados médicos ou de primeiros-socorros, utilização de caminhos e percursos disponíveis, estado dos pavimentos, aptidão física e mental para a realização de atividades ao ar-livre, bem como quaisquer outras condições que poderá enfrentar durante a utilização da aplicação.</li>
    <li>Os conteúdos disponibilizados pela aplicação são meramente indicativos e não poderá ser considerada vinculativa a sua possível observação e/ou visitação. A informação fornecida pela aplicação relativamente à localização do Utilizador é indicativa, não devendo ser utilizada em situações onde informação de localização imprecisa, errónea ou incompleta possa resultar em danos para o Utilizador, para terceiros ou para bens.</li>
    <li>A partir da aplicação, o Utilizador pode ser direcionado para websites de terceiros, por exemplo das entidades patrocinadoras do projeto e ao serviço Mapbox. O conteúdo destes websites é da inteira responsabilidade das respetivas entidades.</li>
    <li>A aplicação utiliza a API Mapbox. Ao utilizar a aplicação, o Utilizador fica também sujeito aos respetivos Termos.</li>
</ul>
<p>Ao utilizar a aplicação, o Utilizador indica que leu, entendeu e concordou com estes Termos do Serviço, vinculando-se às regras neles contidas.</p>

<h1>Descrição da aplicação</h1>
<p>A aplicação corresponde a uma aplicação web para reporte e visualização de avistamentos de gaivotas e respetivos ninhos, na área de incidência do projeto Gaivotas à Vista@AMP. A sua utilização permite que o Utilizador aceda a uma variedade de conteúdos. Estes conteúdos incluem, mas não estão limitados a, listas de avistamentos realizados por outros utilizadores apresentados sobre cartografia, lista de avistamentos realizados pelo próprio utilizador, detalhes sobre estes avistamentos e respetivas fotos, bem como formulários de reporte de novos avistamentos. Os avistamentos podem ser consultados por qualquer utilizador, sendo que para reportar novos avistamentos é necessária uma conta de utilizador, gratuita. A informação espacial é apresentada sobre mapa ou imagens aéreas, fornecidas pelos respetivos detentores legais.</p>
<p>Na utilização da aplicação, particularmente no que se relaciona com informação de mapas e conteúdo disponível, o Utilizador pode aperceber-se de que as condições reais aquando da utilização diferem do descrito no mapa e nos conteúdos. Por conseguinte, o Utilizador deve agir com prudência e utilizar o Serviço por sua conta e risco. O Utilizador é sempre responsável pela sua conduta e respetivas consequências.</p>
<p>O acesso ao Serviço poderá incluir a contratação de serviços de terceiros (como por exemplo serviços de conexão à internet, tráfego de dados) e a utilização (aquisição) de um terminal de acesso (como por exemplo computador pessoal, telemóvel, tablet ou outro qualquer dispositivo que permita aceder a conteúdos online). O Utilizador é exclusivamente responsável por todo e qualquer custo de contratação destes serviços de terceiros para acesso à aplicação. Adicionalmente, o Utilizador deverá possuir e será responsável por qualquer equipamento necessário para o acesso à aplicação.</p>

<h1>Aviso Legal</h1>
<p>O fornecedor não garante, em qualquer circunstância, que as funções contidas na aplicação vão ao encontro dos requisitos ou expetativas do Utilizador, do mesmo modo que não garante a inexistência de erros no funcionamento da aplicação.</p>
<p>As informações e dados fornecidos pela aplicação são prestadas sem qualquer tipo de garantia, explícita ou implícita. Sem qualquer limitação para o referido na linha anterior, a aplicação não garante que as informações prestadas estejam constantemente disponíveis, ou sempre disponíveis, bem como não garante que as mesmas sejam precisas, completas e/ou inequívocas.</p>
<p>O Utilizador entende e concorda que o processamento técnico e transmissão de informações da aplicação, incluindo o seu conteúdo e informações, poderão envolver: transmissões através de várias e diferentes redes em diferentes territórios; e, modificações para conformação e adaptação a determinados requisitos técnicos para conexão com redes e/ou dispositivos.</p>
<p>Em qualquer circunstância, poderá o fornecedor ou qualquer representante legal do mesmo, ser considerado culpado por quaisquer perdas, acidentes, despesas, prejuízos ou outros atos adversos resultantes da utilização da aplicação.</p>
<p>Os Termos de Utilização aplicam-se apenas à utilização da aplicação web, não cobrindo as ações de qualquer outra companhia ou organização que fomente ou propicie a utilização da aplicação.</p>

<h1>Política de privacidade</h1>
<p>A aplicação Gaivotas à Vista utiliza apenas os cookies essenciais para o seu funcionamento, nomeadamente para guardar o estado da aplicação entre utilizações (e.g., dados de login).</p>
<p>Os dados pessoais fornecidos (nome de utilizador, email e password) são armazenados no nosso servidor, para serem associados a cada avistamento reportado, através de um identificador único (id). As passwords são armazenadas de acordo com as boas práticas de segurança informática. Ainda assim é aconselhável, como em qualquer serviço online, a utilização de uma password única.</p>
<p>São mantidos registos (logs) de acesso ao servidor, bem como de erros ocorridos na aplicação, em que é registado o endereço IP do utilizador, bem como informação relativamente ao browser e sistema operativo utilizados. Eventualmente estes dados podem estar associados à respetiva conta de utilizador, caso o utilizador se encontre logged in na aplicação aquando do acesso. Estes registos são utilizados apenas para fins de análise de erros e debugging.</p>
<p>A aplicação utiliza o provedor externo Mapbox para apresentação dos mapas. Ao utilizar a aplicação, fica também sujeito aos termos de serviço e política de privacidade deste serviço.</p>
        </div>
    )
}

export default TermsPage;