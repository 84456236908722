import {
  FETCH_VALIDATE_BEGIN,
  FETCH_VALIDATE_SUCCESS,
  FETCH_VALIDATE_FAILURE,
  FETCH_VALIDATE_EXTRA_BEGIN,
  FETCH_VALIDATE_EXTRA_SUCCESS,
  FETCH_VALIDATE_EXTRA_FAILURE,
  EDIT_FIELD,
  CLEAR_AVISTAMENTOS_VALIDATE,
  FILTRAR_CONCELHO

} from '../actions';
 
const initialState = {
  avistamentos: {},
  mainStatus: 'WAITING',
  extraStatus: 'WAITING', 
  error: null,
  total:0,
  filtroConcelho: 'Todos'
};

export default function avistamentosValidateReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case FETCH_VALIDATE_BEGIN:
      return {
        ...state,
        mainStatus: 'LOADING',
        error: null,
      };
    case FETCH_VALIDATE_EXTRA_BEGIN:
      return {
          ...state,
          extraStatus: 'LOADING',
          error: null
      }
    case FETCH_VALIDATE_SUCCESS:
      return {
        ...state,
        mainStatus: 'LOADED',
        error: null,
        avistamentos: action.payload.avistamentos.observacoes,
        total:action.payload.avistamentos.total
      };
    case FETCH_VALIDATE_EXTRA_SUCCESS:
        var currState = state.avistamentos;
        const newState = currState.concat(action.payload.avistamentos)
        
        return {
            ...state,
            extraStatus: 'LOADED',
            error: null,
            avistamentos: newState
        }
    case FETCH_VALIDATE_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        error: action.payload,
      };

    case FETCH_VALIDATE_EXTRA_FAILURE:
      return {

          ...state,
          extraStatus: 'ERROR',
          error: action.payload
      }
    case CLEAR_AVISTAMENTOS_VALIDATE:
      return {
          avistamentos: {},
          mainStatus: 'WAITING',
          extraStatus: 'WAITING', 
          error: null,
          filtroConcelho: 'Todos' 
      }
    case EDIT_FIELD:
      return {
        ...state,
        avistamentos: {...state.avistamentos, 
          [action.payload.field]: action.payload.value
        }
      }
    case FILTRAR_CONCELHO:
      return {
        ...state,
        filtroConcelho: action.payload
      }
    default:
      return state;
  }
}
