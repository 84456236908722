export const GET_EXCEL_START = 'GET_EXCEL_START';
export const GET_EXCEL_SUCCESS = 'GET_EXCEL_SUCCESS';
export const GET_EXCEL_FAILED = 'GET_EXCEL_FAILED';

export const get_excel_start = () => ({
    type: GET_EXCEL_START
})

export const get_excel_success = (user) => ({
    type: GET_EXCEL_SUCCESS,
    payload: user
})

export const get_excel_failed = (error) => ({
    type: GET_EXCEL_FAILED,
    payload: error
})

const token = localStorage.getItem("token");

export const get_excel = () => {
    return dispatch => {
        dispatch(get_excel_start());
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/xlsx`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            }
        )
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
            
            window.open(url, '_blank');
            URL.revokeObjectURL(url);
        })
        .then(() => {
            dispatch(get_excel_success())
        })
        .catch(error => {
            dispatch(get_excel_failed(error))
        })
    }
}