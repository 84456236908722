export const update_form_avistamento_date = (date) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_DATE',
        payload: date
    }
}

export const update_form_avistamento_coords = (coords) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_COORDS',
        payload: coords
    }
}

export const update_form_avistamento_vida = (vida) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_VIDA',
        payload: vida
    }
}

export const update_form_avistamento_numero = (numero) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_NUMERO',
        payload: numero
    }
}

export const update_form_avistamento_especie = (especie) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_ESPECIE',
        payload: especie
    }
}

export const update_form_avistamento_image = (imagem) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_IMAGE',
        payload: imagem
    }
}

export const update_form_avistamento_concelho = (concelho) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_CONCELHO',
        payload: concelho
    }
}

export const update_form_avistamento_ruido = (ruido) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_RUIDO',
        payload: ruido
    }
}

export const update_form_avistamento_alimentacao = (alimentacao) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_ALIMENTACAO',
        payload: alimentacao
    }
}

export const update_form_avistamento_lixo = (lixo) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_LIXO',
        payload: lixo
    }
}

export const update_form_avistamento_detalhes = (detalhes) => {
    return {
        type: 'UPDATE_FORM_AVISTAMENTO_DETALHES',
        payload: detalhes
    }
}

export const clean_form_avistamento = () => {
    return {
        type: 'CLEAR_AVISTAMENTO_FORM'
    }        
}
