export default function novoAninhamentoReducer(
    state = {
        data: null,
        long:null,
        lat: null,
        progenitores: false,
        ruido: false,
        local: 'Telhado',
        detalhes: '',
        n_ovos: 0,
        inferido:false,
        concelho: null,
        imageInfo: []

    },
    action){
        switch(action.type){
            case 'UPDATE_FORM_NINHO_DATE':
                return {...state, data: action.payload}
            case 'UPDATE_FORM_NINHO_COORDS':
                return {...state, long: action.payload[1], lat:action.payload[0]}
            case 'UPDATE_FORM_NINHO_PROGENITORES':
                return {...state, progenitores: action.payload}
            case 'UPDATE_FORM_NINHO_OVOS':
                return {...state, n_ovos: action.payload}
            case 'UPDATE_FORM_NINHO_RUIDO':
                return {...state, ruido: action.payload}
            case 'UPDATE_FORM_NINHO_LOCAL':
                return {...state, local: action.payload}
            case 'UPDATE_FORM_NINHO_DETALHES':
                return {...state, detalhes: action.payload}
            case 'UPDATE_FORM_NINHO_INFERIDO':
                return {...state, inferido: action.payload}
            case 'UPDATE_FORM_NINHO_IMAGE':
                return {...state, imageInfo:action.payload}
            case 'UPDATE_FORM_NINHO_CONCELHO':
                return {...state, concelho:action.payload}
            case 'CLEAR_ANINHAMENTO_FORM':
                return {...state, data: null, long:null, lat:null, progenitores: false, ruido:'false', local:'Telhado', inferido:false, detalhes:'', n_ovos:0, imageInfo:[], concelho:null}
            default:
                return state
        }
    }