import React, { useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {  submit_reset_password } from '../redux/actions';
import { Link} from 'react-router-dom';

function PasswordResetPage(props){
    const loginStatus = useSelector(state => state.userStatus.loginStatus)
    const {id, token} = props;
    const [passChecks, setPassChecks] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const rex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$')

    const dispatch = useDispatch();

    useEffect(() => {
        
        if(rex.test(newPassword)){
            setPassChecks(true)
        }else{
            setPassChecks(false)
        }
        
    }, [newPassword])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(rex.test(newPassword)){
            dispatch(submit_reset_password(id, token, newPassword))
        }

        
        

    }
    return(
        <div style={{marginLeft:'15%', marginRight:'15%', marginTop:'30px'}}>
            {loginStatus==='reset_password_failed' ? <div style={{color:'red'}}>Ocorreu um erro. Por favor tente novamente.</div>:null}
            {loginStatus==='reset_password_success'?<div>A password foi alterada. <Link to={'/login'}>Login</Link></div>:<form onSubmit={handleSubmit}>
                <label htmlFor="new_reset_password" style={{display:'block'}}>Nova password:</label>
                {!passChecks?<div style={{fontSize:13}}>A password deve ter pelo menos oito caracteres, e conter pelo menos uma letra maiúscula, uma minúscula e um número.</div>:null}
                <input type="password" id="new_reset_password" name="new_reset_password" style={{width:'100%', boxSizing: 'border-box'}}  onChange={e => setNewPassword(e.target.value)} value = {newPassword} required></input>
                <label htmlFor="confirm_reset_password" style={{display:'block'}}>Confirme a nova password:</label>
                <input type="password" id="confirm_reset_password" name="confirm_reset_password" style={{width:'100%', boxSizing: 'border-box'}}  onChange={e => setConfirmPassword(e.target.value)} value = {confirmPassword} required></input>
                {newPassword!==confirmPassword ? <div style={{color:'red'}}>Por favor certifique-se que as passwords coincidem.</div>:null}
                <button className='custom-button' disabled={newPassword!==confirmPassword||!passChecks?'disabled':null} type='submit' display='block' style={{marginTop:'5px'}}>{loginStatus==='submitting_reset_password'?'A submeter...':'Submeter'}</button>
            </form>}
        </div>
        
    )
}

export default PasswordResetPage;