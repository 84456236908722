import {
    EDIT_OBSERVATION,
    EDIT_OBSERVATION_FIELD,
    DELETE_OBSERVATION_CONFIRM,
    DELETE_OBSERVATION_START,
    DELETE_OBSERVATION_SUCCESS,
    DELETE_OBSERVATION_FAILED,
    DELETE_OBSERVATION_CLEAN,
    VALIDATE_OBSERVATION_START,
    VALIDATE_OBSERVATION_SUCCESS,
    VALIDATE_OBSERVATION_FAILED,
    VALIDATE_OBSERVATION_CLEAN,
    VALIDATE_OBSERVATION_CHECK,
  } from '../actions';
  
  const initialState = {
    editingObservation: {},
    deletingObservation: {},
    deletingObservationStatus: 'WAITING',
    validatingObservationStatus: 'WAITING'
  };
  
  export default function editObservationReducer(
    state = initialState,
    action,
  ) {
    switch (action.type) {
      case EDIT_OBSERVATION:
        return {
          ...state,
          editingObservation: action.payload,
        };
      case EDIT_OBSERVATION_FIELD:
        return{
          ...state,
          editingObservation: {...state.editingObservation,
              properties: {
                ...state.editingObservation.properties,
                [action.payload.target]: action.payload.value
              }
            }
          
        }
      case DELETE_OBSERVATION_START:
        return {...state, deletingObservationStatus:'DELETING'};
      case DELETE_OBSERVATION_CONFIRM:
        return {...state, deletingObservationStatus:'CONFIRMING', deletingObservation:action.payload};
      case DELETE_OBSERVATION_SUCCESS:
        return {...state, deletingObservationStatus: 'DELETED'};
      case DELETE_OBSERVATION_FAILED:
        return {...state, deletingObservationStatus: 'FAILED'}
      case DELETE_OBSERVATION_CLEAN:
        return {...state, deletingObservationStatus: 'WAITING', deletingObservation:{}}
      case VALIDATE_OBSERVATION_START:
        return {...state, validatingObservationStatus:'VALIDATING'};
      case VALIDATE_OBSERVATION_CHECK:
        return {...state, validatingObservationStatus:'CHECKING', editingObservation:action.payload}
      case VALIDATE_OBSERVATION_SUCCESS:
        return {...state, validatingObservationStatus: 'VALIDATED'};
      case VALIDATE_OBSERVATION_FAILED:
        return {...state, validatingObservationStatus: 'FAILED'}
      case VALIDATE_OBSERVATION_CLEAN:
        return {...state, validatingObservationStatus: 'WAITING', editingObservation:{}}
      
      default:
        return state;
    }
  }


  