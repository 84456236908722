export const show_sidebar = ()=>{
    return {
        type: 'SHOW_SIDEBAR'
    }
}

export const hide_sidebar = ()=>{
    return {
        type: 'HIDE_SIDEBAR'
    }
}