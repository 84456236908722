import {
    FETCH_AVISTAMENTOS_BEGIN,
    FETCH_AVISTAMENTOS_SUCCESS,
    FETCH_AVISTAMENTOS_FAILURE,
    FETCH_AVISTAMENTO_BEGIN,
    FETCH_AVISTAMENTO_SUCCESS,
    FETCH_AVISTAMENTO_FAILURE,
    CLEAR_AVISTAMENTO_POPUP
  } from '../actions';
  
const initialState = {
avistamentos: {},
selectedAvistamento:{},
statusGeral: 'WAITING',
statusSelected:'WAITING',
errorGeral: null,
errorSelected: null
};

export default function avistamentosReducer(state = initialState, action){
    switch(action.type){
    case FETCH_AVISTAMENTOS_BEGIN:
        return {
            ...state,
            statusGeral: 'LOADING',
            errorGeral: null
        }
    case FETCH_AVISTAMENTOS_SUCCESS:
        return {
            ...state,
            statusGeral: 'LOADED',
            errorGeral: null,
            avistamentos: action.payload.avistamentos
        }
    case FETCH_AVISTAMENTOS_FAILURE:
        return {
            ...state,
            statusGeral: 'ERROR',
            errorGeral: action.payload
        }
    case FETCH_AVISTAMENTO_BEGIN:
        return {
            ...state,
            statusSelected: 'LOADING',
            errorSelected: null
        }
    case FETCH_AVISTAMENTO_SUCCESS:
        return {
            ...state,
            statusSelected: 'LOADED',
            errorSelected: null,
            selectedAvistamento: action.payload
        }
    case FETCH_AVISTAMENTO_FAILURE:
        return {
            ...state,
            statusSelected: 'ERROR',
            errorSelected: action.payload
        }
    case CLEAR_AVISTAMENTO_POPUP:
        return {
            ...state,
            selectedAvistamento:{},
            statusSelected: 'WAITING',
            errorSelected: null
        }
    default:
        return state
    }
}