import {timeoutPromise} from '../../helpers/timeouters';
export const FETCH_ANINHAMENTOS_BEGIN   = 'FETCH_ANINHAMENTOS_BEGIN';
export const FETCH_ANINHAMENTOS_SUCCESS = 'FETCH_ANINHAMENTOS_SUCCESS';
export const FETCH_ANINHAMENTOS_FAILURE = 'FETCH_ANINHAMENTOS_FAILURE';
export const FETCH_ANINHAMENTO_BEGIN   = 'FETCH_ANINHAMENTO_BEGIN';
export const FETCH_ANINHAMENTO_SUCCESS = 'FETCH_ANINHAMENTO_SUCCESS';
export const FETCH_ANINHAMENTO_FAILURE = 'FETCH_ANINHAMENTO_FAILURE';
export const CLEAR_ANINHAMENTO_POPUP = 'CLEAR_ANINHAMENTO_POPUP';

export const fetchAninhamentosBegin = () => ({
    type: FETCH_ANINHAMENTOS_BEGIN
});

export const fetchAninhamentoBegin = () => ({
    type: FETCH_ANINHAMENTO_BEGIN
});

export const fetchAninhamentosSuccess = aninhamentos => ({
    type: FETCH_ANINHAMENTOS_SUCCESS,
    payload: {aninhamentos}
});

export const fetchAninhamentoSuccess = aninhamento => ({
    type: FETCH_ANINHAMENTO_SUCCESS,
    payload: {aninhamento}
});

export const fetchAninhamentosFailure = error => ({
    type: FETCH_ANINHAMENTOS_FAILURE,
    payload: {error}
});

export const fetchAninhamentoFailure = error => ({
    type: FETCH_ANINHAMENTO_FAILURE,
    payload: {error}
});

export const clearAninhamentoPopup = () => ({
    type: CLEAR_ANINHAMENTO_POPUP
    
});



export const fetchAninhamentos = () => {
    const token = localStorage.getItem("token");
    const params = token?{
        method:'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }:{method:'get'};
    return async dispatch =>{
        dispatch(fetchAninhamentosBegin());
        try{
            
            const response = await timeoutPromise(6000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/aninhamentos?q=500`, params));
          
            if(response.ok){
                const content = await response.json()
                dispatch(fetchAninhamentosSuccess(content))
            }else{
                throw response.status
            }
            

        } catch (error){
            dispatch(fetchAninhamentosFailure(error))
          
        } 
    
    }
}

export const fetchAninhamento = (id) => {
    return dispatch =>{
        dispatch(fetchAninhamentoBegin());
   
        return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/aninhamento/${id}`)
        .then(response => response.json())
        .then(json => {
            dispatch(
                fetchAninhamentoSuccess(json)
                )
        })
        .catch((error) => {
            dispatch(fetchAninhamentoFailure(error))
        })
    }
}