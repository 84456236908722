export const update_filter_tipos = (tipos) => {
    return {
        type: 'UPDATE_FILTER_TIPOS',
        payload: tipos
    }
}

export const update_filter_datas = (datas) => {
    return {
        type: 'UPDATE_FILTER_DATAS',
        payload: datas
    }
}

export const clear_filters = () => {
    return{ 
        type: 'CLEAR_FILTERS'
    }
}