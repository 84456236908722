import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { delete_observation, delete_observation_clean } from '../redux/actions';
import { useHistory } from 'react-router-dom';

function DeleteModal(){
    //modal para confirmar a eliminação de uma observação
    const deletingState = useSelector(state => state.editObservation.deletingObservationStatus); //estado, quando fazemos request ao backend para eliminar a observação
    const deletingObs = useSelector(state => state.editObservation.deletingObservation); //dados da observação a eliminar
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCancel = () => {
        //se a eliminação for cancelada, limpamos do state e fechamos o modal
        dispatch(delete_observation_clean())
        history.goBack()
    }

     return(
         <div style={{height:'100%', padding:'10px'}}> 
            {//depois de ter, na lista de observações, clicado em eliminar numa, o estado passou a "Confirming" e este modal abriu.
            //aí, mostramos o prompt de confirmação abaixo e dois botões para eliminar ou cancelar:
            deletingState==='CONFIRMING'?<div style={{display:'flex', flexDirection:'column', justifyContent: 'space-evenly', height:'100%'}}>
            <div style={{fontSize:'0.9em', textAlign:'center'}}>Eliminar? Não pode ser revertido!</div>
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                <div onClick ={handleCancel} style={{cursor: 'pointer'}}>Cancelar</div>
                <div onClick ={() => dispatch(delete_observation(deletingObs.id, deletingObs.tipo))} style={{color:'red', cursor: 'pointer'}}>Eliminar</div></div>
                
            </div>:null}
            {//diferentes infos ao utilizador, refletindo os diferentes estados de processo de eliminação:
            }
            {deletingState==='DELETING' ? <div>A eliminar...</div>:null}
            {deletingState==='DELETED' ? <div>Observação eliminada</div>:null}
            {deletingState==='FAILED' ? <div>Ocorreu um erro. Por favor tente novamente.</div>:null}
        </div>
    )
}

export default DeleteModal;


