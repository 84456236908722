import {timeoutPromise} from '../../helpers/timeouters';


export const upload_image_start = () => {
    return {
        type:'UPLOADING_IMAGE'
    }
}

export const upload_image_success = (image) => {
    return {
        type:'UPLOAD_IMAGE_SUCCESS',
        payload:image
    }
}

export const upload_image_failure = () => {
    return {
        type: 'UPLOAD_IMAGE_FAILED'
    }
}

export const upload_image_clean = () => {
    return {
        type: 'UPLOAD_IMAGE_CLEAN'
    }
}

export const upload_image = (image) => {

    return async dispatch => {
        dispatch(upload_image_start());
        try{
       
            var formData = new FormData();
            formData.append('file', image)
            
    
            const token = localStorage.getItem("token");
            const upload = await timeoutPromise(60000, fetch(`${process.env.REACT_APP_API_ADDRESS}/api/imageupload`,{
                method:'POST',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`

                },
                body:formData
            })
            )
            const resp_url = await upload.text()
            
            
            dispatch(upload_image_success(resp_url))

        } catch (error){
            dispatch(upload_image_failure())
     
        } 
    }
}