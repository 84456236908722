export default function userReducer(
    state = {
        loggedIn: false,
        profile:{username:'',
                email:'',
                id: '',
                role:'',
                avatar:0
            },
        loginStatus: 'logged_out',
        userUpdateStatus: 'waiting',
        userDeleteStatus: 'waiting',
        loginError: ''
        
    },
    action){
        switch(action.type){
            case 'LOGIN_START':
                return {...state, loggedIn: false, profile: {username: '', email:'', role:'', avatar:0}, loginStatus:'logging_in', loginError: ''}
            case 'LOGIN_SUCCESS':
                return {...state, loggedIn: true, profile: action.payload, loginStatus:'logged_in'}
            case 'LOGIN_FAILURE':
                return {...state, loggedIn: false, profile:{username: '', email:'', role:'', avatar:0}, loginStatus:'login_failed', loginError: action.payload}
            case 'LOGOUT':
                return {...state, loggedIn: false, profile:{username: '', email:'', role:'', avatar:0}, loginStatus: 'logged_out', loginError: ''}
            case 'REGISTER_START':
                return {...state, loggedIn: false, profile: {username: '', email:'', role:'', avatar:0}, loginStatus:'registering', loginError: ''}
            case 'REGISTER_SUCCESS':
                return {...state, loggedIn: false, profile: {username: '', email:'', role:'', avatar:0}, loginStatus:'registration_success', loginError: ''}
            case 'REGISTER_FAILURE':
                return {...state, loggedIn: false, profile:{username: '', email:'', role:'', avatar:0}, loginStatus:'registration_failed', loginError: action.payload}
            case 'RESET_START':
                return {...state, loginStatus:'resetting', loginError: ''}
            case 'RESET_SUCCESS':
                return {...state, loginStatus:'reset_success', loginError: ''}
            case 'RESET_FAILURE':
                return {...state, loginStatus:'reset_failed', loginError: action.payload}
            case 'SUBMIT_RESET_PASSWORD_START':
                return {...state, loginStatus:'submitting_reset_password', loginError: ''}
            case 'SUBMIT_RESET_PASSWORD_SUCCESS':
                return {...state, loginStatus:'reset_password_success', loginError: ''}
            case 'SUBMIT_RESET_PASSWORD_FAILURE':
                return {...state, loginStatus:'reset_password_failed', loginError: ''}
            case 'CHANGE_PASSWORD_START':
                return {...state, loginStatus:'changing_pass', loginError: ''}
            case 'CHANGE_PASSWORD_SUCCESS':
                return {...state, loginStatus:'change_pass_success', loginError: ''}
            case 'CHANGE_PASSWORD_FAILURE':
                return {...state, loginStatus:'change_pass_failed', loginError: ''}
            case 'CHANGE_PASSWORD_CLEAN':
                return {...state, loginStatus: 'logged_in'}
            case 'EDIT_USER_SELF_START':
                return {...state,  userUpdateStatus: 'editing', loginError: ''}
            case 'EDIT_USER_SELF_SUCCESS':
                return {...state,  profile:{...state.profile, ...action.payload}, userUpdateStatus: 'edit_success', loginError: ''}
            case 'EDIT_USER_SELF_FAILURE':
                return {...state,  userUpdateStatus: 'edit_failed', loginError: ''}
            case 'EDIT_USER_SELF_CLEAN':
                return {...state,  userUpdateStatus: 'waiting', loginError: ''}
            case 'DELETE_USER_SELF_CONFIRM':
                return {...state,  userDeleteStatus: 'confirming', loginError: ''}
            case 'DELETE_USER_SELF_START':
                return {...state,  userDeleteStatus: 'deleting', loginError: ''}
            case 'DELETE_USER_SELF_SUCCESS':
                return {...state,  profile:{username:'',
                email:'',
                id: '',
                role:'',
                avatar:0
            }, userDeleteStatus: 'delete_success', loginError: ''}
            case 'DELETE_USER_SELF_FAILURE':
                return {...state,  userDeleteStatus: 'delete_failed', loginError: ''}
            case 'DELETE_USER_SELF_CLEAN':
                return {...state,  userDeleteStatus: 'waiting', loginError: ''}
            case 'CLEAR_LOGIN_FORMS':
                return {loggedIn: false, profile:{username: '', email:'', role:'', avatar:0}, loginStatus:'logged_out', loginError: ''}
            default:
                return state
        }
    }